var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import * as Sentry from '@sentry/browser';
import { UiButton } from '@claims-ia/design-system';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@shared/layout/layout';
import { Step } from '@shared/claim/steps';
import { ClaimCompensationOption } from '@shared/types/api/claim';
import { claimTypeHasFeature, ClaimTypeFeatureFlag } from '@shared/claim/claimTypeFeatureFlags';
import { breakInDamagesToRepair } from '@declaration/store/breakInDamage';
import OtherArtisanConfirmationModal from '@declaration/components/declaration/pages/indemnisation/autre-artisan/otherArtisanConfirmationModal.vue';
import ContactInformationModal from '@declaration/components/declaration/pages/indemnisation/artisan-partenaire/ContactInformationModal.vue';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const layout = namespace('layout');
const claim = namespace('claim');
const counting = namespace('counting');
const qualification = namespace('qualification');
const breakInDamage = namespace('breakInDamage');
let CompensationOptions = class CompensationOptions extends Vue {
    constructor() {
        super(...arguments);
        this.savingOption = false;
        this.options = ClaimCompensationOption;
        this.displayConfirmationModal = false;
        this.displayContactInformationModal = false;
        this.selfRepairImage = require('@declaration/assets/images/compensation/selfRepair.svg');
        this.partnerArtisanImage = require('@declaration/assets/images/compensation/partnerArtisan.svg');
        this.otherArtisanImage = require('@declaration/assets/images/compensation/otherArtisan.svg');
    }
    closeConfirmationModal() {
        this.displayConfirmationModal = false;
    }
    closeContactInformationModal() {
        this.displayContactInformationModal = false;
    }
    async chooseOtherArtisanOption() {
        if (claimTypeHasFeature(this.claimTypeTrigram, ClaimTypeFeatureFlag.ShowPecuniraryChoiceConfirmation) &&
            !this.displayConfirmationModal) {
            this.displayConfirmationModal = true;
        }
        else {
            await this.chooseOption(ClaimCompensationOption.OtherArtisan);
        }
    }
    async choosePartnerArtisanOption() {
        if (insurerHasFeature(InsurerFeatureFlag.RENContactInformationConfirmation)) {
            this.confirmContactInformation();
        }
        else {
            await this.chooseOption(ClaimCompensationOption.PartnerArtisan);
        }
    }
    async chooseOption(chosenCompensationOption) {
        this.savingOption = chosenCompensationOption;
        await this.updateAndSaveClaim({
            claimPayload: {
                chosenCompensationOption,
                // DO NOT DELETE THIS LINE BELOW PLEASE
                // It is necessary to confirm that the chosen option is final, other things for like IMH will not work anymore if you delete this
                isCompensationOptionAccepted: true,
            },
            step: Step.CompensationOptions,
        }).catch((err) => {
            Sentry.captureException(err);
            this.savingOption = false;
        });
    }
    async confirmContactInformation(renContactInformation) {
        if (this.displayContactInformationModal && renContactInformation) {
            this.savingOption = ClaimCompensationOption.PartnerArtisan;
            try {
                await this.updateAndSaveClaim({
                    claimPayload: {
                        chosenCompensationOption: ClaimCompensationOption.PartnerArtisan,
                        // DO NOT DELETE THIS LINE BELOW PLEASE
                        // It is necessary to confirm that the chosen option is final, other things for like IMH will not work anymore if you delete this
                        isCompensationOptionAccepted: true,
                        renContactInformation,
                    },
                    step: Step.CompensationOptions,
                });
                this.closeContactInformationModal();
            }
            catch (err) {
                Sentry.captureException(err);
            }
            finally {
                this.savingOption = false;
            }
        }
        else {
            this.displayContactInformationModal = true;
        }
    }
    get isOthercraftsmanOptionWellVisible() {
        return claimTypeHasFeature(this.claimTypeTrigram, ClaimTypeFeatureFlag.WellVisiblePecuniraryCompensationOption);
    }
    get isItemsListDisplayed() {
        return claimTypeHasFeature(this.claimTypeTrigram, ClaimTypeFeatureFlag.DisplayListOfItemsToRepair);
    }
    get itemsToRepair() {
        return breakInDamagesToRepair(this.breakInDamages, this.$tc.bind(this));
    }
};
__decorate([
    layout.State('color')
], CompensationOptions.prototype, "color", void 0);
__decorate([
    layout.State('isMobile')
], CompensationOptions.prototype, "isMobile", void 0);
__decorate([
    layout.Getter('isDesktop')
], CompensationOptions.prototype, "isDesktop", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], CompensationOptions.prototype, "isUpdatingClaim", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], CompensationOptions.prototype, "updateAndSaveClaim", void 0);
__decorate([
    counting.State('counting')
], CompensationOptions.prototype, "counting", void 0);
__decorate([
    qualification.State('typeTrigram')
], CompensationOptions.prototype, "claimTypeTrigram", void 0);
__decorate([
    breakInDamage.State('damages')
], CompensationOptions.prototype, "breakInDamages", void 0);
CompensationOptions = __decorate([
    Component({
        components: {
            UiButton,
            OtherArtisanConfirmationModal,
            ContactInformationModal,
        },
        meta: {
            close: quitClaimDeclarationCloseCallback,
            title: 'page.title.compensation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            goBack: null,
        },
        provide() {
            return {
                chooseOtherArtisanOption: this.chooseOtherArtisanOption,
                choosePartnerArtisanOption: this.choosePartnerArtisanOption,
            };
        },
    })
], CompensationOptions);
export default CompensationOptions;
