var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as Sentry from '@sentry/browser';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton, UiIcon } from '@claims-ia/design-system';
import { RoomDamageComponent } from './RoomDamageComponent';
import RoomDamageForm from '@declaration/components/roomDamage/RoomDamageForm.vue';
import RoomImage from '@declaration/components/roomDamage/RoomImage.vue';
import Modal from '@declaration/components/UI/Modal.vue';
import { ROOM_CONFIGURATION_BY_ROOM_TYPE, } from '@shared/constants/equipmentConstants';
import { QUALIFICATION_ROOM } from '@shared/roomDamage/constants';
import { QualificationRoomType } from '@shared/types/api/claim';
import { GoodDamageCategory, GoodDamageOther } from '@shared/types/api/roomDamage';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import { RoomDamageStep } from '@declaration/constants/roomDamageConstants';
import { uncapitalize } from '@shared/utils/text';
import { GOOD_DAMAGE_TYPE_LABELS } from '@shared/roomDamage/goodDamage';
const claim = namespace('claim');
const qualification = namespace('qualification');
const roomDamage = namespace('roomDamage');
const layout = namespace('layout');
const counting = namespace('counting');
let RoomDamageModal = class RoomDamageModal extends RoomDamageComponent {
    constructor() {
        super(...arguments);
        this.roomOptionsBySlug = QUALIFICATION_ROOM;
        this.roomConfigurationByRoomType = ROOM_CONFIGURATION_BY_ROOM_TYPE;
        this.isSaving = false;
        this.isDeleting = false;
        this.steps = RoomDamageStep;
        this.currentStep = RoomDamageStep.RoomDamage;
        this.selectedCategories = [];
        this.goodDamageIndex = -1;
    }
    revealValidation() {
        this.$store.dispatch('error/add', 'Veuillez remplir tous les champs obligatoires');
        this.validateAndRevealDamages(this.changeKey);
    }
    get invalidForm() {
        var _a, _b, _c, _d;
        switch (this.currentStep) {
            case RoomDamageStep.RoomDamage:
                return !this.roomDamage.roomNameSlug;
            case RoomDamageStep.SurfaceDamages:
                if (!this.shouldDescribeRoomDamagedGoods)
                    return !this.validation.isValid;
                return Object.keys((_b = (_a = this.validation.validator) === null || _a === void 0 ? void 0 : _a.fields) !== null && _b !== void 0 ? _b : {}).some((field) => field.startsWith('surfaceDamages') && this.validation.fieldHasViolations(field));
            case RoomDamageStep.GoodDamageCategories:
                return this.validation.fieldHasViolations('goodDamages') && !this.selectedCategories.length;
            case RoomDamageStep.GoodDamageTypes:
                return this.validation.fieldHasViolations('goodDamages');
            case RoomDamageStep.GoodDamageDetails:
                return Object.keys((_d = (_c = this.validation.validator) === null || _c === void 0 ? void 0 : _c.fields) !== null && _d !== void 0 ? _d : {}).some((field) => field.startsWith('goodDamages') && this.validation.fieldHasViolations(field));
            case RoomDamageStep.GoodDamageDescription:
                return this.isValidGoodDamage(this.goodDamageInEdition) === false;
        }
    }
    get formTitle() {
        if (this.currentStep === RoomDamageStep.GoodDamageDescription && this.goodDamageIndex > -1) {
            const damage = this.goodDamages[this.goodDamageIndex];
            if (damage === null || damage === void 0 ? void 0 : damage.type) {
                const damageTitle = this.$tc(GOOD_DAMAGE_TYPE_LABELS[damage.type]);
                return this.$tc('claim.declaration.room_damages.modal.title.good_damage', undefined, {
                    damage: uncapitalize(damageTitle),
                });
            }
        }
        return this.roomDamage.roomNameSlug && this.roomOptionsBySlug[this.roomDamage.roomNameSlug]
            ? this.$tc(this.roomOptionsBySlug[this.roomDamage.roomNameSlug].roomTitle)
            : this.$tc('claim.declaration.room_damages.modal.title');
    }
    get roomComponentFromRoomNameSlug() {
        return this.roomDamage.roomNameSlug &&
            this.roomDamage.roomNameSlug in this.roomConfigurationByRoomType
            ? this.roomConfigurationByRoomType[this.roomDamage.roomNameSlug].svgComponentName
            : null;
    }
    get isHeaderImageDisplayed() {
        return this.roomComponentFromRoomNameSlug && this.isMobile;
    }
    get shouldDescribeSurfaceDamages() {
        return this.room !== QualificationRoomType.Other;
    }
    get shouldDescribeRoomDamagedGoods() {
        return (this.shouldDescribeSurfaceDamages &&
            claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.DescribeRoomDamagedGoods));
    }
    get buttonText() {
        switch (this.currentStep) {
            case RoomDamageStep.RoomDamage:
                return this.$tc(this.shouldDescribeSurfaceDamages
                    ? 'claim.damage.real_estate.room_damage.form.next.button'
                    : 'claim.damage.real_estate.room_damage.form.validate.button');
            case RoomDamageStep.SurfaceDamages:
                return this.$tc(this.shouldDescribeRoomDamagedGoods
                    ? 'claim.damage.real_estate.room_damage.form.next.button'
                    : 'claim.damage.real_estate.room_damage.form.validate.button');
            case RoomDamageStep.GoodDamageCategories:
                return this.hasSelectedCategories || !this.hasSurfaceDamages
                    ? this.$tc('claim.damage.real_estate.room_damage.form.next.button')
                    : this.$tc('claim.damage.real_estate.room_damage.form.validate.button');
            case RoomDamageStep.GoodDamageTypes:
                return this.hasGoodDamages && this.hasAtLeastOneGoodDamageNotOther
                    ? this.$tc('claim.damage.real_estate.room_damage.form.next.button')
                    : this.$tc('claim.damage.real_estate.room_damage.form.validate.button');
            case RoomDamageStep.GoodDamageDetails:
                return this.$tc('claim.damage.real_estate.room_damage.form.validate.button');
            case RoomDamageStep.GoodDamageDescription:
                return this.$tc('claim.damage.real_estate.room_damage.form.validate.button');
        }
    }
    get hasSelectedCategories() {
        return !!this.selectedCategories.length;
    }
    get hasAtLeastOneGoodDamageNotOther() {
        return this.goodDamages.some((goodDamage) => goodDamage.category !== GoodDamageCategory.Other &&
            !Object.values(GoodDamageOther).includes(goodDamage.type));
    }
    get goodDamageInEdition() {
        return this.goodDamageIndex;
    }
    set goodDamageInEdition(goodDamageInEdition) {
        this.goodDamageIndex = goodDamageInEdition;
        this.currentStep =
            goodDamageInEdition === -1
                ? RoomDamageStep.GoodDamageDetails
                : RoomDamageStep.GoodDamageDescription;
    }
    initModale() {
        this.currentStep = RoomDamageStep.RoomDamage;
    }
    closeModal() {
        if (this.changeKey === null) {
            this.deleteDamage(null);
        }
        else {
            const damage = this.counting.roomDamages.find((d) => this.changeKey === d.id);
            if (damage) {
                this.updateDamage({
                    changeKey: this.changeKey,
                    data: damage,
                });
            }
        }
        this.goodDamageIndex = -1;
        this.$emit('close');
    }
    async nextStep() {
        switch (this.currentStep) {
            case RoomDamageStep.RoomDamage:
                if (!this.shouldDescribeSurfaceDamages)
                    await this.onSaveChanges();
                else
                    this.currentStep = RoomDamageStep.SurfaceDamages;
                break;
            case RoomDamageStep.SurfaceDamages:
                if (!this.shouldDescribeRoomDamagedGoods)
                    await this.onSaveChanges();
                else
                    this.currentStep = RoomDamageStep.GoodDamageCategories;
                break;
            case RoomDamageStep.GoodDamageCategories:
                if (this.hasSelectedCategories)
                    this.currentStep = RoomDamageStep.GoodDamageTypes;
                else
                    await this.onSaveChanges();
                break;
            case RoomDamageStep.GoodDamageTypes:
                if (this.hasGoodDamages && this.hasAtLeastOneGoodDamageNotOther)
                    this.currentStep = RoomDamageStep.GoodDamageDetails;
                else
                    await this.onSaveChanges();
                break;
            case RoomDamageStep.GoodDamageDetails:
                await this.onSaveChanges();
                break;
            case RoomDamageStep.GoodDamageDescription:
                this.goBackOneStep();
                break;
        }
    }
    goBackOneStep() {
        switch (this.currentStep) {
            case RoomDamageStep.SurfaceDamages:
                this.currentStep = RoomDamageStep.RoomDamage;
                break;
            case RoomDamageStep.GoodDamageCategories:
                this.currentStep = RoomDamageStep.SurfaceDamages;
                break;
            case RoomDamageStep.GoodDamageTypes:
                this.currentStep = RoomDamageStep.GoodDamageCategories;
                break;
            case RoomDamageStep.GoodDamageDetails:
                this.currentStep = RoomDamageStep.GoodDamageTypes;
                break;
            case RoomDamageStep.GoodDamageDescription:
                this.goodDamageIndex = -1;
                this.currentStep = RoomDamageStep.GoodDamageDetails;
                break;
        }
    }
    async handleButtonClick() {
        await this.nextStep();
    }
    async onSaveChanges() {
        var _a;
        if (this.roomDamage.goodDamages) {
            for (let i = ((_a = this.roomDamage.goodDamages) === null || _a === void 0 ? void 0 : _a.length) - 1; i >= 0; i--) {
                const goodDamage = this.roomDamage.goodDamages[i];
                if (!goodDamage.type ||
                    !goodDamage.category ||
                    !this.selectedCategories.includes(goodDamage.category)) {
                    this.removeGoodDamage({
                        changeKey: this.changeKey,
                        index: i,
                    });
                }
            }
        }
        this.isSaving = true;
        try {
            await this.saveChanges();
            this.$emit('close');
        }
        catch (err) {
            Sentry.captureException(err);
        }
        finally {
            this.isSaving = false;
        }
    }
    confirmDeletion() {
        if (typeof this.changeKey !== 'string')
            return this.closeModal();
        const deleteCallback = async () => {
            this.isDeleting = true;
            this.deleteDamage(this.changeKey);
            if (this.changeKey !== null)
                await this.saveChanges();
            this.closeModal();
            this.isDeleting = false;
        };
        this.confirmDelete(this.changeKey, deleteCallback);
    }
};
__decorate([
    layout.State('isMobile')
], RoomDamageModal.prototype, "isMobile", void 0);
__decorate([
    claim.State('claim')
], RoomDamageModal.prototype, "claim", void 0);
__decorate([
    qualification.Getter('typeTrigram')
], RoomDamageModal.prototype, "typeTrigram", void 0);
__decorate([
    counting.State('counting')
], RoomDamageModal.prototype, "counting", void 0);
__decorate([
    roomDamage.Action('saveChanges')
], RoomDamageModal.prototype, "saveChanges", void 0);
__decorate([
    roomDamage.Action('validateAndRevealDamages')
], RoomDamageModal.prototype, "validateAndRevealDamages", void 0);
__decorate([
    roomDamage.Action('removeGoodDamage')
], RoomDamageModal.prototype, "removeGoodDamage", void 0);
RoomDamageModal = __decorate([
    Component({
        components: {
            Modal,
            RoomDamageForm,
            UiButton,
            RoomImage,
            UiIcon,
        },
        props: {
            isDisplayed: { type: Boolean, required: true },
            confirmDelete: { type: Function, required: true },
        },
    })
], RoomDamageModal);
export default RoomDamageModal;
