var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import DOMPurify from 'dompurify';
import { UiButton, UiIcon } from '@claims-ia/design-system';
import { QUESTION_PANEL_BIG_TO_SMALL_TRANSITION, QUESTION_PANEL_HELP_CONTENT_TRANSITION, QUESTION_PANEL_SMALL_CONTENT_TRANSITION, QUESTION_PANEL_SMALL_TO_BIG_TRANSITION, } from '@declaration/animations/questionPanel';
import { INSURER_CONFIG } from '@shared/insurer';
import { styleRGBAFromHexAndAlpha } from '@shared/utils/colors';
const layout = namespace('layout');
const pages = namespace('pages');
const helpers = namespace('helpers');
const qualification = namespace('qualification');
let QuestionPanel = class QuestionPanel extends Vue {
    constructor() {
        super(...arguments);
        this.questionPanelSmallContentTransition = QUESTION_PANEL_SMALL_CONTENT_TRANSITION;
        this.questionPanelHelpTransition = QUESTION_PANEL_HELP_CONTENT_TRANSITION;
    }
    showGlobalHelp() {
        this.showHelp(this.step);
    }
    get insurerHasAdvisorPhoneNumbers() {
        return INSURER_CONFIG.declaration.advisorPhoneNumbers !== undefined;
    }
    get displayHelpButton() {
        if (this.showHelpLink) {
            return this.checkStepHasHelp || this.insurerHasAdvisorPhoneNumbers;
        }
        else
            return false;
    }
    get checkStepHasHelp() {
        if (this.helpState) {
            return this.helpState[this.step] !== undefined;
        }
        return false;
    }
    get activeBorderColor() {
        return styleRGBAFromHexAndAlpha(this.color.questionsAsideColor, 0.4);
    }
    get questionType() {
        var _a;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return ((_a = this.$parent) === null || _a === void 0 ? void 0 : _a.$options)._componentTag;
    }
    get isActive() {
        return this.activeStep === this.step;
    }
    get isAnswered() {
        var _a;
        return (((_a = this.declarationComponentStack[this.declarationComponentStack.length - 1]) === null || _a === void 0 ? void 0 : _a.step) !==
            this.step || !!this.shouldCertify);
    }
    get purifiedTitle() {
        return DOMPurify.sanitize(this.questionTitle);
    }
    get purifiedSubTitle() {
        return this.questionSubTitle ? DOMPurify.sanitize(this.questionSubTitle) : null;
    }
    get purifiedAnswer() {
        var _a;
        return DOMPurify.sanitize((_a = this.answer) !== null && _a !== void 0 ? _a : '');
    }
    get questionPanelTransition() {
        return this.isSmall
            ? QUESTION_PANEL_BIG_TO_SMALL_TRANSITION
            : QUESTION_PANEL_SMALL_TO_BIG_TRANSITION;
    }
};
__decorate([
    layout.State('isMobile')
], QuestionPanel.prototype, "isMobile", void 0);
__decorate([
    pages.State('activeStep')
], QuestionPanel.prototype, "activeStep", void 0);
__decorate([
    pages.State('declarationComponentStack')
], QuestionPanel.prototype, "declarationComponentStack", void 0);
__decorate([
    pages.Getter('shouldCertify')
], QuestionPanel.prototype, "shouldCertify", void 0);
__decorate([
    layout.Getter('isDesktop')
], QuestionPanel.prototype, "isDesktop", void 0);
__decorate([
    layout.State('color')
], QuestionPanel.prototype, "color", void 0);
__decorate([
    helpers.State('stepHelps')
], QuestionPanel.prototype, "help", void 0);
__decorate([
    helpers.Action('show')
], QuestionPanel.prototype, "showHelp", void 0);
__decorate([
    helpers.State('stepHelps')
], QuestionPanel.prototype, "helpState", void 0);
__decorate([
    qualification.State('typeTrigram')
], QuestionPanel.prototype, "typeTrigram", void 0);
QuestionPanel = __decorate([
    Component({
        components: {
            UiButton,
            UiIcon,
        },
        props: {
            questionTitle: {
                type: String,
                required: true,
            },
            questionSubTitle: {
                type: String,
                default: undefined,
            },
            answer: {
                type: String,
                default: null,
            },
            isSmall: {
                type: Boolean,
                default: false,
            },
            isLocked: {
                type: Boolean,
                required: true,
            },
            isLargeWidth: {
                type: Boolean,
                default: false,
            },
            hasPaddingMarginInQuestionContent: {
                type: Boolean,
                default: true,
            },
            displayTitleWhenLarge: {
                type: Boolean,
                default: true,
            },
            image: {
                type: String,
                required: false,
            },
            imageAlt: {
                type: String,
                required: false,
            },
            step: {
                type: String,
                required: true,
            },
            showHelpLink: {
                type: Boolean,
                default: true,
            },
            helpLabel: {
                type: String,
                default() {
                    return this.$tc('help.default.question');
                },
            },
            custom: {
                type: Boolean,
                default: false,
            },
        },
    })
], QuestionPanel);
export default QuestionPanel;
