var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import Checkout from '@declaration/components/Payout/Checkout.vue';
import { uuidFromIri } from '@shared/utils/iri';
import formatAmount from '@shared/utils/formatAmount';
import { formatDate } from '@shared/utils/date';
import { WHITE_LAYOUT_COLOR } from '@declaration/layout';
import { Step } from '@shared/claim/steps';
import { ClaimStatus } from '@shared/types/api/claim';
const claim = namespace('claim');
const counting = namespace('counting');
let PayoutSuccess = class PayoutSuccess extends Vue {
    constructor() {
        super(...arguments);
        this.isRedirecting = false;
        this.successImage = require('@declaration/assets/images/payout/success.svg');
    }
    get payout() {
        var _a;
        return (_a = this.claim.delayedPayout) !== null && _a !== void 0 ? _a : this.claim.immediatePayout;
    }
    get formattedCreatedAt() {
        var _a;
        return ((_a = this.payout) === null || _a === void 0 ? void 0 : _a.createdAt) ? formatDate(this.payout.createdAt) : '';
    }
    get formattedAmount() {
        var _a;
        return formatAmount((((_a = this.payout) === null || _a === void 0 ? void 0 : _a.amount) || 0) / 100);
    }
    get cardNumberWithLastFourShown() {
        if (!this.payout) {
            return '';
        }
        return `XXXX XXXX XXXX ${this.payout.cardLastFour}`;
    }
    get actionButtonText() {
        return this.claim.status !== ClaimStatus.DelayedPayoutPending &&
            this.claim.isPendingRepairQuotePending
            ? this.$tc('payout.success.redirection.waiting_documents')
            : this.$tc('payout.success.redirection.home');
    }
    get reference() {
        var _a;
        const iri = (_a = this.payout) === null || _a === void 0 ? void 0 : _a.id;
        if (!iri) {
            return '';
        }
        return uuidFromIri(iri);
    }
    async redirectWithBrms() {
        this.isRedirecting = true;
        await this.updateAndSaveClaimStep(this.claim.delayedPayout ? Step.DelayedPayoutSuccess : Step.ImmediatePayoutSuccess);
        this.isRedirecting = false;
    }
    returnHome() {
        this.$router.push('/');
    }
};
__decorate([
    claim.State('claim')
], PayoutSuccess.prototype, "claim", void 0);
__decorate([
    claim.Action('updateAndSaveClaimStep')
], PayoutSuccess.prototype, "updateAndSaveClaimStep", void 0);
__decorate([
    counting.State('counting')
], PayoutSuccess.prototype, "counting", void 0);
PayoutSuccess = __decorate([
    Component({
        components: {
            Checkout,
            UiButton,
        },
        meta: {
            title: 'page.title.payout.success',
            goBack: null,
            color: WHITE_LAYOUT_COLOR,
        },
    })
], PayoutSuccess);
export default PayoutSuccess;
