var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { breakInDamageTitle } from '@shared/breakInDamage/breakInDamageFields';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import BreakInDamagesList from '@declaration/components/breakInDamage/BreakInDamagesList.vue';
import { RobberyEventType } from '@shared/types/api/claim';
import { fileCollectionFilesCount } from '@shared/files/uploadedFiles';
const breakInDamage = namespace('breakInDamage');
const qualification = namespace('qualification');
let BreakInDamagesQuestion = class BreakInDamagesQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
    }
    get title() {
        if (this.isVandalismClaim) {
            return this.$tc('claim.declaration.break_in_damages.vandalism.question.title', this.damagesCount);
        }
        return this.$tc('claim.declaration.break_in_damages.question.title', this.damagesCount);
    }
    get isVandalismClaim() {
        return this.qualification ? this.qualification.eventType === RobberyEventType.Vandalism : false;
    }
    get answer() {
        if (!this.damages.length) {
            return this.$tc('claim.damage.break_in_damages.question.answer.no_damages');
        }
        const breakInDamagesDetailsWithFilesArray = this.damages.map((damage) => fileCollectionFilesCount(damage.repairFileCollection) +
            fileCollectionFilesCount(damage.pendingRepairFileCollection) +
            fileCollectionFilesCount(damage.pictureCollection) ===
            0
            ? `<div><b>${breakInDamageTitle(damage, this.$tc.bind(this)).toLowerCase()}</b>
        <div>${this.$tc('model.break_in_damage.documents', fileCollectionFilesCount(damage.repairFileCollection) +
                fileCollectionFilesCount(damage.pendingRepairFileCollection) +
                fileCollectionFilesCount(damage.pictureCollection))} : ${this.$tc('claim.break_in_damage.receipt.type.none')}`
            : `<div><b>${breakInDamageTitle(damage, this.$tc.bind(this)).toLowerCase()}</b>
        <div>${this.$tc('model.break_in_damage.documents', fileCollectionFilesCount(damage.repairFileCollection) +
                fileCollectionFilesCount(damage.pendingRepairFileCollection) +
                fileCollectionFilesCount(damage.pictureCollection))} : ${fileCollectionFilesCount(damage.pendingRepairFileCollection) > 0
                ? this.$tc('model.break_in_damage.documents.quote').toLowerCase()
                : ''}${fileCollectionFilesCount(damage.repairFileCollection) > 0
                ? fileCollectionFilesCount(damage.pendingRepairFileCollection) > 0
                    ? ', ' +
                        this.$tc('model.break_in_damage.documents.invoice', fileCollectionFilesCount(damage.repairFileCollection)).toLowerCase()
                    : this.$tc('model.break_in_damage.documents.invoice', fileCollectionFilesCount(damage.repairFileCollection)).toLowerCase()
                : ''}${(fileCollectionFilesCount(damage.pendingRepairFileCollection) > 0 ||
                fileCollectionFilesCount(damage.repairFileCollection) > 0) &&
                fileCollectionFilesCount(damage.pictureCollection) > 0
                ? ','
                : ''} ${fileCollectionFilesCount(damage.pictureCollection) === 0
                ? ''
                : fileCollectionFilesCount(damage.pictureCollection)} ${fileCollectionFilesCount(damage.pictureCollection) === 0
                ? ''
                : this.$tc('model.break_in_damage.documents.picture', fileCollectionFilesCount(damage.pictureCollection)).toLowerCase()}</div></div>`);
        breakInDamagesDetailsWithFilesArray.unshift(this.$tc('claim.damage.break_in_damages.question.answer.with_damages', this.damagesCount));
        return breakInDamagesDetailsWithFilesArray.join('');
    }
    get damagesCount() {
        return this.damages.length;
    }
    get areBreakInDamagesComplete() {
        // this is still computed in case we need to make it more complex
        return true;
    }
    get validationButtonText() {
        return this.damagesCount
            ? this.$tc('claim.declaration.break_in_damages.validate.with_damages')
            : this.$tc('claim.declaration.break_in_damages.validate.no_damages');
    }
    askToComplete() {
        this.$buefy.toast.open({
            message: this.$tc('claim.declaration.break_in_damages.validate.error.ask_to_complete'),
            type: 'is-danger',
            position: 'is-bottom',
        });
    }
    async handleValidationClick() {
        this.isSaving = true;
        await this.validateBreakInDamagesStep();
        this.isSaving = false;
    }
};
__decorate([
    breakInDamage.State('damages')
], BreakInDamagesQuestion.prototype, "damages", void 0);
__decorate([
    breakInDamage.Action('validateBreakInDamagesStep')
], BreakInDamagesQuestion.prototype, "validateBreakInDamagesStep", void 0);
__decorate([
    qualification.State('qualification')
], BreakInDamagesQuestion.prototype, "qualification", void 0);
BreakInDamagesQuestion = __decorate([
    Component({
        components: { QuestionPanel, BreakInDamagesList, UiButton },
        props: QuestionProps,
    })
], BreakInDamagesQuestion);
export default BreakInDamagesQuestion;
