var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const contract = namespace('contract');
const settings = namespace('settings');
let QuitApplicationButton = class QuitApplicationButton extends Vue {
    get buttonLabel() {
        var _a;
        return (_a = this.buttonText) !== null && _a !== void 0 ? _a : this.$tc('claim.button.back_home');
    }
    actionHandler() {
        if (this.buttonAction !== null) {
            this.buttonAction();
        }
        else if (this.contractNumber &&
            this.setting('customerAreaUrl') !== null &&
            insurerHasFeature(InsurerFeatureFlag.AddContractNumberToCustomerAreaUrl)) {
            this.$goBackHome(undefined, this.contractNumber);
        }
        else {
            this.$goBackHome();
        }
    }
};
__decorate([
    contract.Getter('contractNumber')
], QuitApplicationButton.prototype, "contractNumber", void 0);
__decorate([
    settings.Getter('setting')
], QuitApplicationButton.prototype, "setting", void 0);
QuitApplicationButton = __decorate([
    Component({
        components: {
            UiButton,
        },
        props: {
            buttonText: {
                type: String,
                default: null,
            },
            buttonAction: {
                type: Function,
                default: null,
            },
        },
    })
], QuitApplicationButton);
export default QuitApplicationButton;
