var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { UiButton } from '@claims-ia/design-system';
import DateAndTimePicker from '@declaration/components/UI/DateAndTimePicker.vue';
import { isIOS } from '@shared/utils/device';
let DateWithValidation = class DateWithValidation extends Vue {
    constructor() {
        super(...arguments);
        this.dateValueToValidate = null;
    }
    created() {
        this.$watch('value', (newValue) => {
            this.dateValueToValidate = newValue;
        }, { immediate: true });
    }
    /**
     * On iOS, input event is triggered when date selection picker is opened and at
     * each move of one of the year, month or day wheels.
     * In this case automatically updating the claim on input do not work and
     * we add a confirmation button.
     */
    get shouldConfirmInput() {
        return this.hasHoursAndMinutes || isIOS();
    }
    updateValue(date) {
        this.$emit('on-value-update', date);
    }
    confirmInput() {
        if (this.dateValueToValidate) {
            this.updateValue(this.dateValueToValidate);
        }
    }
    onInput(date) {
        this.dateValueToValidate = date;
        if (this.shouldConfirmInput) {
            return;
        }
        this.updateValue(date);
    }
};
DateWithValidation = __decorate([
    Component({
        components: { UiButton, DateAndTimePicker },
        props: {
            value: {
                type: Date,
                default: null,
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
            maxDate: {
                type: Date,
                default: null,
            },
            hasHoursAndMinutes: {
                type: Boolean,
                default: false,
            },
        },
    })
], DateWithValidation);
export default DateWithValidation;
