var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import EquipmentList from '@declaration/components/equipment/EquipmentList.vue';
const equipment = namespace('equipment');
let EquipmentQuestion = class EquipmentQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
    }
    get answer() {
        if (!this.equipments.length) {
            return this.$tc('claim.declaration.equipments.question.answer.no_damages');
        }
        const mainAnswer = this.$tc('claim.declaration.equipments.question.answer.with_damages', this.equipments.length > 1 ? 2 : 1, { count: this.equipments.length });
        return `${mainAnswer} : ${this.equipments
            .map((equipment) => `<strong>${equipment.name}</strong>`)
            .join(', ')}`;
    }
    get validationButtonText() {
        return this.equipments.length === 0
            ? this.$tc('claim.declaration.equipments.question.validate.no_equipment')
            : this.$tc('claim.declaration.equipments.question.validate.with_equipment');
    }
    askToComplete() {
        this.$buefy.toast.open({
            message: this.$tc('claim.declaration.equipments.validate.error.ask_to_complete'),
            type: 'is-danger',
            position: 'is-bottom',
        });
    }
    async handleValidationClick() {
        this.isSaving = true;
        await this.validateEquipmentsStep();
        this.isSaving = false;
    }
};
__decorate([
    equipment.State('damages')
], EquipmentQuestion.prototype, "equipments", void 0);
__decorate([
    equipment.Action('validateEquipmentsStep')
], EquipmentQuestion.prototype, "validateEquipmentsStep", void 0);
__decorate([
    equipment.Getter('areDamagesValid')
], EquipmentQuestion.prototype, "areDamagesValid", void 0);
EquipmentQuestion = __decorate([
    Component({
        components: { QuestionPanel, UiButton, EquipmentList },
        props: QuestionProps,
    })
], EquipmentQuestion);
export default EquipmentQuestion;
