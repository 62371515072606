var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import DamageCard from '../UI/DamageCard.vue';
import AddButton from '@declaration/components/UI/AddButton.vue';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import PersonalPropertyModal from '@declaration/components/personalProperty/PersonalPropertyModal.vue';
import PersonalPropertyModalV2 from '@declaration/components/personalProperty/v2/PersonalPropertyModal.vue';
import { ExclusionReason } from '@shared/types/api/refundDetail';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import { personalPropertyFields } from '@shared/personalProperty/personalPropertyFields';
import { isPersonalProperty } from '@shared/damage/damage';
import { PersonalPropertyInitialCategorySlug, RepairableStatus, } from '@shared/types/api/personalProperty';
import { ModelFieldsProjection } from '@shared/modelField/modelField';
import { PERSONAL_PROPERTY_QUESTION_TRAD_KEYS, } from '@declaration/types/personalProperty';
import { optionalCategoryIri, optionalResourceIri } from '@shared/utils/iri';
import { INITIAL_PERSONAL_PROPERTY_CATEGORIES } from '@shared/constants/personalPropertyConstants';
const personalProperty = namespace('personalProperty');
const qualification = namespace('qualification');
const settings = namespace('settings');
const claim = namespace('claim');
let PersonalPropertyList = class PersonalPropertyList extends Vue {
    constructor() {
        super(...arguments);
        this.isPersonalPropertyModalOpen = false;
        this.selectedChangeKey = null;
        this.cardSaving = false;
    }
    get selectedIndex() {
        return this.filteredPersonalProperties.length;
    }
    selectItemAndOpenHandler(damage) {
        if (this.selectItemAndOpenModal !== null) {
            this.selectItemAndOpenModal(damage);
        }
    }
    deleteItemHandler(damage) {
        this.$emit('on-delete-damage', damage);
    }
    selectPersonalPropertyAndOpenModal(personalProperty) {
        this.selectedChangeKey = personalProperty.id;
        this.isPersonalPropertyModalOpen = true;
    }
    addNewDamageHandler() {
        this.$emit('modale-opened');
    }
    get filteredPersonalProperties() {
        return this.personalProperties.filter((personalProperty) => {
            return (optionalResourceIri(personalProperty.category) !==
                optionalCategoryIri('denrees-alimentaires', this.categoriesByIri));
        });
    }
    get tradKeys() {
        return PERSONAL_PROPERTY_QUESTION_TRAD_KEYS[this.typeTrigram];
    }
    get personalPropertiesCount() {
        return this.filteredPersonalProperties.length;
    }
    get canAddNewPersonalProperty() {
        var _a, _b;
        switch (this.typeTrigram) {
            case ClaimTypeTrigram.Glass:
                return (this.personalPropertiesCount <
                    ((_a = this.settingGetter('bdgPersonalPropertiesCountThresholdBeforeExpertise')) !== null && _a !== void 0 ? _a : 1));
            case ClaimTypeTrigram.Fire:
                return (this.personalPropertiesCount <
                    ((_b = this.settingGetter('incPersonalPropertiesCountThresholdBeforeExpertise')) !== null && _b !== void 0 ? _b : 1));
            default:
                return true;
        }
    }
    waitingForRepairFileWarning(damage) {
        return this.$tc(damage.repairableStatus === RepairableStatus.Irreparable
            ? 'claim.damage.personal_property.sumup.waiting_for_irreparable_certificate'
            : 'claim.damage.personal_property.sumup.waiting_for_repair_quote');
    }
    get isELE() {
        return this.typeTrigram === ClaimTypeTrigram.Electric;
    }
    addNewPersonalProperty() {
        this.selectedChangeKey = 0;
        this.isPersonalPropertyModalOpen = true;
    }
    closeModal() {
        this.isPersonalPropertyModalOpen = false;
        this.resetChanges();
    }
    confirmDelete(personalPropertyIri, deleteCallback) {
        if (personalPropertyIri) {
            this.$buefy.dialog.confirm({
                animation: 'fade',
                message: this.$tc(this.tradKeys.confirmDeletion.message),
                cancelText: this.$tc(this.tradKeys.confirmDeletion.cancel),
                confirmText: this.$tc(this.tradKeys.confirmDeletion.confirm),
                onConfirm: deleteCallback,
            });
        }
        else {
            deleteCallback();
        }
    }
    onMissingProduct() {
        this.$emit('missing-product');
    }
    onOtherProduct() {
        this.$emit('other-product');
    }
    isComplete(damage) {
        return this.isDamageValid(damage) && damage.id;
    }
    canEdit(damage) {
        return !this.isComplete(damage);
    }
    isExcludedByGuarantees(damage) {
        if ('exclusionReason' in damage) {
            return (damage.exclusionReason === ExclusionReason.ContractGuarantees ||
                damage.exclusionReason === ExclusionReason.NotIncludedInOutsideRobbery);
        }
        else
            return false;
    }
    shouldDisplayRepairQuoteInfo(damage) {
        if ('isWaitingForRepairFile' in damage) {
            return (claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyRepair) &&
                damage.isWaitingForRepairFile);
        }
        else
            return false;
    }
    isWaitingForRepairFile(damage) {
        return damage.isWaitingForRepairFile;
    }
    onRepairQuoteOptout(damage) {
        this.$buefy.dialog.confirm({
            animation: 'fade',
            title: this.$tc('claim.damage.personal_property.sumup.repair_quote_optout'),
            message: this.$tc('claim.damage.personal_property.sumup.repair_quote_optout.confirm'),
            onCancel: () => {
                // No-op
            },
            onConfirm: async () => {
                this.updatePersonalProperty({
                    changeKey: damage.id,
                    data: {
                        repairableStatus: RepairableStatus.Excluded,
                        repairQuoteInfo: null,
                        repairFileCollection: {
                            id: damage.repairFileCollection.id,
                            uploadedAppFiles: [],
                        },
                    },
                });
                this.cardSaving = true;
                if (this.currentStep) {
                    await this.saveChanges({ step: this.currentStep });
                }
                else {
                    await this.saveChanges();
                }
                this.cardSaving = false;
            },
            confirmText: this.$tc('appointment.artisan.date_selection.confirm_popup.confirm'),
            cancelText: this.$tc('appointment.artisan.date_selection.confirm_popup.cancel'),
        });
    }
    modelFields(damage) {
        return personalPropertyFields(damage, {
            edition: false,
            type: this.typeTrigram,
            projection: ModelFieldsProjection.Declaration,
            translate: this.$tc.bind(this),
            categories: this.categoriesByIri,
        });
    }
    get damagesCount() {
        return this.damages.length;
    }
    get canAddNewDamage() {
        var _a, _b;
        switch (this.typeTrigram) {
            case ClaimTypeTrigram.Glass:
                return (this.damagesCount <
                    ((_a = this.settingGetter('bdgPersonalPropertiesCountThresholdBeforeExpertise')) !== null && _a !== void 0 ? _a : 1));
            case ClaimTypeTrigram.Fire:
                return (this.damagesCount <
                    ((_b = this.settingGetter('incPersonalPropertiesCountThresholdBeforeExpertise')) !== null && _b !== void 0 ? _b : 1));
            default:
                return true;
        }
    }
    get shouldDescribeOther() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyDescribeOther);
    }
    isAmountOnly(damage) {
        if (isPersonalProperty(damage)) {
            return this.isAmountOnlyGetter(damage);
        }
        else
            return false;
    }
    isIdentityDocument(damage) {
        if (isPersonalProperty(damage)) {
            return this.isIdentityDocumentGetter(damage);
        }
        else
            return false;
    }
    title(damage, index) {
        var _a;
        const positionSuffix = this.getCategoryPositionSuffix(damage, index);
        if (damage.initialCategorySlug === PersonalPropertyInitialCategorySlug.Autres ||
            this.shouldDescribeOther ||
            this.isAmountOnly(damage) ||
            this.isIdentityDocument(damage))
            if (damage.initialCategorySlug) {
                return (this.$tc(INITIAL_PERSONAL_PROPERTY_CATEGORIES[damage.initialCategorySlug].labelTradKey) +
                    (positionSuffix ? ` ${positionSuffix}` : ''));
            }
        return ((_a = this.getCategorySubLabel(optionalResourceIri(damage.category), index)) !== null && _a !== void 0 ? _a : this.$tc(this.tradKeys.card.title, 0, { number: index + 1 }));
    }
};
__decorate([
    claim.Getter('currentStep')
], PersonalPropertyList.prototype, "currentStep", void 0);
__decorate([
    personalProperty.State('damages')
], PersonalPropertyList.prototype, "personalProperties", void 0);
__decorate([
    personalProperty.State('categories')
], PersonalPropertyList.prototype, "categories", void 0);
__decorate([
    personalProperty.Action('updateDamage')
], PersonalPropertyList.prototype, "updatePersonalProperty", void 0);
__decorate([
    personalProperty.Getter('isDamageValid')
], PersonalPropertyList.prototype, "isDamageValid", void 0);
__decorate([
    personalProperty.State('categoriesByIri')
], PersonalPropertyList.prototype, "categoriesByIri", void 0);
__decorate([
    personalProperty.Action('resetChanges')
], PersonalPropertyList.prototype, "resetChanges", void 0);
__decorate([
    personalProperty.Action('saveChanges')
], PersonalPropertyList.prototype, "saveChanges", void 0);
__decorate([
    personalProperty.Getter('isAmountOnly')
], PersonalPropertyList.prototype, "isAmountOnlyGetter", void 0);
__decorate([
    personalProperty.Getter('isIdentityDocument')
], PersonalPropertyList.prototype, "isIdentityDocumentGetter", void 0);
__decorate([
    personalProperty.Getter('getCategorySubLabel')
], PersonalPropertyList.prototype, "getCategorySubLabel", void 0);
__decorate([
    personalProperty.Getter('getCategoryPositionSuffix')
], PersonalPropertyList.prototype, "getCategoryPositionSuffix", void 0);
__decorate([
    qualification.State('typeTrigram')
], PersonalPropertyList.prototype, "typeTrigram", void 0);
__decorate([
    settings.Getter('setting')
], PersonalPropertyList.prototype, "settingGetter", void 0);
PersonalPropertyList = __decorate([
    Component({
        components: {
            PersonalPropertyModalV2,
            PersonalPropertyModal,
            UiButton,
            DamageCard,
            AddButton,
        },
        props: {
            addButtonOutlined: {
                type: Boolean,
                default: false,
            },
            damageType: {
                type: String,
                default: null,
            },
            damages: {
                type: Array,
                default: () => [],
            },
            selectItemAndOpenModal: {
                type: Function,
                default: null,
            },
            confirmDamageDelete: {
                type: Function,
                default: null,
            },
        },
    })
], PersonalPropertyList);
export default PersonalPropertyList;
