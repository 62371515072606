var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import Checkout from '@declaration/components/Payout/Checkout.vue';
import { uuidFromIri } from '@shared/utils/iri';
import { WHITE_LAYOUT_COLOR } from '@declaration/layout';
import { Step } from '@shared/claim/steps';
import { PayoutStatus } from '@declaration/types/payout';
import { ClaimStatus } from '@shared/types/api/claim';
const claim = namespace('claim');
let PayoutPending = class PayoutPending extends Vue {
    constructor() {
        super(...arguments);
        this.isPollingStatus = true;
    }
    async created() {
        if (!this.claim.id) {
            return;
        }
        this.isPollingStatus = true;
        try {
            const response = await this.$axios.$get(`/checkout/poll-pending-payout/${uuidFromIri(this.claim.id)}`);
            if (response.status === PayoutStatus.Succeeded || response.status === PayoutStatus.Failed) {
                await this.updateAndSaveClaimStep(this.step);
            }
        }
        catch (_a) {
            this.$store.dispatch('error/add', this.$tc('payout.pending.error'));
        }
        this.isPollingStatus = false;
    }
    get step() {
        return this.claim.status === ClaimStatus.ImmediatePayoutPending
            ? Step.ImmediatePayoutIndex
            : Step.DelayedPayoutIndex;
    }
    handleBeCalledClick() {
        this.updateAndSaveClaim({
            claimPayload: { isAdvisorCallWanted: true },
            step: this.step,
        });
    }
};
__decorate([
    claim.State('claim')
], PayoutPending.prototype, "claim", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], PayoutPending.prototype, "updateAndSaveClaim", void 0);
__decorate([
    claim.Action('updateAndSaveClaimStep')
], PayoutPending.prototype, "updateAndSaveClaimStep", void 0);
PayoutPending = __decorate([
    Component({
        components: {
            Checkout,
            UiButton,
        },
        meta: {
            title: 'page.title.payout.index',
            goBack: null,
            color: WHITE_LAYOUT_COLOR,
        },
    })
], PayoutPending);
export default PayoutPending;
