var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { RobberySecurizationMethod } from '@shared/types/api/claim';
const claim = namespace('claim');
const qualification = namespace('qualification');
let RobberyHouseSecuredYesNoQuestion = class RobberyHouseSecuredYesNoQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
        this.showWarning = false;
    }
    updateClaim(homeSecured) {
        if (!homeSecured) {
            this.showWarning = true;
            return;
        }
        this.updateAndSaveQualification({
            qualificationPayload: {
                homeSecured: RobberySecurizationMethod.Unnecessary,
            },
            step: this.step,
        });
    }
    get answer() {
        if (this.qualification.homeSecured === null) {
            return null;
        }
        return this.qualification.homeSecured
            ? this.$tc('claim.qualification.robbery.house_secured.answer.yes')
            : this.$tc('claim.qualification.robbery.house_secured.answer.no');
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], RobberyHouseSecuredYesNoQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], RobberyHouseSecuredYesNoQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], RobberyHouseSecuredYesNoQuestion.prototype, "updateAndSaveQualification", void 0);
RobberyHouseSecuredYesNoQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton, UiButton },
        props: QuestionProps,
    })
], RobberyHouseSecuredYesNoQuestion);
export default RobberyHouseSecuredYesNoQuestion;
