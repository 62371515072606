var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import BreakInDamageDescriptionSectionCard from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionSectionCard.vue';
import BreakInDamageDescriptionStep from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionStep.vue';
import { Material } from '@shared/types/api/breakInDamage';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import BreakInDamageCheckBox from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageCheckBox.vue';
import BreakInDamageDescriptionSubSection from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionSubSection.vue';
import { parseLength } from '@declaration/helpers/typeFormatters';
import BreakInDamageChoices from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageChoices.vue';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { METAL_LITTLE_GATE_IMPACTS_NUMBER, PVC_LITTLE_GATE_IMPACTS_NUMBER, WOODEN_LITTLE_GATE_IMPACTS_NUMBER, } from '@shared/constants/breakInDamages/LittleGateConstants';
const layout = namespace('layout');
const breakInDamage = namespace('breakInDamage');
let LittleGateImpacts = class LittleGateImpacts extends Vue {
    constructor() {
        super(...arguments);
        this.parseLength = parseLength;
        this.isSaving = false;
        this.showValidation = false;
        this.YES_OR_NO_CHOICES = YES_OR_NO_CHOICES;
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get breakInDamage() {
        return this.change.data;
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get isValid() {
        return !this.validation.fieldHasViolations('numberOfImpacts');
    }
    update(data) {
        this.updateBreakInDamage({ changeKey: this.changeKey, data });
    }
    save() {
        this.isSaving = true;
        this.$emit('goToNextStep');
        this.isSaving = false;
    }
    revealValidation() {
        this.$store.dispatch('error/add', this.$tc('error.form.please_validate_message'));
        this.validateBreakInDamage(this.changeKey);
        this.showValidation = true;
    }
    get numberOfImpacts() {
        if (this.breakInDamage.material === Material.Wood) {
            return WOODEN_LITTLE_GATE_IMPACTS_NUMBER;
        }
        if (this.breakInDamage.material === Material.Metal) {
            return METAL_LITTLE_GATE_IMPACTS_NUMBER;
        }
        if (this.breakInDamage.material === Material.PVC) {
            return PVC_LITTLE_GATE_IMPACTS_NUMBER;
        }
        return null;
    }
};
__decorate([
    layout.State('color')
], LittleGateImpacts.prototype, "color", void 0);
__decorate([
    breakInDamage.Getter('change')
], LittleGateImpacts.prototype, "changeGetter", void 0);
__decorate([
    breakInDamage.Action('updateDamage')
], LittleGateImpacts.prototype, "updateBreakInDamage", void 0);
__decorate([
    breakInDamage.Getter('validation')
], LittleGateImpacts.prototype, "validationGetter", void 0);
__decorate([
    breakInDamage.Action('validateAndRevealDamages')
], LittleGateImpacts.prototype, "validateBreakInDamage", void 0);
LittleGateImpacts = __decorate([
    Component({
        components: {
            BreakInDamageDescriptionStep,
            BreakInDamageDescriptionSectionCard,
            RadioPrimaryButton,
            BreakInDamageCheckBox,
            BreakInDamageDescriptionSubSection,
            BreakInDamageChoices,
        },
        props: {
            changeKey: { type: [String, Number], default: null },
            isFinalStep: { type: Boolean, default: false },
        },
    })
], LittleGateImpacts);
export default LittleGateImpacts;
