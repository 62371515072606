var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import CommonArea from '@declaration/components/svg/CommonArea.vue';
import { QUALIFICATION_COMMON_AREA_DETAIL } from '@shared/constants/equipmentConstants';
import { COMMON_AREA_DETAILS } from '@declaration/constants/equipmentConstants';
import { INSURER_CONFIG } from '@shared/insurer';
const layout = namespace('layout');
const qualification = namespace('qualification');
const claim = namespace('claim');
let LeakageCauseCommonAreaQuestion = class LeakageCauseCommonAreaQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.selectedDetail = null;
        this.hoveredDetail = null;
    }
    created() {
        this.selectedDetail = this.waterDamageQualification.leakageCauseDetailSlug;
    }
    get detailValuesAndTradKeys() {
        var _a, _b;
        const leakageCauseCommonAreaDetails = (_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.leakageCauseCommonAreaDetails) !== null && _b !== void 0 ? _b : COMMON_AREA_DETAILS;
        return leakageCauseCommonAreaDetails.map((slug) => {
            return {
                value: slug,
                tradKey: QUALIFICATION_COMMON_AREA_DETAIL[slug].tradKey,
            };
        });
    }
    updateClaim() {
        this.updateAndSaveWaterDamageQualification({
            qualificationPayload: { leakageCauseDetailSlug: this.selectedDetail },
            step: this.step,
        });
    }
    updateSelected(leakageCauseDetailSlug) {
        this.selectedDetail = leakageCauseDetailSlug;
    }
    updateHover(leakageCauseDetailSlug) {
        this.hoveredDetail = leakageCauseDetailSlug;
    }
    get answer() {
        if (this.waterDamageQualification.leakageCauseDetailSlug === null) {
            return null;
        }
        if (!QUALIFICATION_COMMON_AREA_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug]) {
            return this.$tc('claim.qualification.leakage_cause_common_area_detail.sumup.default');
        }
        return this.$tc(QUALIFICATION_COMMON_AREA_DETAIL[this.waterDamageQualification.leakageCauseDetailSlug]
            .leakageCauseCommonAreaDetailAnswerTradKey);
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], LeakageCauseCommonAreaQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], LeakageCauseCommonAreaQuestion.prototype, "waterDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], LeakageCauseCommonAreaQuestion.prototype, "updateAndSaveWaterDamageQualification", void 0);
__decorate([
    layout.Getter('isDesktop')
], LeakageCauseCommonAreaQuestion.prototype, "isDesktop", void 0);
LeakageCauseCommonAreaQuestion = __decorate([
    Component({
        components: {
            UiButton,
            CommonArea,
            RadioPrimaryButton,
            QuestionPanel,
        },
        props: QuestionProps,
    })
], LeakageCauseCommonAreaQuestion);
export default LeakageCauseCommonAreaQuestion;
