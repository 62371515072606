var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { UiIcon, UiButton } from '@claims-ia/design-system';
import { namespace } from 'vuex-class';
import EquipmentForm from '@declaration/components/equipment/EquipmentForm.vue';
import Modal from '@declaration/components/UI/Modal.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
const equipment = namespace('equipment');
let EquipmentModal = class EquipmentModal extends Vue {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.isDeleting = false;
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get equipment() {
        return this.change.data;
    }
    get isValid() {
        // make assertion on name because when the modal is initially open,
        // the store is considered as valid as no object is created
        return this.validation.isValid && !!this.equipment.name;
    }
    revealValidation() {
        this.$store.dispatch('error/add', this.$tc('error.form.please_validate_message'));
        this.validateEquipment(this.changeKey);
    }
    async save() {
        this.isSaving = true;
        try {
            await this.saveChanges();
            this.closeModal();
        }
        catch (err) {
            this.$store.dispatch('error/add', this.$tc('error.snackbar.info'));
        }
        this.isSaving = false;
    }
    closeModal() {
        this.$emit('close');
    }
    onClose() {
        this.closeModal();
    }
    confirmDeletion() {
        const deleteCallback = async () => {
            this.isDeleting = true;
            await this.deleteEquipment(this.changeKey);
            // Delete from backend if it is persisted
            if (this.changeKey !== null) {
                await this.saveChanges();
            }
            this.closeModal();
            this.isDeleting = false;
        };
        this.confirmDelete(this.changeKey, deleteCallback);
    }
};
__decorate([
    equipment.Getter('change')
], EquipmentModal.prototype, "changeGetter", void 0);
__decorate([
    equipment.Getter('validation')
], EquipmentModal.prototype, "validationGetter", void 0);
__decorate([
    equipment.Action('saveChanges')
], EquipmentModal.prototype, "saveChanges", void 0);
__decorate([
    equipment.Action('validateAndRevealDamages')
], EquipmentModal.prototype, "validateEquipment", void 0);
__decorate([
    equipment.Action('updateDamage')
], EquipmentModal.prototype, "updateEquipment", void 0);
__decorate([
    equipment.Action('selectWithInitialCategory')
], EquipmentModal.prototype, "selectWithInitialCategory", void 0);
__decorate([
    equipment.Action('deleteDamage')
], EquipmentModal.prototype, "deleteEquipment", void 0);
EquipmentModal = __decorate([
    Component({
        components: {
            Modal,
            RadioPrimaryButton,
            UiButton,
            EquipmentForm,
            UiIcon,
        },
        props: {
            isDisplayed: { type: Boolean, required: true },
            changeKey: { type: [Number, String], default: null },
            confirmDelete: { type: Function, required: true },
        },
    })
], EquipmentModal);
export default EquipmentModal;
