var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { PersonalRobberyEventType } from '@shared/types/api/claim';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { PERSONAL_ROBBERY_EVENT_TYPE_CHOICES } from '@shared/constants/robberyConstants';
const claim = namespace('claim');
const qualification = namespace('qualification');
let PersonalRobberyEventTypeQuestion = class PersonalRobberyEventTypeQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.choices = PERSONAL_ROBBERY_EVENT_TYPE_CHOICES;
        this.eventType = null;
        this.displayEventCircumstance = false;
        this.otherEventTypeCircumstances = '';
    }
    created() {
        var _a;
        this.eventType = this.qualification.personalRobberyEventType;
        if (this.eventType === PersonalRobberyEventType.Other) {
            this.displayEventCircumstance = true;
        }
        this.otherEventTypeCircumstances = (_a = this.qualification.personalRobberyEventCircumstances) !== null && _a !== void 0 ? _a : '';
    }
    updateClaim(personalRobberyEventType) {
        this.eventType = personalRobberyEventType;
        if (personalRobberyEventType === PersonalRobberyEventType.Other) {
            this.displayEventCircumstance = true;
        }
        else {
            this.displayEventCircumstance = false;
            this.otherEventTypeCircumstances = '';
            this.updateAndSaveQualification({
                qualificationPayload: {
                    personalRobberyEventType,
                    personalRobberyEventCircumstances: '',
                },
                step: this.step,
            });
        }
    }
    updateClaimWithCircumstances() {
        this.eventType = PersonalRobberyEventType.Other;
        this.updateAndSaveQualification({
            qualificationPayload: {
                personalRobberyEventType: PersonalRobberyEventType.Other,
                personalRobberyEventCircumstances: this.otherEventTypeCircumstances,
            },
            step: this.step,
        });
    }
    get answer() {
        if (this.qualification.personalRobberyEventType === null) {
            return null;
        }
        if (this.qualification.personalRobberyEventType === PersonalRobberyEventType.Other) {
            return this.$tc('claim.qualification.robbery.personal_robbery.event_type.answer.other', 1, {
                circumstances: this.qualification.personalRobberyEventCircumstances,
            });
        }
        return this.$tc('claim.qualification.robbery.personal_robbery.event_type.answer.' +
            this.qualification.personalRobberyEventType);
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], PersonalRobberyEventTypeQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], PersonalRobberyEventTypeQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], PersonalRobberyEventTypeQuestion.prototype, "updateAndSaveQualification", void 0);
PersonalRobberyEventTypeQuestion = __decorate([
    Component({
        components: { UiButton, QuestionPanel, RadioPrimaryButton },
        props: QuestionProps,
    })
], PersonalRobberyEventTypeQuestion);
export default PersonalRobberyEventTypeQuestion;
