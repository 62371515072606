var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import PersonalPropertyList from './PersonalPropertyList.vue';
import { personalPropertyContractWarnings } from '@backoffice/claim/warnings/claimWarnings';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { PERSONAL_PROPERTY_QUESTION_TRAD_KEYS, } from '@declaration/types/personalProperty';
import { INITIAL_PERSONAL_PROPERTY_CATEGORIES, PERSONAL_PROPERTY_IDENTITY_DOCUMENT_TYPES_TRAD_KEY, PERSONAL_PROPERTY_RECEIPT_TYPES_TRAD_KEY, } from '@shared/constants/personalPropertyConstants';
import { PersonalPropertyReceiptType, RepairableStatus } from '@shared/types/api/personalProperty';
import { fileCollectionFilesCount } from '@shared/files/uploadedFiles';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { optionalCategoryIri, optionalResourceIri } from '@shared/utils/iri';
import formatAmount from '@shared/utils/formatAmount';
import PersonalPropertyModal from '@declaration/components/personalProperty/PersonalPropertyModal.vue';
import PersonalPropertyModalV2 from '@declaration/components/personalProperty/v2/PersonalPropertyModal.vue';
const personalProperty = namespace('personalProperty');
const contract = namespace('contract');
const qualification = namespace('qualification');
let PersonalPropertyQuestion = class PersonalPropertyQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.selectedChangeKey = null;
        this.isPersonalPropertyModalOpen = false;
        this.personalPropertyDamage = null;
    }
    get selectedIndex() {
        return this.filteredDamages.length;
    }
    get damagesCount() {
        return this.personalProperties.length;
    }
    get filteredDamages() {
        return this.personalProperties.filter((personalProperty) => {
            return (optionalResourceIri(personalProperty.category) !==
                optionalCategoryIri('denrees-alimentaires', this.categoriesByIri));
        });
    }
    get canValidate() {
        switch (this.typeTrigram) {
            case ClaimTypeTrigram.Glass:
            case ClaimTypeTrigram.Fire:
                return this.areDamagesValid && this.personalPropertiesCount > 0;
            default:
                return this.areDamagesValid;
        }
    }
    get personalPropertiesCount() {
        return this.personalProperties.length;
    }
    get validatedPersonalProperties() {
        return this.personalProperties.filter(({ id, category }) => id &&
            optionalResourceIri(category) !==
                optionalCategoryIri('denrees-alimentaires', this.categoriesByIri));
    }
    get answerTitle() {
        return this.$tc(this.tradKeys.answer, this.validatedPersonalProperties.length);
    }
    get isELE() {
        return this.typeTrigram === ClaimTypeTrigram.Electric;
    }
    selectPersonalPropertyAndOpenModal(personalProperty) {
        this.selectedChangeKey = personalProperty.id;
        this.isPersonalPropertyModalOpen = true;
    }
    deleteDamageHandler(personalProperty) {
        this.personalPropertyDamage = personalProperty;
        this.confirmDelete(personalProperty.id, this.deletePersonalProperty);
    }
    confirmDelete(personalPropertyIri, deleteCallback) {
        if (personalPropertyIri) {
            this.$buefy.dialog.confirm({
                animation: 'fade',
                message: this.$tc(this.tradKeys.confirmDeletion.message),
                cancelText: this.$tc(this.tradKeys.confirmDeletion.cancel),
                confirmText: this.$tc(this.tradKeys.confirmDeletion.confirm),
                onConfirm: deleteCallback,
            });
        }
        else {
            deleteCallback();
        }
    }
    async deletePersonalProperty() {
        var _a;
        this.isSaving = true;
        if ((_a = this.personalPropertyDamage) === null || _a === void 0 ? void 0 : _a.id) {
            await this.delete(this.personalPropertyDamage.id);
            await this.saveChanges();
        }
        else {
            await this.delete(this.selectedChangeKey);
        }
        this.isSaving = false;
    }
    addNewDamage() {
        this.selectedChangeKey = 0;
        this.isPersonalPropertyModalOpen = true;
    }
    closeModal() {
        this.isPersonalPropertyModalOpen = false;
        this.resetPersonalPropertyChanges();
    }
    getDamageTitle(personalProperty) {
        if (personalProperty.thirdPartyProductInfo) {
            return `${personalProperty.thirdPartyProductInfo.brand} ${personalProperty.thirdPartyProductInfo.name}`;
        }
        if (this.isIdentityDocumentGetter(personalProperty) && personalProperty.documentType) {
            return this.$tc(PERSONAL_PROPERTY_IDENTITY_DOCUMENT_TYPES_TRAD_KEY[personalProperty.documentType]);
        }
        if (personalProperty.designation)
            return personalProperty.designation;
        if (personalProperty.initialCategorySlug) {
            return this.$tc(INITIAL_PERSONAL_PROPERTY_CATEGORIES[personalProperty.initialCategorySlug].labelTradKey);
        }
        return this.$tc('model.personal_property');
    }
    getDamageDocuments(personalProperty) {
        var _a;
        if (this.isIdentityDocumentGetter(personalProperty))
            return null;
        // Justificatif(s) d'achat
        let receiptFiles = fileCollectionFilesCount((_a = personalProperty.receipt) === null || _a === void 0 ? void 0 : _a.fileCollection);
        // Justificatif(s) de réparation (attestation d'irréparabilité/devis de réparation)
        let reparationFiles = fileCollectionFilesCount(personalProperty.repairFileCollection);
        // Justificatif(s) des dommages (photos)
        const pictureFiles = fileCollectionFilesCount(personalProperty.pictureCollection);
        const values = [];
        if (receiptFiles > 0) {
            const { receipt } = personalProperty;
            // Si aucun type de reçu, on ignore les fichiers
            if (!(receipt === null || receipt === void 0 ? void 0 : receipt.type) || receipt.type === PersonalPropertyReceiptType.None) {
                receiptFiles = 0;
            }
            else {
                const typeLabel = this.$tc(PERSONAL_PROPERTY_RECEIPT_TYPES_TRAD_KEY[receipt.type], receiptFiles);
                // Si le reçu correspond a des photos, on précise qu'il s'agit d'un justificatif d'achat
                // pour éviter la confusion avec les photos du dommage
                if (receipt.type === PersonalPropertyReceiptType.Pictures) {
                    values.push(this.$tc('claim.damage.justificative.receipt_with_type', receiptFiles, {
                        type: typeLabel,
                    }));
                }
                else {
                    values.push(typeLabel);
                }
            }
        }
        if (reparationFiles > 0) {
            // Si le bien est réparable, il s'agit d'un devis de réparation
            if (personalProperty.repairableStatus === RepairableStatus.Repairable) {
                values.push(this.$tc('claim.damage.justificative.reparation_invoice', reparationFiles));
            }
            // Si le bien est irréparable, il s'agit d'une attestation d'irréparabilité
            else if (personalProperty.repairableStatus === RepairableStatus.Irreparable) {
                values.push(this.$tc('claim.damage.justificative.irreparability_certificate', reparationFiles));
            }
            // Sinon, on ignore les fichiers
            else {
                reparationFiles = 0;
            }
        }
        if (pictureFiles > 0) {
            values.push(this.$tc('claim.damage.justificative.picture', pictureFiles));
        }
        const label = this.$tc('claim.damage.justificative.title', reparationFiles + receiptFiles + pictureFiles);
        const value = values.length ? values.join(', ') : this.$tc('claim.damage.justificative.none');
        return `${label} : ${value}`;
    }
    getRepairStatus(personalProperty) {
        if (personalProperty.repairableStatus === RepairableStatus.Repairable) {
            return `${this.$tc('claim.damage.repairable.title')} : ${this.$tc('yes')}`;
        }
        if (personalProperty.repairableStatus === RepairableStatus.Irreparable) {
            return `${this.$tc('claim.damage.repairable.title')} : ${this.$tc('no')}`;
        }
        return null;
    }
    getRepairAmount(personalProperty) {
        var _a;
        if (personalProperty.repairableStatus === RepairableStatus.Repairable &&
            ((_a = personalProperty.repairQuoteInfo) === null || _a === void 0 ? void 0 : _a.amount)) {
            return `${this.$tc('claim.damage.repairable.amount')} : ${formatAmount(personalProperty.repairQuoteInfo.amount / 100)}`;
        }
        return null;
    }
    get warnings() {
        if (!this.selectedContract) {
            return [];
        }
        return personalPropertyContractWarnings(this.selectedContract);
    }
    get completedPersonalPropertiesCount() {
        return this.personalProperties.filter((personalProperty) => personalProperty.id !== null).length;
    }
    get tradKeys() {
        return PERSONAL_PROPERTY_QUESTION_TRAD_KEYS[this.typeTrigram];
    }
    askToComplete() {
        this.$buefy.toast.open({
            message: this.$tc(this.tradKeys.error.incomplete),
            type: 'is-danger',
            position: 'is-bottom',
        });
    }
    get validationButtonText() {
        return this.$tc(this.tradKeys.validate, this.completedPersonalPropertiesCount);
    }
    async validateQuestion() {
        this.isSaving = true;
        await this.validatePersonalPropertiesStep();
        this.isSaving = false;
    }
    onMissingProduct() {
        this.$emit('missing-product');
    }
    onOtherProduct() {
        this.$emit('other-product');
    }
};
__decorate([
    contract.State('selectedContract')
], PersonalPropertyQuestion.prototype, "selectedContract", void 0);
__decorate([
    personalProperty.Getter('isIdentityDocument')
], PersonalPropertyQuestion.prototype, "isIdentityDocumentGetter", void 0);
__decorate([
    personalProperty.State('damages')
], PersonalPropertyQuestion.prototype, "personalProperties", void 0);
__decorate([
    personalProperty.State('categoriesByIri')
], PersonalPropertyQuestion.prototype, "categoriesByIri", void 0);
__decorate([
    personalProperty.Action('deleteDamage')
], PersonalPropertyQuestion.prototype, "delete", void 0);
__decorate([
    personalProperty.Action('saveChanges')
], PersonalPropertyQuestion.prototype, "saveChanges", void 0);
__decorate([
    personalProperty.Action('resetChanges')
], PersonalPropertyQuestion.prototype, "resetPersonalPropertyChanges", void 0);
__decorate([
    personalProperty.Getter('areDamagesValid')
], PersonalPropertyQuestion.prototype, "areDamagesValid", void 0);
__decorate([
    personalProperty.Action('validatePersonalPropertiesStep')
], PersonalPropertyQuestion.prototype, "validatePersonalPropertiesStep", void 0);
__decorate([
    qualification.State('typeTrigram')
], PersonalPropertyQuestion.prototype, "typeTrigram", void 0);
PersonalPropertyQuestion = __decorate([
    Component({
        components: {
            QuestionPanel,
            PersonalPropertyList,
            UiButton,
            PersonalPropertyModalV2,
            PersonalPropertyModal,
        },
        props: QuestionProps,
    })
], PersonalPropertyQuestion);
export default PersonalPropertyQuestion;
