var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import ContractModification from '@declaration/components/declaration/pages/contrat/modification.vue';
import { ContractStatus } from '@shared/types/api/contract';
import { CONTRACT_HOUSING_CAPACITIES_TRAD_KEYS, CONTRACT_STATUSES_TRAD_KEYS, HOUSING_TYPES_TRAD_KEYS, } from '@shared/contract/labels';
import { formatDate } from '@shared/utils/date';
import formatAmount from '@shared/utils/formatAmount';
import { Step } from '@shared/claim/steps';
const contract = namespace('contract');
const claim = namespace('claim');
let ContractInformation = class ContractInformation extends Vue {
    constructor() {
        super(...arguments);
        this.statuses = ContractStatus;
        this.isLoading = false;
        this.isEditing = false;
        this.formatAmount = formatAmount;
        this.formatDate = formatDate;
    }
    get statusText() {
        return this.$tc(CONTRACT_STATUSES_TRAD_KEYS[this.contract.status]);
    }
    get fullName() {
        return `${this.contract.contact.firstName} ${this.contract.contact.lastName.toUpperCase()}`;
    }
    get hasBothPhones() {
        return this.contract.contact.mobilePhone && this.contract.contact.landlinePhone;
    }
    get onePhone() {
        return this.contract.contact.mobilePhone || this.contract.contact.landlinePhone;
    }
    get housingType() {
        return this.$tc(HOUSING_TYPES_TRAD_KEYS[this.contract.housingType.slug]);
    }
    get housingCapacity() {
        return this.$tc(CONTRACT_HOUSING_CAPACITIES_TRAD_KEYS[this.contract.housingCapacity.code]);
    }
    get canEdit() {
        return this.contract.status === ContractStatus.InEffect;
    }
    async handleConfirmation() {
        this.isLoading = true;
        try {
            await this.updateAndSaveClaimStep(Step.ContractInformation);
        }
        catch (_a) {
            this.isLoading = false;
        }
    }
    editContract() {
        this.isEditing = true;
    }
    exitEdition() {
        this.isEditing = false;
    }
};
__decorate([
    contract.State('selectedContract')
], ContractInformation.prototype, "contract", void 0);
__decorate([
    claim.Action('updateAndSaveClaimStep')
], ContractInformation.prototype, "updateAndSaveClaimStep", void 0);
ContractInformation = __decorate([
    Component({
        components: {
            UiButton,
            ContractForm: ContractModification,
        },
    })
], ContractInformation);
export default ContractInformation;
