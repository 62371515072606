var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as Sentry from '@sentry/browser';
import { Subject } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import AmountInput from '@declaration/components/UI/AmountInput.vue';
import ClaimsCheck from '@declaration/components/UI/ClaimsCheck.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import PictureCollectionUpload from '@declaration/components/upload/PictureCollectionUpload.vue';
import RepairQuoteUpload from '@declaration/components/upload/RepairQuoteUpload.vue';
import { YES_OR_NO_CHOICES, UNKNOWN_CHOICE } from '@declaration/helpers/choices';
import { parseAge, parseAmount } from '@declaration/helpers/typeFormatters';
import { PERSONAL_PROPERTY_QUESTION_TRAD_KEYS, } from '@declaration/types/personalProperty';
import { translateChoices } from '@shared/choice';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import AgeInput from '@declaration/components/AgeInput.vue';
import NumberInput from '@declaration/components/NumberInput.vue';
import LengthInput from '@declaration/components/LengthInput.vue';
import { INITIAL_PERSONAL_PROPERTY_CATEGORIES, PERSONAL_PROPERTY_IDENTITY_DOCUMENT_TYPES, PERSONAL_PROPERTY_IDENTITY_DOCUMENT_TYPES_TRAD_KEY, PERSONAL_PROPERTY_RECEIPT_TYPES, } from '@shared/constants/personalPropertyConstants';
import { PERSONAL_PROPERTY_RECEIPT_TYPES_ADDITION_TRAD_KEY } from '@declaration/constants/personalPropertyConstants';
import { emptyUploadedFileCollection, fileCollectionFilesCount, removeFilesFromFileCollection, } from '@shared/files/uploadedFiles';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { PersonalPropertyReceiptType, BasePersonalPropertyInitialCategorySlug, PersonalPropertyType, BDGPersonalPropertyInitialCategorySlug, PersonalPropertyInitialCategorySlug, } from '@shared/types/api/personalProperty';
import { ClaimFileType } from '@shared/types/file';
import { idFromIri, optionalResourceIri, uuidFromIri } from '@shared/utils/iri';
const claim = namespace('claim');
const personalProperty = namespace('personalProperty');
const qualification = namespace('qualification');
let PersonalPropertyForm = class PersonalPropertyForm extends Vue {
    constructor() {
        super(...arguments);
        this.updateRepairInfo$ = new Subject();
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
        this.unknownValueAndTradKey = UNKNOWN_CHOICE;
        this.isRepairQuoteMandatory = null;
        this.isUnrepairableCertificateMandatory = null;
        this.ClaimFileType = ClaimFileType;
        this.userKnowPrice = null;
        this.parseAge = parseAge;
        this.parseAmount = parseAmount;
    }
    get isPurchaseCertificationRequired() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyPurchaseCertification);
    }
    get isAgeInputDisplayedInCreationFields() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyPurchaseCertification);
    }
    get isUserKnowPriceAsCheckboxes() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyUserKnowPriceAsCheckboxes);
    }
    get isDescriptionRequired() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyDescriptionRequired);
    }
    get filteredCategories() {
        return this.categories.filter((category) => !category.data.isHidden);
    }
    get isCategoryModifiable() {
        if (this.shouldDescribeOther && this.personalPropertyIsOther)
            return false;
        return this.isCategoryModifiableGetter(this.changeKey);
    }
    get userKnowPriceTitle() {
        return 'claim.damage.personal_property.form.question.user_know_price';
    }
    get noPriceKnownValidation() {
        return this.validation.isValid && this.personalProperty.amount === null;
    }
    get personalPropertyIsElectronic() {
        return (this.personalProperty.initialCategorySlug ===
            BasePersonalPropertyInitialCategorySlug.Multimedia ||
            this.personalProperty.initialCategorySlug ===
                BDGPersonalPropertyInitialCategorySlug.ElectromenagerOuMultimedia);
    }
    get personalPropertyIsClothing() {
        return (this.personalProperty.initialCategorySlug ===
            BasePersonalPropertyInitialCategorySlug.VetementsEtAccessoires);
    }
    get personalPropertyIsOther() {
        return this.personalProperty.initialCategorySlug === PersonalPropertyInitialCategorySlug.Autres;
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get personalProperty() {
        return this.change.data;
    }
    get isProductSearchAvailable() {
        if (this.shouldDescribeOther && this.personalPropertyIsOther) {
            return false;
        }
        return (!this.personalProperty.initialCategorySlug ||
            INITIAL_PERSONAL_PROPERTY_CATEGORIES[this.personalProperty.initialCategorySlug]
                .isProductSearchAvailable);
    }
    get shouldDisplayUserKnowPrice() {
        return (!this.isIdentityDocument &&
            !this.personalProperty.thirdPartyProductId &&
            !this.isAmountOnly &&
            !(this.isGlassBreakagePersonalProperty && this.personalPropertyIsOther));
    }
    get shouldDisplayAgeFieldInPPCreationForm() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyAgeInputHiddenFromCreationFields);
    }
    get isThirdPartyAmount() {
        var _a, _b, _c;
        return ((_c = (_b = (_a = this.personalProperty) === null || _a === void 0 ? void 0 : _a.thirdPartyProductInfo) === null || _b === void 0 ? void 0 : _b.amount) !== null && _c !== void 0 ? _c : 0) > 0;
    }
    get isPriceKnow() {
        var _a, _b;
        return (this.isThirdPartyAmount ||
            (((_b = (_a = this.personalProperty) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : 0) > 0 && !this.personalProperty.thirdPartyProductInfo));
    }
    get isGlassBreakagePersonalProperty() {
        return this.personalProperty.type === PersonalPropertyType.GlassBreakage;
    }
    get shouldDisplayGlassFields() {
        return (this.isGlassBreakagePersonalProperty &&
            !this.personalPropertyIsElectronic &&
            !this.personalPropertyIsOther);
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get filteredDesignations() {
        return this.filteredDesignationsGetter(this.changeKey);
    }
    get isRepairQuoteEnabled() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyRepair);
    }
    get shouldDisplayWarrantyQuestion() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyWarranty);
    }
    get shouldDisplayReceiptQuestion() {
        return (!this.isIdentityDocument &&
            !(this.isGlassBreakagePersonalProperty && this.personalPropertyIsOther));
    }
    get shouldDisplayRepairQuoteSection() {
        return this.isRepairQuoteEnabled;
    }
    get identityDocumentTypeChoices() {
        return translateChoices(PERSONAL_PROPERTY_IDENTITY_DOCUMENT_TYPES, this.$tc.bind(this));
    }
    get tradKeys() {
        return PERSONAL_PROPERTY_QUESTION_TRAD_KEYS[this.typeTrigram];
    }
    get canChoseAgeTimeUnit() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyMonthAge);
    }
    revealValidation() {
        this.$store.dispatch('error/add', this.$tc('error.form.please_validate_message'));
        this.validatePersonalProperty(this.changeKey);
    }
    onValidation() {
        this.$emit('no-price-known');
    }
    created() {
        this.debouncedRepairInfoUpdate = this.updateRepairInfo$
            .pipe(filter(() => this.isRepairQuoteEnabled), debounceTime(500), switchMap(() => this.updateDataFromRepairInfo()))
            .subscribe((isRepairQuoteMandatory) => {
            this.setRepairQuoteMandatory(isRepairQuoteMandatory);
        });
    }
    destroy() {
        this.debouncedRepairInfoUpdate.unsubscribe();
    }
    mounted() {
        if (this.isRepairQuoteEnabled) {
            this.updateRepairInfo$.next();
        }
        if (this.validation.validator) {
            const changeKey = this.changeKey;
            Object.keys(this.validation.validator.fields).forEach((fieldName) => {
                this.untouchField({ changeKey, fieldName });
            });
        }
    }
    update(data) {
        var _a;
        if (((_a = this.currentCategory) === null || _a === void 0 ? void 0 : _a.isIdentityDocument) && data.documentType) {
            data.designation = this.$tc(PERSONAL_PROPERTY_IDENTITY_DOCUMENT_TYPES_TRAD_KEY[data.documentType]);
        }
        if (data.userKnowPrice === false) {
            data.amount = null;
            if (this.typeTrigram !== ClaimTypeTrigram.Glass) {
                data.age = null;
            }
        }
        this.updatePersonalProperty({ changeKey: this.changeKey, data });
        if (this.isRepairQuoteEnabled) {
            // Update isRepairQuoteMandatory data on specific data changes
            if ('age' in data || 'amount' in data || 'category' in data) {
                this.updateRepairInfo$.next();
            }
        }
    }
    get isAmountOnly() {
        return this.isAmountOnlyGetter(this.changeKey);
    }
    get categoryIRI() {
        return optionalResourceIri(this.personalProperty.category);
    }
    get isIdentityDocument() {
        return this.isIdentityDocumentGetter(this.changeKey);
    }
    get uploadLabel() {
        var _a;
        if (this.isAmountOnly) {
            return this.$tc(`claim.damage.personal_property.form.receipt.cash.upload.add`);
        }
        if (!this.canChoseReceiptType) {
            return this.$tc('claim.damage.personal_property.form.receipt.upload.add.invoice');
        }
        if (!((_a = this.personalProperty.receipt) === null || _a === void 0 ? void 0 : _a.type)) {
            return '+';
        }
        return this.$tc(PERSONAL_PROPERTY_RECEIPT_TYPES_ADDITION_TRAD_KEY[this.personalProperty.receipt.type]);
    }
    get canChoseReceiptType() {
        return (!this.isAmountOnly &&
            claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyReceiptTypes));
    }
    get receiptTitle() {
        return this.isAmountOnly
            ? this.$tc('claim.damage.personal_property.form.receipt.cash.title')
            : this.$tc(this.tradKeys.form.receiptTitle);
    }
    get receiptDetails() {
        if (this.isAmountOnly) {
            return this.$tc('claim.damage.personal_property.form.receipt.cash.text');
        }
        return this.$tc(this.tradKeys.form.receiptText);
    }
    get currentCategory() {
        var _a, _b;
        const currentCategoryIri = optionalResourceIri(this.personalProperty.category);
        if (!currentCategoryIri) {
            return null;
        }
        return (_b = (_a = this.filteredCategories) === null || _a === void 0 ? void 0 : _a.find((category) => category.data.id === currentCategoryIri)) === null || _b === void 0 ? void 0 : _b.data;
    }
    onPictureCollectionUpdate(pictureCollection) {
        this.update({ pictureCollection });
    }
    goBackToProductSearch() {
        this.update({ thirdPartyProductId: null, thirdPartyProductInfo: null, designation: null });
    }
    get receiptTypeChoices() {
        return translateChoices(PERSONAL_PROPERTY_RECEIPT_TYPES.filter(({ value }) => value !== PersonalPropertyReceiptType.CashWithdrawal), this.$tc.bind(this));
    }
    get receiptTypeValue() {
        var _a;
        if (this.personalProperty.receipt) {
            return (_a = this.personalProperty.receipt.type) !== null && _a !== void 0 ? _a : PersonalPropertyReceiptType.None;
        }
        return null;
    }
    get currentCategoryIsJewels() {
        var _a;
        return (((_a = this.currentCategory) === null || _a === void 0 ? void 0 : _a.slug) === BasePersonalPropertyInitialCategorySlug.BijouxObjetsDeValeur);
    }
    get placeholderBasedOnCategory() {
        var _a;
        return ((_a = this.currentCategory) === null || _a === void 0 ? void 0 : _a.slug) === BasePersonalPropertyInitialCategorySlug.Multimedia
            ? this.$tc('claim.damage.personal_property.form.question.description.placeholder')
            : '';
    }
    onReceiptType(type) {
        var _a, _b, _c;
        const receipt = this.personalProperty.receipt;
        if (type === PersonalPropertyReceiptType.None ||
            type === PersonalPropertyReceiptType.Pictures) {
            this.update({
                receipt: {
                    '@type': ApiEntityType.PersonalPropertyReceipt,
                    id: (_a = receipt === null || receipt === void 0 ? void 0 : receipt.id) !== null && _a !== void 0 ? _a : null,
                    fileCollection: removeFilesFromFileCollection(receipt === null || receipt === void 0 ? void 0 : receipt.fileCollection),
                    type,
                },
            });
            return;
        }
        this.update({
            receipt: {
                '@type': ApiEntityType.PersonalPropertyReceipt,
                id: (_b = receipt === null || receipt === void 0 ? void 0 : receipt.id) !== null && _b !== void 0 ? _b : null,
                fileCollection: (_c = receipt === null || receipt === void 0 ? void 0 : receipt.fileCollection) !== null && _c !== void 0 ? _c : emptyUploadedFileCollection(),
                type,
            },
        });
    }
    onReceiptFileCollection(fileCollection) {
        var _a, _b;
        const receipt = this.personalProperty.receipt;
        const fileCount = fileCollectionFilesCount(fileCollection);
        const id = (_a = receipt === null || receipt === void 0 ? void 0 : receipt.id) !== null && _a !== void 0 ? _a : null;
        if (this.isAmountOnly) {
            // Receipt is a cash withdrawal document
            this.update({
                receipt: {
                    '@type': ApiEntityType.PersonalPropertyReceipt,
                    id,
                    type: fileCount
                        ? PersonalPropertyReceiptType.CashWithdrawal
                        : PersonalPropertyReceiptType.None,
                    fileCollection,
                },
            });
            return;
        }
        if (!this.canChoseReceiptType) {
            // Receipt is invoice
            this.update({
                receipt: {
                    '@type': ApiEntityType.PersonalPropertyReceipt,
                    id,
                    type: fileCount
                        ? PersonalPropertyReceiptType.TypeInvoice
                        : PersonalPropertyReceiptType.None,
                    fileCollection,
                },
            });
            return;
        }
        // Receipt can be of any type
        const type = (_b = receipt === null || receipt === void 0 ? void 0 : receipt.type) !== null && _b !== void 0 ? _b : null;
        this.update({
            receipt: {
                '@type': ApiEntityType.PersonalPropertyReceipt,
                id,
                type,
                fileCollection,
            },
        });
    }
    onRepairQuoteUpdate({ repairableStatus, repairFileCollection, repairQuoteInfo, }) {
        this.update({
            repairableStatus,
            repairFileCollection,
            repairQuoteInfo,
        });
    }
    setAmountOnlyValue(amount) {
        var _a, _b, _c;
        this.update({
            amount,
            description: (_b = (_a = this.currentCategory) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : null,
            designation: (_c = this.$tc('claim.damage.personal_property.sumup.amount_only.designation')) !== null && _c !== void 0 ? _c : null,
            age: 0,
        });
    }
    setRepairQuoteMandatory(isRepairQuoteMandatory) {
        this.isRepairQuoteMandatory = isRepairQuoteMandatory;
    }
    async updateDataFromRepairInfo() {
        if (!this.personalProperty.category || this.personalProperty.age === null || !this.claim.id) {
            return false;
        }
        try {
            const response = await this.$axios.$get(`/products/repair/${uuidFromIri(this.claim.id)}`, {
                params: {
                    age: this.personalProperty.age,
                    amount: this.personalProperty.amount,
                    itemId: this.personalProperty.thirdPartyProductId,
                    personalPropertyCategoryId: idFromIri(typeof this.personalProperty.category === 'string'
                        ? this.personalProperty.category
                        : this.personalProperty.category.id),
                },
            });
            return response.isRepairQuoteMandatory;
        }
        catch (err) {
            Sentry.captureException(err);
            return false;
        }
    }
    onDesignationChange(designation) {
        this.update({ designation: designation || null });
    }
    get canUploadReceipt() {
        return (this.isAmountOnly ||
            !this.canChoseReceiptType ||
            (this.personalProperty.receipt &&
                this.personalProperty.receipt.type !== PersonalPropertyReceiptType.None &&
                this.personalProperty.receipt.type !== PersonalPropertyReceiptType.Pictures));
    }
    get canUploadPictures() {
        var _a;
        if (this.isAmountOnly) {
            return false;
        }
        if (this.shouldDescribeOther && this.personalPropertyIsOther) {
            return false;
        }
        if (!claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyPicturesOnlyForReceipt)) {
            return true;
        }
        return ((_a = this.personalProperty.receipt) === null || _a === void 0 ? void 0 : _a.type) === PersonalPropertyReceiptType.Pictures;
    }
    get repairUnrepairFieldsTitle() {
        return this.$tc(this.tradKeys.form.repairableTitle);
    }
    get repairUnrepairFieldsText() {
        return this.$tc(this.tradKeys.form.repairableText);
    }
    get amountInputTitle() {
        const category = this.currentCategory;
        if (category &&
            category.slug === BasePersonalPropertyInitialCategorySlug.BijouxObjetsDeValeur) {
            return 'claim.damage.personal_property.form.question.amount.jewels';
        }
        if (!this.personalProperty.userKnowPrice && !this.personalProperty.thirdPartyProductId) {
            return 'claim.damage.personal_property.form.question.amount_estimation';
        }
        return 'claim.damage.personal_property.form.question.amount';
    }
    get shouldDescribeOther() {
        return claimTypeHasFeature(this.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyDescribeOther);
    }
    hasSubcategories(category) {
        return category.subcategories.length !== 0;
    }
};
__decorate([
    claim.State('claim')
], PersonalPropertyForm.prototype, "claim", void 0);
__decorate([
    personalProperty.State('categories')
], PersonalPropertyForm.prototype, "categories", void 0);
__decorate([
    personalProperty.State('categoriesByIri')
], PersonalPropertyForm.prototype, "categoriesByIri", void 0);
__decorate([
    personalProperty.Getter('change')
], PersonalPropertyForm.prototype, "changeGetter", void 0);
__decorate([
    personalProperty.Getter('isAmountOnly')
], PersonalPropertyForm.prototype, "isAmountOnlyGetter", void 0);
__decorate([
    personalProperty.Getter('isIdentityDocument')
], PersonalPropertyForm.prototype, "isIdentityDocumentGetter", void 0);
__decorate([
    personalProperty.Getter('isCategoryModifiable')
], PersonalPropertyForm.prototype, "isCategoryModifiableGetter", void 0);
__decorate([
    personalProperty.Getter('validation')
], PersonalPropertyForm.prototype, "validationGetter", void 0);
__decorate([
    personalProperty.Getter('filteredDesignations')
], PersonalPropertyForm.prototype, "filteredDesignationsGetter", void 0);
__decorate([
    personalProperty.Action('validateAndRevealDamages')
], PersonalPropertyForm.prototype, "validatePersonalProperty", void 0);
__decorate([
    personalProperty.Action('updateDamage')
], PersonalPropertyForm.prototype, "updatePersonalProperty", void 0);
__decorate([
    personalProperty.Mutation('UNTOUCH_FIELD')
], PersonalPropertyForm.prototype, "untouchField", void 0);
__decorate([
    qualification.State('typeTrigram')
], PersonalPropertyForm.prototype, "typeTrigram", void 0);
PersonalPropertyForm = __decorate([
    Component({
        components: {
            AmountInput,
            AgeInput,
            ClaimsCheck,
            NumberInput,
            LengthInput,
            PictureCollectionUpload,
            FileCollectionUpload,
            RadioPrimaryButton,
            RepairQuoteUpload,
            UiButton,
        },
        props: {
            changeKey: { type: [String, Number], default: null },
            isSaving: { type: Boolean, default: null },
        },
    })
], PersonalPropertyForm);
export default PersonalPropertyForm;
