var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import DropdownCard from '@declaration/components/DropdownCard.vue';
import { startEndFromAvailableTimeSlot } from '@shared/utils/date';
import UserContactForm from '@declaration/components/appointment/UserContactForm.vue';
const artisanAppointment = namespace('artisanAppointment');
const contract = namespace('contract');
let ArtisanAppointmentDateSelection = class ArtisanAppointmentDateSelection extends Vue {
    constructor() {
        super(...arguments);
        this.isFetchingAppointments = true;
        this.isSavingAppointment = false;
        this.numberOfDayDisplayed = 4;
    }
    async created() {
        await this.fetchArtisanAppointments();
        this.isFetchingAppointments = false;
        this.initializeContact();
    }
    get canValidate() {
        return this.selectedTimeSlot !== null && this.validation.isValid;
    }
    revealValidation() {
        this.validateContact();
        this.$store.dispatch('error/add', 'Veuillez remplir tous les champs obligatoires');
    }
    initializeContact() {
        var _a;
        if (!this.selectedContract) {
            return;
        }
        if (!this.userContact.email || !this.userContact.email.length) {
            this.updateContact({ email: this.selectedContract.contact.email });
        }
        if (!this.userContact.phoneNumber || !this.userContact.phoneNumber.length) {
            this.updateContact({
                phoneNumber: (_a = this.selectedContract.contact.mobilePhone) !== null && _a !== void 0 ? _a : this.selectedContract.contact.landlinePhone,
            });
        }
    }
    selectTimeSlot(timeSlotSlug) {
        this.setSelectedTimeSlotSlug(timeSlotSlug);
    }
    validateSelectedTimeSlot() {
        const appointmentInfo = {
            day: this.selectedTimeSlotDay.label.toLowerCase(),
            startHour: this.selectedTimeSlot.startHour,
            endHour: this.selectedTimeSlot.endHour,
            artisanName: this.artisanName,
            artisanExtranetLink: this.artisanExtranetLink,
            date: this.selectedTimeSlotDay.date,
        };
        this.$buefy.dialog.confirm({
            animation: 'fade',
            title: this.$tc('appointment.artisan.date_selection.confirm_popup.title'),
            message: this.$tc('appointment.artisan.date_selection.confirm_popup.text', 0, appointmentInfo),
            cancelText: this.$tc('appointment.artisan.date_selection.confirm_popup.cancel'),
            confirmText: this.$tc('appointment.artisan.date_selection.confirm_popup.confirm'),
            onConfirm: async () => {
                this.isSavingAppointment = true;
                await this.onConfirmCallback({
                    ...startEndFromAvailableTimeSlot(this.selectedTimeSlotDay.date, this.selectedTimeSlot),
                    artisanName: this.artisanName,
                    artisanExtranetLink: this.artisanExtranetLink,
                    userContact: this.userContact,
                });
                this.isSavingAppointment = false;
            },
            onCancel: () => this.setSelectedTimeSlotSlug(null),
        });
    }
    showMoreDay() {
        this.numberOfDayDisplayed += 4;
    }
};
__decorate([
    artisanAppointment.Getter('displayedArtisanAvailableTimeSlotsScheduleByDays')
], ArtisanAppointmentDateSelection.prototype, "displayedAvailableTimeSlotsScheduleByDays", void 0);
__decorate([
    artisanAppointment.Getter('selectedTimeSlotDay')
], ArtisanAppointmentDateSelection.prototype, "selectedTimeSlotDay", void 0);
__decorate([
    artisanAppointment.Getter('selectedTimeSlot')
], ArtisanAppointmentDateSelection.prototype, "selectedTimeSlot", void 0);
__decorate([
    artisanAppointment.State('artisanName')
], ArtisanAppointmentDateSelection.prototype, "artisanName", void 0);
__decorate([
    artisanAppointment.State('artisanExtranetLink')
], ArtisanAppointmentDateSelection.prototype, "artisanExtranetLink", void 0);
__decorate([
    artisanAppointment.State('selectedTimeSlotSlug')
], ArtisanAppointmentDateSelection.prototype, "selectedTimeSlotSlug", void 0);
__decorate([
    artisanAppointment.State('userContact')
], ArtisanAppointmentDateSelection.prototype, "userContact", void 0);
__decorate([
    contract.State('selectedContract')
], ArtisanAppointmentDateSelection.prototype, "selectedContract", void 0);
__decorate([
    artisanAppointment.Action('updateContact')
], ArtisanAppointmentDateSelection.prototype, "updateContact", void 0);
__decorate([
    artisanAppointment.Action('fetchArtisanAppointments')
], ArtisanAppointmentDateSelection.prototype, "fetchArtisanAppointments", void 0);
__decorate([
    artisanAppointment.Action('setSelectedTimeSlotSlug')
], ArtisanAppointmentDateSelection.prototype, "setSelectedTimeSlotSlug", void 0);
__decorate([
    artisanAppointment.Action('validateContact')
], ArtisanAppointmentDateSelection.prototype, "validateContact", void 0);
__decorate([
    artisanAppointment.Getter('validation')
], ArtisanAppointmentDateSelection.prototype, "validation", void 0);
ArtisanAppointmentDateSelection = __decorate([
    Component({
        components: { DropdownCard, UiButton, UserContactForm },
        props: {
            onConfirmCallback: {
                type: Function,
                required: true,
            },
        },
    })
], ArtisanAppointmentDateSelection);
export default ArtisanAppointmentDateSelection;
