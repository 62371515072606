var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import DateAndTimePicker from '@declaration/components/UI/DateAndTimePicker.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
import { ClaimFileType } from '@shared/types/file';
const claim = namespace('claim');
const qualification = namespace('qualification');
let PublicWorksInfoQuestion = class PublicWorksInfoQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.companyName = null;
        this.interventionDate = null;
        this.maxDate = new Date();
        this.publicWorksDocument = null;
        this.ClaimFileType = ClaimFileType;
    }
    created() {
        this.maxDate = this.claim.damageDate ? new Date(this.claim.damageDate) : new Date();
        this.companyName = this.electricalDamageQualification.publicWorksCompanyName;
        this.interventionDate = this.electricalDamageQualification.publicWorksInterventionDate
            ? new Date(this.electricalDamageQualification.publicWorksInterventionDate)
            : this.maxDate;
        this.publicWorksDocument = this.electricalDamageQualification.publicWorksDocument;
    }
    get areInfoCompleted() {
        return this.companyName && this.interventionDate !== null;
    }
    onFileCollectionUpdate(fileCollection) {
        this.publicWorksDocument = fileCollection;
    }
    updateClaimWithInfo() {
        this.updateAndSaveQualification({
            qualificationPayload: {
                publicWorksCompanyName: this.companyName,
                publicWorksInterventionDate: this.interventionDate
                    ? this.interventionDate.toISOString()
                    : null,
                publicWorksDocument: this.publicWorksDocument,
            },
            step: this.step,
        });
    }
    async updateClaimWithNoInfo() {
        await this.updateAndSaveQualification({
            qualificationPayload: {
                publicWorksCompanyName: null,
                publicWorksInterventionDate: null,
                publicWorksDocument: emptyUploadedFileCollection(),
            },
            step: this.step,
        });
    }
    get answer() {
        if (this.electricalDamageQualification.hasProfessionalIntervened === null) {
            return null;
        }
        return this.electricalDamageQualification.hasProfessionalIntervened
            ? this.$tc('claim.qualification.electrical.has_professional_intervened.sumup.yes')
            : this.$tc('claim.qualification.electrical.has_professional_intervened.sumup.no');
    }
};
__decorate([
    claim.State('claim')
], PublicWorksInfoQuestion.prototype, "claim", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], PublicWorksInfoQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], PublicWorksInfoQuestion.prototype, "electricalDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], PublicWorksInfoQuestion.prototype, "updateAndSaveQualification", void 0);
PublicWorksInfoQuestion = __decorate([
    Component({
        components: { FileCollectionUpload, QuestionPanel, UiButton, DateAndTimePicker },
        props: QuestionProps,
    })
], PublicWorksInfoQuestion);
export default PublicWorksInfoQuestion;
