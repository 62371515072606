var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import BreakInDamageDescriptionSectionCard from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionSectionCard.vue';
import BreakInDamageDescriptionStep from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionStep.vue';
import { BreakInDamageRepairPerson, BreakInDamageRepairStatus, RobberyBreakableElement, } from '@shared/types/api/claim';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { REPAIRED_BY_CHOICES } from '@shared/constants/breakInDamageConstants';
import BreakInDamageRepairQuoteUpload from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageRepairQuoteUpload.vue';
import { ClaimFileType } from '@shared/types/file';
import { REPAIR_STATUS_CHOICES_DOOR } from '@shared/constants/breakInDamages/DoorConstants';
import { REPAIR_STATUS_CHOICES_SLIDING_BAY } from '@shared/constants/breakInDamages/SlidingBayConstants';
import { REPAIR_STATUS_CHOICES_WINDOW } from '@shared/constants/breakInDamages/WindowConstants';
import { REPAIR_STATUS_CHOICES_FRENCH_WINDOW } from '@shared/constants/breakInDamages/FrenchWindowConstants';
import { REPAIR_STATUS_CHOICES_SHUTTER } from '@shared/constants/breakInDamages/ShutterConstants';
import { REPAIR_STATUS_CHOICES_GARAGE_DOOR } from '@shared/constants/breakInDamages/GarageDoorConstants';
import { REPAIR_STATUS_CHOICES_GATE } from '@shared/constants/breakInDamages/GateConstants';
import { REPAIR_STATUS_CHOICES_OTHER } from '@shared/constants/breakInDamages/OtherConstants';
import { REPAIR_STATUS_CHOICES_LITTLE_GATE } from '@shared/constants/breakInDamages/LittleGateConstants';
import { REPAIR_STATUS_CHOICES_FENCE } from '@shared/constants/breakInDamages/FenceConstants';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const breakInDamage = namespace('breakInDamage');
let Repairs = class Repairs extends Vue {
    constructor() {
        super(...arguments);
        this.showValidation = false;
        this.ClaimFileType = ClaimFileType;
        this.REPAIRED_BY_CHOICES = REPAIRED_BY_CHOICES;
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get breakInDamage() {
        return this.change.data;
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get isValid() {
        return (!this.validation.fieldHasViolations('hasBeenRepaired') &&
            !this.validation.fieldHasViolations('repairedBy') &&
            !this.validation.fieldHasViolations('repairFileCollection') &&
            !this.validation.fieldHasViolations('repairQuoteInfo.amount') &&
            !this.validation.fieldHasViolations('pendingRepairQuoteInfo.amount'));
    }
    update(data) {
        this.updateBreakInDamage({ changeKey: this.changeKey, data });
    }
    save() {
        this.$emit('goToNextStep');
    }
    revealValidation() {
        this.$store.dispatch('error/add', this.$tc('error.form.please_validate_message'));
        this.validateBreakInDamage(this.changeKey);
        this.showValidation = true;
    }
    get displayRepairedBySection() {
        return (this.breakInDamage.hasBeenRepaired !== null &&
            this.breakInDamage.hasBeenRepaired !== BreakInDamageRepairStatus.NotRepaired);
    }
    get displayRepairInvoiceSection() {
        return (this.breakInDamage.hasBeenRepaired !== null &&
            this.breakInDamage.hasBeenRepaired !== BreakInDamageRepairStatus.NotRepaired &&
            this.breakInDamage.repairedBy !== null);
    }
    get displayRepairQuoteSection() {
        return (this.breakInDamage.hasBeenRepaired !== null &&
            ((this.breakInDamage.hasBeenRepaired === BreakInDamageRepairStatus.RepairedButNeedsMoreWork &&
                this.breakInDamage.repairedBy !== null) ||
                this.breakInDamage.hasBeenRepaired === BreakInDamageRepairStatus.NotRepaired));
    }
    get repairInvoiceSectionTitle() {
        if (this.breakInDamage.repairedBy !== null &&
            this.breakInDamage.repairedBy === BreakInDamageRepairPerson.Artisan) {
            return this.$tc('claim.declaration.break_in_damages.form.repairs.invoice.title.mandatory');
        }
        return this.$tc('claim.declaration.break_in_damages.form.repairs.invoice.title.optional');
    }
    get repairInvoiceSectionSubtitle() {
        if (this.breakInDamage.repairedBy !== null &&
            this.breakInDamage.repairedBy === BreakInDamageRepairPerson.Artisan) {
            return this.$tc('claim.declaration.break_in_damages.form.repairs.invoice.subtitle.mandatory');
        }
        return this.$tc('claim.declaration.break_in_damages.form.repairs.invoice.subtitle.optional');
    }
    onInitialRepairQuoteUpdate({ repairableStatus, repairFileCollection, repairQuoteInfo, }) {
        this.update({
            repairableStatus,
            repairFileCollection,
            repairQuoteInfo,
        });
    }
    onLateRepairQuoteUpdate({ repairFileCollection, repairQuoteInfo }) {
        this.update({
            pendingRepairFileCollection: repairFileCollection,
            pendingRepairQuoteInfo: repairQuoteInfo,
        });
    }
    get repairStatusChoices() {
        let choices = [];
        switch (this.breakInDamage.type) {
            case RobberyBreakableElement.Door:
                choices = REPAIR_STATUS_CHOICES_DOOR;
                break;
            case RobberyBreakableElement.Window:
                choices = REPAIR_STATUS_CHOICES_WINDOW;
                break;
            case RobberyBreakableElement.Fence:
                choices = REPAIR_STATUS_CHOICES_FENCE;
                break;
            case RobberyBreakableElement.FrenchWindow:
                choices = REPAIR_STATUS_CHOICES_FRENCH_WINDOW;
                break;
            case RobberyBreakableElement.SlidingBayWindow:
                choices = REPAIR_STATUS_CHOICES_SLIDING_BAY;
                break;
            case RobberyBreakableElement.Shutter:
                choices = REPAIR_STATUS_CHOICES_SHUTTER;
                break;
            case RobberyBreakableElement.GarageDoor:
                choices = REPAIR_STATUS_CHOICES_GARAGE_DOOR;
                break;
            case RobberyBreakableElement.Gate:
                choices = REPAIR_STATUS_CHOICES_GATE;
                break;
            case RobberyBreakableElement.Other:
                choices = REPAIR_STATUS_CHOICES_OTHER;
                break;
            case RobberyBreakableElement.LittleGate:
                choices = REPAIR_STATUS_CHOICES_LITTLE_GATE;
                break;
        }
        return choices.filter((choice) => {
            if (choice.value === BreakInDamageRepairStatus.RepairedButNeedsMoreWork) {
                return !insurerHasFeature(InsurerFeatureFlag.HideBreakInDamagesRepairedButNeedsMoreWork);
            }
            return true;
        });
    }
};
__decorate([
    Prop({ type: [String, Number], default: null })
], Repairs.prototype, "changeKey", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Repairs.prototype, "isFinalStep", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Repairs.prototype, "isSaving", void 0);
__decorate([
    breakInDamage.Getter('change')
], Repairs.prototype, "changeGetter", void 0);
__decorate([
    breakInDamage.Action('updateDamage')
], Repairs.prototype, "updateBreakInDamage", void 0);
__decorate([
    breakInDamage.Getter('validation')
], Repairs.prototype, "validationGetter", void 0);
__decorate([
    breakInDamage.Action('validateAndRevealDamages')
], Repairs.prototype, "validateBreakInDamage", void 0);
Repairs = __decorate([
    Component({
        components: {
            BreakInDamageDescriptionStep,
            BreakInDamageDescriptionSectionCard,
            RadioPrimaryButton,
            BreakInDamageRepairQuoteUpload,
        },
    })
], Repairs);
export default Repairs;
