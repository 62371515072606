var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import ParagraphsWithIcon from '@declaration/components/ParagraphsWithIcon.vue';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import RepairQuoteUpload from '@declaration/components/upload/RepairQuoteUpload.vue';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@shared/layout/layout';
import { Step } from '@shared/claim/steps';
import { PayoutStatus } from '@declaration/types/payout';
import { breakInDamageTitle } from '@shared/breakInDamage/breakInDamageFields';
import { BreakInDamageCompensationMode } from '@shared/types/api/breakInDamage';
import { BreakInDamageRepairQuotePendingStatus } from '@shared/types/api/claim';
import formatAmount from '@shared/utils/formatAmount';
import { ClaimFileType } from '@shared/types/file';
const breakInDamage = namespace('breakInDamage');
const claim = namespace('claim');
let MissingDocumentCard = class MissingDocumentCard extends Vue {
    constructor() {
        super(...arguments);
        this.saveButtonLoading = false;
        this.ClaimFileType = ClaimFileType;
        this.illustration = require('@declaration/assets/images/documents/document.svg');
    }
    get isValid() {
        for (let i = 0; i < this.breakInDamages.length; i++) {
            const breakInDamage = this.breakInDamages[i];
            if (!this.validationGetter(breakInDamage.id).isValid) {
                return false;
            }
        }
        return true;
    }
    get contents() {
        const paragraphs = this.claim.immediatePayout && this.claim.immediatePayout.status === PayoutStatus.Succeeded
            ? [
                {
                    icon: 'check',
                    html: this.$tc('payout.missing_document.description.check', 0, {
                        amount: formatAmount(this.claim.immediatePayout.amount / 100),
                    }),
                },
            ]
            : [];
        paragraphs.push({
            icon: 'calendar',
            html: `${this.$tc('payout.missing_document.description.calendar')}`,
        });
        return paragraphs;
    }
    displayName(breakInDamage) {
        return breakInDamageTitle(breakInDamage, this.$tc.bind(this));
    }
    receiptTitle(breakInDamage, initialFile) {
        if (!initialFile) {
            return this.$tc('missing_document.card.item.title.late');
        }
        else if (breakInDamage.compensationMode === BreakInDamageCompensationMode.CompensationModeTemporary) {
            return this.$tc('missing_document.card.item.title.initial.temporary');
        }
        else {
            return this.$tc('missing_document.card.item.title.initial.definitive');
        }
    }
    get missingDocumentBrokenDamages() {
        return this.breakInDamages
            .map((breakInDamage) => {
            switch (breakInDamage.repairQuotePendingStatus) {
                case BreakInDamageRepairQuotePendingStatus.Initial:
                    return {
                        breakInDamage,
                        missingDocuments: [
                            {
                                name: this.receiptTitle(breakInDamage, true),
                                initialFile: true,
                            },
                        ],
                    };
                case BreakInDamageRepairQuotePendingStatus.Late:
                    return {
                        breakInDamage,
                        missingDocuments: [
                            {
                                name: this.receiptTitle(breakInDamage, false),
                                initialFile: false,
                            },
                        ],
                    };
                case BreakInDamageRepairQuotePendingStatus.Both:
                    return {
                        breakInDamage,
                        missingDocuments: [
                            {
                                name: this.receiptTitle(breakInDamage, true),
                                initialFile: true,
                            },
                            {
                                name: this.receiptTitle(breakInDamage, false),
                                initialFile: false,
                            },
                        ],
                    };
                default:
                    return { breakInDamage, missingDocuments: [] };
            }
        })
            .filter((x) => x.missingDocuments.length > 0);
    }
    noDocument(breakInDamage, initialFile) {
        this.$buefy.dialog.confirm({
            animation: 'fade',
            title: this.$tc('compensation.document_missing.break_in_damages.no-document'),
            message: this.$tc('compensation.document_missing.break_in_damages.no-document.description'),
            onCancel: () => {
                // No-op
            },
            onConfirm: async () => {
                const data = initialFile
                    ? {
                        ignoreRepairFile: true,
                    }
                    : {
                        ignorePendingRepairFile: true,
                    };
                await this.updateBreakInDamage({
                    changeKey: breakInDamage.id,
                    data: { ...breakInDamage, ...data },
                });
            },
            confirmText: this.$tc('appointment.artisan.date_selection.confirm_popup.confirm'),
            cancelText: this.$tc('appointment.artisan.date_selection.confirm_popup.cancel'),
        });
    }
    breakInDamageHasPendingReceipt(breakInDamage, missingDocuments) {
        for (let i = 0; i < missingDocuments.length; i++) {
            const missingDocument = missingDocuments[i];
            if (missingDocument.initialFile) {
                if (!breakInDamage.ignoreRepairFile &&
                    breakInDamage.repairFileCollection.uploadedAppFiles.length === 0) {
                    return true;
                }
            }
            else if (!breakInDamage.ignorePendingRepairFile &&
                (!breakInDamage.pendingRepairFileCollection ||
                    breakInDamage.pendingRepairFileCollection.uploadedAppFiles.length === 0)) {
                return true;
            }
        }
        return false;
    }
    get saveButtonTitle() {
        let title = this.$tc('missing_document.card.save.ignore');
        for (let i = 0; i < this.missingDocumentBrokenDamages.length; i++) {
            const missingDocumentBreakInDamage = this.missingDocumentBrokenDamages[i];
            const breakInDamage = missingDocumentBreakInDamage.breakInDamage;
            for (let j = 0; j < missingDocumentBreakInDamage.missingDocuments.length; j++) {
                const missingDocument = missingDocumentBreakInDamage.missingDocuments[j];
                if ((missingDocument.initialFile && !breakInDamage.ignoreRepairFile) ||
                    (!missingDocument.initialFile && !breakInDamage.ignorePendingRepairFile)) {
                    title = this.$tc('missing_document.card.save');
                }
            }
        }
        return title;
    }
    onInitialRepairQuoteUpdate(changeKey, { repairableStatus, repairFileCollection, repairQuoteInfo }) {
        this.updateBreakInDamage({
            changeKey,
            data: {
                repairableStatus,
                repairFileCollection,
                repairQuoteInfo,
            },
        });
    }
    onLateRepairQuoteUpdate(changeKey, { repairFileCollection, repairQuoteInfo }) {
        this.updateBreakInDamage({
            changeKey,
            data: {
                pendingRepairFileCollection: repairFileCollection,
                pendingRepairQuoteInfo: repairQuoteInfo,
            },
        });
    }
    async save() {
        this.saveButtonLoading = true;
        await this.saveChanges({ step: Step.DeclarationDocumentPending });
        this.saveButtonLoading = false;
    }
    get canValidate() {
        return (this.missingDocumentBrokenDamages.filter(({ breakInDamage, missingDocuments }) => {
            return this.breakInDamageHasPendingReceipt(breakInDamage, missingDocuments);
        }).length === 0);
    }
};
__decorate([
    claim.State('claim')
], MissingDocumentCard.prototype, "claim", void 0);
__decorate([
    breakInDamage.State('damages')
], MissingDocumentCard.prototype, "breakInDamages", void 0);
__decorate([
    breakInDamage.Getter('change')
], MissingDocumentCard.prototype, "changeGetter", void 0);
__decorate([
    breakInDamage.Getter('validation')
], MissingDocumentCard.prototype, "validationGetter", void 0);
__decorate([
    breakInDamage.Action('saveChanges')
], MissingDocumentCard.prototype, "saveChanges", void 0);
__decorate([
    breakInDamage.Action('validateAndRevealDamages')
], MissingDocumentCard.prototype, "validateBreakInDamage", void 0);
__decorate([
    breakInDamage.Action('updateDamage')
], MissingDocumentCard.prototype, "updateBreakInDamage", void 0);
__decorate([
    breakInDamage.Action('deleteDamage')
], MissingDocumentCard.prototype, "deleteBreakInDamage", void 0);
MissingDocumentCard = __decorate([
    Component({
        components: {
            UiButton,
            RepairQuoteUpload,
            TitleImageDescriptionLayout,
            ParagraphsWithIcon,
        },
        meta: {
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
        },
    })
], MissingDocumentCard);
export default MissingDocumentCard;
