var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { UiButton, UiIcon } from '@claims-ia/design-system';
import { GoodDamageComponent } from './GoodDamageComponent';
import LinkButton from '@declaration/components/UI/LinkButton.vue';
import { DESCRIBIBLE_GOOD_DAMAGE_TYPES, GOOD_DAMAGE_TYPE_ILLUSTRATIONS, } from '@shared/roomDamage/goodDamage';
let GoodDamageDetailsForm = class GoodDamageDetailsForm extends GoodDamageComponent {
    get illustration() {
        var _a;
        if (!this.type)
            return null;
        return (_a = GOOD_DAMAGE_TYPE_ILLUSTRATIONS[this.type]) !== null && _a !== void 0 ? _a : null;
    }
    get canDescribe() {
        if (!this.type)
            return null;
        return DESCRIBIBLE_GOOD_DAMAGE_TYPES.includes(this.type);
    }
    get isValid() {
        var _a;
        return (_a = this.isValidGoodDamage(this.index)) !== null && _a !== void 0 ? _a : false;
    }
    get showEditButton() {
        var _a;
        return (_a = this.canDescribe) !== null && _a !== void 0 ? _a : false;
    }
    get buttonText() {
        return this.isValid
            ? this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.edit')
            : this.$tc('claim.damage.real_estate.room_damage.form.question.good_damage.describe');
    }
};
GoodDamageDetailsForm = __decorate([
    Component({
        components: { UiButton, LinkButton, UiIcon },
        props: {
            title: { type: String, required: true },
            details: { type: String, default: null },
        },
    })
], GoodDamageDetailsForm);
export default GoodDamageDetailsForm;
