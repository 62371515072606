var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { Step } from '@shared/claim/steps';
import { DEFAULT_LAYOUT_COLOR } from '@declaration/layout';
import { uuidFromIri } from '@shared/utils/iri';
const claim = namespace('claim');
const qualification = namespace('qualification');
var HeliosMessageType;
(function (HeliosMessageType) {
    HeliosMessageType["Error"] = "error";
    HeliosMessageType["Ready"] = "ready";
    HeliosMessageType["Completed"] = "completed";
})(HeliosMessageType || (HeliosMessageType = {}));
export function simulateHeliosMessage(message) {
    var _a;
    const payload = {
        issuer: 'helios',
        ...message,
    };
    (_a = window.top) === null || _a === void 0 ? void 0 : _a.postMessage(JSON.stringify(payload), '*');
}
let DroughtDeclaration = class DroughtDeclaration extends Vue {
    constructor() {
        super(...arguments);
        this.existsImmediatePayout = true;
        this.listeners = [];
        this.iFrameSrc = null;
        this.isLoading = true;
        this.isReady = false;
        this.error = null;
        // Generate distinct frame name to prevent caching issues
        this.iFrameName = `helios-${new Date().getTime().toString()}`;
    }
    async getHeliosUrl() {
        const claimId = this.claimInfo.id;
        if (!claimId) {
            return null;
        }
        try {
            const response = await this.$axios.$post(`/helios/${uuidFromIri(claimId)}`);
            return response.url;
        }
        catch (err) {
            this.error = this.$tc('error.snackbar.info');
            return null;
        }
    }
    async mounted() {
        const url = await this.getHeliosUrl();
        if (url === null) {
            return;
        }
        // Frame ref is only available after mounted
        const frame = this.$refs.droughtIframe;
        frame.addEventListener('load', () => {
            this.isLoading = false;
        });
        this.iFrameSrc = url;
        // Cross window message event to communicate with iframe
        // Cannot be by standard js listeners because iframe is served from another domain
        // No CORS is possible for iframes so messages are the only way of communicating
        // between owner and frame windows
        window.addEventListener('message', this.onMessage);
        // Simulate cross message with shift+e (error) or shift+d (end)
        // Must be focused on parent window (header) for it to work
        window.addEventListener('keypress', this.fakeMessageOnKeypress);
    }
    unmount() {
        window.removeEventListener('keypress', this.fakeMessageOnKeypress);
        window.removeEventListener('message', this.onMessage);
    }
    onMessage(message) {
        if (typeof message.data !== 'string') {
            return;
        }
        const payload = JSON.parse(message.data);
        // All messages from all libs and producers are listen.
        // For this feature message must be json and  payload should have `issuer: 'helios'` property
        if (payload.issuer !== 'helios') {
            return;
        }
        if (payload.type === HeliosMessageType.Completed) {
            this.updateAndSaveQualification({
                step: Step.DroughtDeclaration,
                qualificationPayload: { isHeliosDeclarationFinished: true },
            });
            return;
        }
        if (payload.type === HeliosMessageType.Error) {
            this.error = payload.message;
            this.isLoading = false;
            this.isReady = true;
            // eslint-disable-next-line no-console
            console.error('Helios error: ', payload);
            return;
        }
        if (payload.type === HeliosMessageType.Ready) {
            if (!this.isReady) {
                // Let iframe content time to repaint / compute CSS style before revealing
                setTimeout(() => {
                    this.isReady = true;
                }, 600);
            }
        }
    }
    fakeMessageOnKeypress(keypress) {
        if (!keypress.shiftKey) {
            return;
        }
        if (keypress.key === 'E') {
            simulateHeliosMessage({
                type: HeliosMessageType.Error,
                code: 'test',
                message: this.$tc('error.snackbar.info'),
            });
        }
    }
};
__decorate([
    claim.State('claim')
], DroughtDeclaration.prototype, "claimInfo", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], DroughtDeclaration.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], DroughtDeclaration.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], DroughtDeclaration.prototype, "updateAndSaveQualification", void 0);
DroughtDeclaration = __decorate([
    Component({
        components: {
            UiButton,
        },
        meta: {
            title: 'claim.declaration.section.qualification.title',
            goBack: null,
            color: DEFAULT_LAYOUT_COLOR,
        },
    })
], DroughtDeclaration);
export default DroughtDeclaration;
