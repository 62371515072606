var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import axios from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import DropdownCard from '@declaration/components/DropdownCard.vue';
import UserContactForm from '@declaration/components/appointment/UserContactForm.vue';
import { longDateDay } from '@declaration/services/appointmentLabels';
import { timeSlotsScheduledFromExpertAppointmentTimeSlots } from '@shared/services/timeSlotsScheduledFromExpertAppointmentTimeSlots';
import { formatMinutesDuration, formatTime } from '@shared/utils/date';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@shared/layout/layout';
const expertAppointment = namespace('expertAppointment');
const contract = namespace('contract');
const claim = namespace('claim');
const layout = namespace('layout');
let ExpertAppointmentDateSelection = class ExpertAppointmentDateSelection extends Vue {
    constructor() {
        super(...arguments);
        this.numberOfDaysDisplayed = 4;
        this.hadBookingConflict = false;
        this.userHasNoSuitabletimeSlot = false;
        this.isFindingAppointments = true;
    }
    created() {
        this.initializeContact();
        this.fetchAppointments();
    }
    get canValidate() {
        return this.selectedTimeSlot !== null && this.validation.isValid;
    }
    get shouldDisplayNoResults() {
        return !this.isFindingAppointments && !this.allCardItems.length;
    }
    get allCardItems() {
        var _a, _b;
        const timeslotsByDays = timeSlotsScheduledFromExpertAppointmentTimeSlots((_b = (_a = this.appointmentFindResult) === null || _a === void 0 ? void 0 : _a.appointments.timeslots) !== null && _b !== void 0 ? _b : []);
        return timeslotsByDays.map((dayTimeslots) => ({
            key: dayTimeslots.label,
            title: longDateDay(dayTimeslots.date, this.$tc.bind(this)),
            checked: this.selectedTimeSlot,
            items: dayTimeslots.availableTimeSlots.map(({ slug, startHour, endHour, duration }) => ({
                slug,
                label: duration
                    ? this.$tc('appointment.expert.date_selection.appointment_label.range', 0, {
                        startHour,
                        endHour,
                        duration: formatMinutesDuration(duration),
                    })
                    : this.$tc('appointment.expert.date_selection.appointment_label', 0, {
                        startHour,
                        endHour,
                    }),
            })),
        }));
    }
    get cardItems() {
        return this.allCardItems.slice(0, this.numberOfDaysDisplayed);
    }
    revealValidation() {
        this.validateContact();
    }
    initializeContact() {
        var _a;
        if (!this.selectedContract) {
            return;
        }
        if (!this.userContact.email || !this.userContact.email.length) {
            this.updateContact({ email: this.selectedContract.contact.email });
        }
        if (!this.userContact.phoneNumber || !this.userContact.phoneNumber.length) {
            this.updateContact({
                phoneNumber: (_a = this.selectedContract.contact.mobilePhone) !== null && _a !== void 0 ? _a : this.selectedContract.contact.landlinePhone,
            });
        }
    }
    validateSelectedTimeSlot() {
        var _a, _b;
        if (!this.selectedAppointment) {
            return;
        }
        const { startTime, endTime } = this.selectedAppointment;
        const messageParameters = {
            day: longDateDay(startTime, this.$tc.bind(this)).toLowerCase(),
            startHour: formatTime(startTime, true),
            endHour: formatTime(endTime, true),
            expertCompanyName: (_b = (_a = this.appointmentFindResult) === null || _a === void 0 ? void 0 : _a.expert.name) !== null && _b !== void 0 ? _b : '',
        };
        this.$buefy.dialog.confirm({
            animation: 'fade',
            title: this.$tc('appointment.expert.date_selection.confirm_popup.title'),
            message: this.$tc('appointment.expert.date_selection.confirm_popup.text', 0, messageParameters),
            cancelText: this.$tc('appointment.expert.date_selection.confirm_popup.cancel'),
            confirmText: this.$tc('appointment.expert.date_selection.confirm_popup.confirm'),
            onConfirm: () => {
                if (!this.selectedAppointment) {
                    return;
                }
                this.bookTimeslot();
            },
            onCancel: () => this.selectTimeSlot(null),
        });
    }
    showMoreDays() {
        this.numberOfDaysDisplayed += 4;
    }
    async noTimeSlot(noSuitableExpertTimeSlotReason) {
        this.userHasNoSuitabletimeSlot = true;
        try {
            await this.updateAndSaveClaim({
                claimPayload: {
                    noSuitableExpertTimeSlot: true,
                    // Only specify reason if needed for user_choice, other reason are handled server side
                    // while fetching appointments (and catching an error, timeout or not found exception
                    ...(noSuitableExpertTimeSlotReason ? { noSuitableExpertTimeSlotReason } : {}),
                },
                step: this.step,
            });
        }
        catch (err) {
            this.$buefy.toast.open({
                message: 'Une erreur est survenue, merci de réessayer.',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
        this.userHasNoSuitabletimeSlot = false;
    }
    async bookTimeslot() {
        var _a, _b;
        try {
            await this.confirmAppointment();
            this.$emit('confirm', this.selectedAppointment);
        }
        catch (err) {
            if (axios.isAxiosError(err)) {
                if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === 409 && !this.hadBookingConflict) {
                    this.$buefy.toast.open({
                        message: 'appointment.expert.no_appointments.booking_conflict',
                        type: 'is-danger',
                        position: 'is-bottom',
                    });
                    await this.fetchAppointments();
                    this.hadBookingConflict = true;
                    return;
                }
                if (((_b = err.response) === null || _b === void 0 ? void 0 : _b.status) === 409 && this.hadBookingConflict) {
                    this.$buefy.toast.open({
                        message: 'appointment.expert.no_appointments.booking_conflict.advisor',
                        type: 'is-danger',
                        position: 'is-bottom',
                    });
                    await this.noTimeSlot(null);
                    return;
                }
            }
            this.$buefy.toast.open({
                message: 'appointment.expert.no_appointments.internal_server_error',
                type: 'is-danger',
                position: 'is-bottom',
            });
        }
    }
    async fetchAppointments() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this.isFindingAppointments = true;
        await this.fetchExpertAppointments();
        if (this.appointmentFindError !== null) {
            await this.noTimeSlot(null);
        }
        if (((_c = (_b = (_a = this.appointmentFindResult) === null || _a === void 0 ? void 0 : _a.appointments) === null || _b === void 0 ? void 0 : _b.timeslots) !== null && _c !== void 0 ? _c : []).length === 0) {
            if ((_e = (_d = this.appointmentFindResult) === null || _d === void 0 ? void 0 : _d.appointments) === null || _e === void 0 ? void 0 : _e.retry) {
                await this.fetchExpertAppointments();
            }
            if (((_h = (_g = (_f = this.appointmentFindResult) === null || _f === void 0 ? void 0 : _f.appointments) === null || _g === void 0 ? void 0 : _g.timeslots) !== null && _h !== void 0 ? _h : []).length === 0) {
                // Finishes the appointment process and redirects
                await this.noTimeSlot(null);
                return;
            }
        }
        this.isFindingAppointments = false;
    }
};
__decorate([
    layout.State('color')
], ExpertAppointmentDateSelection.prototype, "color", void 0);
__decorate([
    expertAppointment.State('isConfirmingAppointment')
], ExpertAppointmentDateSelection.prototype, "isConfirmingAppointment", void 0);
__decorate([
    expertAppointment.State('appointmentFindResult')
], ExpertAppointmentDateSelection.prototype, "appointmentFindResult", void 0);
__decorate([
    expertAppointment.State('appointmentFindError')
], ExpertAppointmentDateSelection.prototype, "appointmentFindError", void 0);
__decorate([
    expertAppointment.State('selectedTimeSlot')
], ExpertAppointmentDateSelection.prototype, "selectedTimeSlot", void 0);
__decorate([
    expertAppointment.State('userContact')
], ExpertAppointmentDateSelection.prototype, "userContact", void 0);
__decorate([
    contract.State('selectedContract')
], ExpertAppointmentDateSelection.prototype, "selectedContract", void 0);
__decorate([
    expertAppointment.Action('updateContact')
], ExpertAppointmentDateSelection.prototype, "updateContact", void 0);
__decorate([
    expertAppointment.Getter('selectedAppointment')
], ExpertAppointmentDateSelection.prototype, "selectedAppointment", void 0);
__decorate([
    expertAppointment.Action('confirmAppointment')
], ExpertAppointmentDateSelection.prototype, "confirmAppointment", void 0);
__decorate([
    expertAppointment.Action('fetchExpertAppointments')
], ExpertAppointmentDateSelection.prototype, "fetchExpertAppointments", void 0);
__decorate([
    expertAppointment.Action('selectTimeSlot')
], ExpertAppointmentDateSelection.prototype, "selectTimeSlot", void 0);
__decorate([
    expertAppointment.Action('validateContact')
], ExpertAppointmentDateSelection.prototype, "validateContact", void 0);
__decorate([
    expertAppointment.Getter('validation')
], ExpertAppointmentDateSelection.prototype, "validation", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], ExpertAppointmentDateSelection.prototype, "updateAndSaveClaim", void 0);
ExpertAppointmentDateSelection = __decorate([
    Component({
        components: { DropdownCard, UiButton, UserContactForm },
        props: {
            step: {
                type: String,
                required: true,
            },
        },
        meta: {
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
        },
    })
], ExpertAppointmentDateSelection);
export default ExpertAppointmentDateSelection;
