var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import BreakInDamageDescriptionSubSection from '../UI/BreakInDamageDescriptionSubSection.vue';
import { FLAPPY_SHUTTER_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES, SHUTTER_MATERIAL_CHOICES, } from 'shared/constants/breakInDamages/ShutterConstants';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import BreakInDamageDescriptionSectionCard from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionSectionCard.vue';
import BreakInDamageDescriptionStep from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionStep.vue';
import BreakInDamageChoices from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageChoices.vue';
const layout = namespace('layout');
const breakInDamage = namespace('breakInDamage');
let FlappyShutterLeaves = class FlappyShutterLeaves extends Vue {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.showValidation = false;
        this.SHUTTER_MATERIAL_CHOICES = SHUTTER_MATERIAL_CHOICES;
        this.flappyShutterLeaves = Object.entries(FLAPPY_SHUTTER_LEAVES_TYPE_TRAD_KEYS_AND_IMAGES).map(([FlappyShutterLeaves, description]) => ({
            value: FlappyShutterLeaves,
            tradKey: description === null || description === void 0 ? void 0 : description.tradKey,
            image: description === null || description === void 0 ? void 0 : description.image,
        }));
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get breakInDamage() {
        return this.change.data;
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get isValid() {
        return (!this.validation.fieldHasViolations('flappyShutterLeaves') &&
            !this.validation.fieldHasViolations('material'));
    }
    update(data) {
        this.updateBreakInDamage({ changeKey: this.changeKey, data });
    }
    save() {
        this.isSaving = true;
        this.$emit('goToNextStep');
        this.isSaving = false;
    }
    revealValidation() {
        this.$store.dispatch('error/add', this.$tc('error.form.please_validate_message'));
        this.validateBreakInDamage(this.changeKey);
        this.showValidation = true;
    }
};
__decorate([
    layout.State('color')
], FlappyShutterLeaves.prototype, "color", void 0);
__decorate([
    breakInDamage.Getter('change')
], FlappyShutterLeaves.prototype, "changeGetter", void 0);
__decorate([
    breakInDamage.Action('updateDamage')
], FlappyShutterLeaves.prototype, "updateBreakInDamage", void 0);
__decorate([
    breakInDamage.Getter('validation')
], FlappyShutterLeaves.prototype, "validationGetter", void 0);
__decorate([
    breakInDamage.Action('validateAndRevealDamages')
], FlappyShutterLeaves.prototype, "validateBreakInDamage", void 0);
FlappyShutterLeaves = __decorate([
    Component({
        components: {
            BreakInDamageDescriptionSubSection,
            BreakInDamageChoices,
            RadioPrimaryButton,
            BreakInDamageDescriptionSectionCard,
            BreakInDamageDescriptionStep,
        },
        props: {
            changeKey: { type: [String, Number], default: null },
            isFinalStep: { type: Boolean, default: false },
        },
    })
], FlappyShutterLeaves);
export default FlappyShutterLeaves;
