var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import DateAndTimePicker from '@declaration/components/UI/DateAndTimePicker.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import { areDatesOnSameDay, formatDate, formatDateTime, formatTime } from '@shared/utils/date';
import { QuestionComponent, QuestionProps } from '@declaration/question';
const claim = namespace('claim');
const qualification = namespace('qualification');
let RobberyAbsenceDatesQuestion = class RobberyAbsenceDatesQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.today = new Date();
        this.startValue = null;
        this.endValue = null;
    }
    created() {
        var _a, _b, _c, _d;
        // Initialize with qualification values
        this.startValue = this.stringToDate((_b = (_a = this.qualification) === null || _a === void 0 ? void 0 : _a.absenceDateTimeInterval) === null || _b === void 0 ? void 0 : _b.start);
        this.endValue = this.stringToDate((_d = (_c = this.qualification) === null || _c === void 0 ? void 0 : _c.absenceDateTimeInterval) === null || _d === void 0 ? void 0 : _d.end);
    }
    onStartChange(start) {
        this.startValue = new Date(start);
    }
    onEndChange(end) {
        this.endValue = new Date(end);
    }
    onValidation() {
        var _a, _b, _c, _d;
        this.updateAndSaveQualification({
            step: this.step,
            qualificationPayload: {
                absenceDateTimeInterval: {
                    start: this.startValue && this.startValue.toISOString(),
                    end: this.endValue && this.endValue.toISOString(),
                },
            },
            claimPayload: {
                damageDate: this.endValue && this.endValue.toISOString(),
            },
        });
        return (((_b = (_a = this.qualification) === null || _a === void 0 ? void 0 : _a.absenceDateTimeInterval) === null || _b === void 0 ? void 0 : _b.start) &&
            ((_d = (_c = this.qualification) === null || _c === void 0 ? void 0 : _c.absenceDateTimeInterval) === null || _d === void 0 ? void 0 : _d.end));
    }
    get maxStartDate() {
        return this.endValue || this.today;
    }
    get minEndDate() {
        return this.startValue;
    }
    get canValidate() {
        return !!(this.startValue && this.endValue);
    }
    get answer() {
        var _a, _b;
        const start = (_a = this.qualification.absenceDateTimeInterval) === null || _a === void 0 ? void 0 : _a.start;
        const end = (_b = this.qualification.absenceDateTimeInterval) === null || _b === void 0 ? void 0 : _b.end;
        if (!start || !end) {
            return null;
        }
        if (areDatesOnSameDay(start, end)) {
            return this.$tc('claim.qualification.robbery.absence_dates.same_day.answer', 0, {
                day: formatDate(start),
                start: formatTime(start),
                end: formatTime(end),
            });
        }
        return this.$tc('claim.qualification.robbery.absence_dates.answer', 0, {
            start: formatDateTime(start),
            end: formatDateTime(end),
        });
    }
    stringToDate(dateString) {
        return dateString ? new Date(dateString) : null;
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], RobberyAbsenceDatesQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], RobberyAbsenceDatesQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], RobberyAbsenceDatesQuestion.prototype, "updateAndSaveQualification", void 0);
RobberyAbsenceDatesQuestion = __decorate([
    Component({
        components: { QuestionPanel, RadioPrimaryButton, UiButton, DateAndTimePicker },
        props: QuestionProps,
    })
], RobberyAbsenceDatesQuestion);
export default RobberyAbsenceDatesQuestion;
