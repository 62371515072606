var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import Card from '@declaration/components/UI/Card.vue';
import { Step } from '@shared/claim/steps';
const layout = namespace('layout');
const claim = namespace('claim');
let UserComment = class UserComment extends Vue {
    constructor() {
        super(...arguments);
        this.yesNoQuestionValuesAndTradKeys = YES_OR_NO_CHOICES;
        this.showUserCommentArea = null;
        this.userComment = '';
        this.isUpdatingClaim = false;
    }
    showTextArea() {
        if (this.showUserCommentArea) {
            this.$nextTick(() => {
                const textAreaInput = this.$refs.textArea;
                if (this.$refs.textArea) {
                    textAreaInput.focus();
                }
            });
        }
        else {
            this.nextStep();
        }
    }
    async nextStep() {
        this.isUpdatingClaim = true;
        await this.updateAndSaveClaim({
            claimPayload: {
                userComment: this.userComment,
            },
            step: Step.UserComment,
        });
        this.isUpdatingClaim = false;
    }
    beforeEnter(el) {
        el.style.height = '0';
        el.style.opacity = '0';
        el.style.transition = 'none';
    }
    enter(el) {
        requestAnimationFrame(() => {
            el.style.transition = 'height 0.3s ease, opacity 0.3s ease 0.2s';
            el.style.height = el.scrollHeight + 'px';
            el.style.opacity = '1';
        });
    }
    leave(el) {
        el.style.transition = 'height 0.3s ease, opacity 0.3s ease 0.2s';
        el.style.height = el.scrollHeight + 'px';
        el.style.opacity = '1';
        requestAnimationFrame(() => {
            el.style.height = '0';
            el.style.opacity = '0';
        });
    }
};
__decorate([
    layout.State('color')
], UserComment.prototype, "color", void 0);
__decorate([
    claim.State('claim')
], UserComment.prototype, "claim", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], UserComment.prototype, "updateAndSaveClaim", void 0);
UserComment = __decorate([
    Component({
        components: { Card, RadioPrimaryButton, UiButton },
        meta: {
            title: 'in_management.title',
            goBack: null,
        },
    })
], UserComment);
export default UserComment;
