var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import ReceiptForm from '@declaration/components/receipt/ReceiptForm.vue';
import Modal from '@declaration/components/UI/Modal.vue';
const receipt = namespace('receipt');
let ReceiptModal = class ReceiptModal extends Vue {
    constructor() {
        super(...arguments);
        this.isSaving = false;
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get receipt() {
        return this.change.data;
    }
    get invalidForm() {
        return !this.validation.isValid;
    }
    async saveReceipt() {
        this.isSaving = true;
        try {
            await this.saveChanges();
            this.onClose();
        }
        catch (_a) { }
        this.isSaving = false;
    }
    onClose() {
        this.$emit('close');
    }
};
__decorate([
    receipt.Getter('change')
], ReceiptModal.prototype, "changeGetter", void 0);
__decorate([
    receipt.Getter('validation')
], ReceiptModal.prototype, "validationGetter", void 0);
__decorate([
    receipt.Action('saveChanges')
], ReceiptModal.prototype, "saveChanges", void 0);
__decorate([
    receipt.Action('validateAndRevealDamages')
], ReceiptModal.prototype, "validateReceipt", void 0);
__decorate([
    receipt.Action('deleteDamage')
], ReceiptModal.prototype, "deleteReceipt", void 0);
ReceiptModal = __decorate([
    Component({
        components: {
            ReceiptForm,
            Modal,
            UiButton,
        },
        props: {
            isDisplayed: { type: Boolean, required: true },
            changeKey: { type: [Number, String], default: null },
        },
    })
], ReceiptModal);
export default ReceiptModal;
