var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { emptyBreakInDamageDocumentFields, shouldDisplayReceiptUpload, shouldDisplayInitialReceiptUpload, shouldDisplayLateReceiptUpload, shouldDisplayBothUpload, isRepairTemporary, } from '@shared/breakInDamage/breakInDamageHelpers';
import { objectToTranslatableChoices } from '@shared/choice';
import BreakInDamagePictures from '@declaration/components/breakInDamage/BreakInDamagePictures.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import RepairQuoteUpload from '@declaration/components/upload/RepairQuoteUpload.vue';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
import { ROBBERY_BREAKABLE_ELEMENT_SVGS } from '@declaration/constants/robberySvgs';
import { ROBBERY_BREAKABLE_ELEMENT_SLUG_AND_TRAD_KEYS } from '@shared/constants/robberyConstants';
import { BREAK_IN_DAMAGE_FIELDS_FOR_TYPE, BREAK_IN_DAMAGE_REPARATION_TYPES_TRAD_KEYS, BreakInDamageField, } from '@shared/types/api/breakInDamage';
import { ClaimFileType } from '@shared/types/file';
const layout = namespace('layout');
const breakInDamage = namespace('breakInDamage');
const qualification = namespace('qualification');
let DefaultDescription = class DefaultDescription extends Vue {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.BreakInDamageField = BreakInDamageField;
        this.ClaimFileType = ClaimFileType;
        this.yesOrNoChoices = YES_OR_NO_CHOICES;
        this.isReceiptUploading = false;
        this.repairationTypes = objectToTranslatableChoices(BREAK_IN_DAMAGE_REPARATION_TYPES_TRAD_KEYS);
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get breakInDamage() {
        return this.change.data;
    }
    get isValid() {
        // make assertion on type because when the modal is initially open,
        // the store is considered as valid as no object is created
        return this.validation.isValid && !!this.breakInDamage.type;
    }
    get shouldDisplayCategoryChoices() {
        return !this.breakInDamage.type;
    }
    get breakables() {
        return ROBBERY_BREAKABLE_ELEMENT_SLUG_AND_TRAD_KEYS.map((element) => ({
            ...element,
            svg: ROBBERY_BREAKABLE_ELEMENT_SVGS[element.slug],
        }));
    }
    get shouldDisplayMustBeReplacedField() {
        return this.breakInDamage.isRepaired === false;
    }
    get shouldDisplayReceiptUpload() {
        return shouldDisplayReceiptUpload(this.breakInDamage);
    }
    get shouldDisplayInitialReceiptUpload() {
        return shouldDisplayInitialReceiptUpload(this.breakInDamage);
    }
    get shouldDisplayLateReceiptUpload() {
        return shouldDisplayLateReceiptUpload(this.breakInDamage);
    }
    get isRepairTemporary() {
        return isRepairTemporary(this.breakInDamage);
    }
    get shouldDisplayBothUpload() {
        return shouldDisplayBothUpload(this.breakInDamage);
    }
    onRepairationType(type) {
        this.update({
            repairationType: type,
            ...emptyBreakInDamageDocumentFields(this.breakInDamage),
        });
    }
    hasField(field) {
        return (!!this.breakInDamage.type &&
            BREAK_IN_DAMAGE_FIELDS_FOR_TYPE[this.breakInDamage.type].includes(field));
    }
    revealValidation() {
        this.$store.dispatch('error/add', this.$tc('error.form.please_validate_message'));
        this.validateBreakInDamage(this.changeKey);
    }
    setIsRepaired(isRepaired) {
        const payload = {
            isRepaired,
            ...emptyBreakInDamageDocumentFields(this.breakInDamage),
        };
        if (isRepaired) {
            payload.mustBeReplaced = null;
        }
        this.update(payload);
    }
    get isStillWorking() {
        return this.breakInDamage.mustBeReplaced === null ? null : !this.breakInDamage.mustBeReplaced;
    }
    onStillWorkingUpdate(choice) {
        const mustBeReplaced = choice === null ? null : !choice;
        this.update({ mustBeReplaced });
    }
    update(data) {
        this.updateBreakInDamage({
            changeKey: this.changeKey,
            data: { ...data },
        });
    }
    onInitialRepairQuoteUpdate({ repairableStatus, repairFileCollection, repairQuoteInfo, }) {
        this.update({
            repairableStatus,
            repairFileCollection,
            repairQuoteInfo,
        });
    }
    onLateRepairQuoteUpdate({ repairFileCollection, repairQuoteInfo }) {
        this.update({
            pendingRepairFileCollection: repairFileCollection,
            pendingRepairQuoteInfo: repairQuoteInfo,
        });
    }
    save() {
        this.isSaving = true;
        this.$emit('goToNextStep');
        this.isSaving = false;
    }
};
__decorate([
    layout.State('color')
], DefaultDescription.prototype, "color", void 0);
__decorate([
    breakInDamage.State('damages')
], DefaultDescription.prototype, "breakInDamages", void 0);
__decorate([
    breakInDamage.Getter('change')
], DefaultDescription.prototype, "changeGetter", void 0);
__decorate([
    breakInDamage.Getter('validation')
], DefaultDescription.prototype, "validationGetter", void 0);
__decorate([
    breakInDamage.Action('saveChanges')
], DefaultDescription.prototype, "saveChanges", void 0);
__decorate([
    breakInDamage.Action('deleteDamage')
], DefaultDescription.prototype, "deleteDamage", void 0);
__decorate([
    breakInDamage.Action('validateAndRevealDamages')
], DefaultDescription.prototype, "validateBreakInDamage", void 0);
__decorate([
    breakInDamage.Action('updateDamage')
], DefaultDescription.prototype, "updateBreakInDamage", void 0);
__decorate([
    breakInDamage.Action('resetChanges')
], DefaultDescription.prototype, "reseteChanges", void 0);
__decorate([
    qualification.State('qualification')
], DefaultDescription.prototype, "qualification", void 0);
DefaultDescription = __decorate([
    Component({
        components: {
            RadioPrimaryButton,
            BreakInDamagePictures,
            UiButton,
            RepairQuoteUpload,
        },
        props: {
            changeKey: { type: [String, Number], default: null },
        },
    })
], DefaultDescription);
export default DefaultDescription;
