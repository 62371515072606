var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import Modal from '@declaration/components/UI/Modal.vue';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import { ClaimFileType } from '@shared/types/file';
const claim = namespace('claim');
const layout = namespace('layout');
const qualification = namespace('qualification');
let RobberyComplaintFiledModal = class RobberyComplaintFiledModal extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/receipts/receipt.svg');
        this.COMPLAINT_RECEIPT_FILE = ClaimFileType.ComplaintReceipt;
        this.isSavingClaim = false;
        this.fileCollection = null;
    }
    created() {
        this.fileCollection = this.qualification.complaintReceiptFileCollection;
    }
    get validationLabel() {
        var _a;
        return this.mode === 'intro'
            ? this.$tc(this.qualification.isComplaintFiled && ((_a = this.fileCollection) === null || _a === void 0 ? void 0 : _a.uploadedAppFiles.length) === 0
                ? 'claim.qualification.robbery.complaint.intro.filed.modal.continue'
                : 'claim.qualification.robbery.complaint.intro.not_filed.modal.continue')
            : this.$tc('claim.qualification.robbery.complaint.outro.filed.modal.continue');
    }
    onCollectionUpdate(complaintReceiptFileCollection) {
        this.fileCollection = complaintReceiptFileCollection;
    }
    async onValidation() {
        this.isSavingClaim = true;
        this.$emit('close-modal');
        await this.updateAndSaveQualification({
            qualificationPayload: {
                isComplaintFiled: this.qualification.isComplaintFiled,
                complaintReceiptFileCollection: this.fileCollection,
            },
            step: this.step,
        });
        // in case the upload is required, the brms does not redirect to index, otherwise we would lose this step as onValidation
        if (this.qualification.isComplaintFiled && !this.qualification.complaintReceiptFileCollection) {
            this.$goToLocalePath('index');
        }
        this.isSavingClaim = false;
    }
    get isBlocked() {
        var _a;
        return this.mode === 'blocking' && ((_a = this.fileCollection) === null || _a === void 0 ? void 0 : _a.uploadedAppFiles.length) === 0;
    }
};
__decorate([
    claim.State('claim')
], RobberyComplaintFiledModal.prototype, "claim", void 0);
__decorate([
    layout.State('color')
], RobberyComplaintFiledModal.prototype, "color", void 0);
__decorate([
    qualification.State('qualification')
], RobberyComplaintFiledModal.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], RobberyComplaintFiledModal.prototype, "updateQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], RobberyComplaintFiledModal.prototype, "updateAndSaveQualification", void 0);
RobberyComplaintFiledModal = __decorate([
    Component({
        components: {
            Modal,
            TitleImageDescriptionLayout,
            UiButton,
            FileCollectionUpload,
        },
        props: {
            isDisplayed: {
                type: Boolean,
                default: false,
            },
            step: {
                type: String,
                required: true,
            },
            mode: {
                type: String,
                default: 'intro',
            },
        },
    })
], RobberyComplaintFiledModal);
export default RobberyComplaintFiledModal;
