var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton, UiIcon } from '@claims-ia/design-system';
import DamageFields from '@declaration/components/damage/DamageFields.vue';
import RoomImage from '@declaration/components/roomDamage/RoomImage.vue';
import { ROOM_CONFIGURATION_BY_ROOM_TYPE, } from '@shared/constants/equipmentConstants';
import { ModelFieldsProjection } from '@shared/modelField/modelField';
import { QUALIFICATION_ROOM } from '@shared/roomDamage/constants';
import { roomDamageFields } from '@shared/roomDamage/roomDamageFields';
import { EMPTY_COVERING_CATEGORIES_BY_SURFACE_TYPE } from '@shared/roomDamage/surfaceDamage';
const roomDamage = namespace('roomDamage');
const qualification = namespace('qualification');
let RoomDamageCard = class RoomDamageCard extends Vue {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.roomConfigurationByRoomType = ROOM_CONFIGURATION_BY_ROOM_TYPE;
    }
    onDelete() {
        if (this.confirmDelete !== null) {
            this.confirmDelete(this.roomDamage.id, this.deleteRoomDamage);
        }
    }
    async deleteRoomDamage() {
        this.isSaving = true;
        if (this.roomDamage.id) {
            this.delete(this.roomDamage.id);
            await this.saveChanges();
        }
        else {
            this.delete(this.changeKey);
        }
        this.isSaving = false;
    }
    get isEmpty() {
        return this.isEmptyGetter(this.changeKey);
    }
    get modelFields() {
        var _a;
        return roomDamageFields(this.roomDamage, {
            edition: false,
            type: this.typeTrigram,
            projection: ModelFieldsProjection.Declaration,
            translate: this.$tc.bind(this),
            coveringCategoryLabels: this.coveringCategories
                ? Object.fromEntries(Object.entries(this.coveringCategories).map(([id, { label }]) => [id, label]))
                : {},
            coveringCategoriesBySurfaceType: (_a = this.coveringCategoriesBySurfaceType) !== null && _a !== void 0 ? _a : EMPTY_COVERING_CATEGORIES_BY_SURFACE_TYPE,
        });
    }
    get roomComponentFromRoomNameSlug() {
        if (this.roomDamage.roomNameSlug && this.roomDamage.roomNameSlug in QUALIFICATION_ROOM) {
            return this.roomConfigurationByRoomType[this.roomDamage.roomNameSlug].svgComponentName;
        }
        return null;
    }
};
__decorate([
    roomDamage.State('damages')
], RoomDamageCard.prototype, "roomDamages", void 0);
__decorate([
    roomDamage.State('coveringCategories')
], RoomDamageCard.prototype, "coveringCategories", void 0);
__decorate([
    roomDamage.State('coveringCategoriesBySurfaceType')
], RoomDamageCard.prototype, "coveringCategoriesBySurfaceType", void 0);
__decorate([
    roomDamage.Getter('isEmpty')
], RoomDamageCard.prototype, "isEmptyGetter", void 0);
__decorate([
    roomDamage.Action('saveChanges')
], RoomDamageCard.prototype, "saveChanges", void 0);
__decorate([
    roomDamage.Action('deleteDamage')
], RoomDamageCard.prototype, "delete", void 0);
__decorate([
    qualification.State('typeTrigram')
], RoomDamageCard.prototype, "typeTrigram", void 0);
RoomDamageCard = __decorate([
    Component({
        props: {
            roomDamage: {
                type: Object,
                required: true,
            },
            index: {
                type: Number,
                required: true,
            },
            changeKey: { type: [Number, String], default: null },
            canModify: {
                type: Boolean,
                default: true,
            },
            confirmDelete: {
                type: Function,
                default: null,
            },
            currentStep: {
                type: String,
                default: null,
            },
            title: {
                type: String,
                required: true,
            },
        },
        components: {
            UiButton,
            UiIcon,
            RoomImage,
            DamageFields,
        },
    })
], RoomDamageCard);
export default RoomDamageCard;
