var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton, UiIcon } from '@claims-ia/design-system';
import { brandAsset } from '@declaration/environment/brandAsset';
import { closingStatus } from '@declaration/claim/status';
import { ClaimLanguage } from '@shared/types/api/user';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { INSURER_CONFIG } from '@shared/insurer';
import LanguageSelector from '@declaration/components/LanguageSelector.vue';
const layout = namespace('layout');
const authentication = namespace('authentication');
const pages = namespace('pages');
const claim = namespace('claim');
const qualification = namespace('qualification');
let Header = class Header extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.languages = Object.values(ClaimLanguage);
        this.svgLogo = brandAsset('header_logo.svg');
        this.pngLogo = brandAsset('header_logo.png');
        this.logoSrc = '';
        this.closingStatutes = closingStatus;
    }
    mounted() {
        this.logoSrc = this.svgLogo;
    }
    onImageError() {
        if (this.logoSrc !== this.pngLogo) {
            this.logoSrc = this.pngLogo;
        }
    }
    close() {
        if (this.closeCallback === null) {
            return;
        }
        if (typeof this.closeCallback === 'string') {
            this.$goToLocalePath(this.closeCallback);
            return;
        }
        this.closeCallback(this);
    }
    async goBack() {
        if (this.goBackCallback === null) {
            return;
        }
        const res = this.goBackCallback(this);
        if (res instanceof Promise) {
            this.isLoading = true;
            await res;
            this.isLoading = false;
        }
    }
    get isClosingStatus() {
        return this.closingStatutes.includes(this.currentStatus);
    }
    get shouldDisplayClose() {
        var _a, _b;
        const showCloseBtnIfMagicLink = (_b = (_a = INSURER_CONFIG.declaration.closeCallBackBtnBehavior) === null || _a === void 0 ? void 0 : _a.showCloseBtnIfMagicLink) !== null && _b !== void 0 ? _b : false;
        const magicLinkCases = !this.magicLink || (this.magicLink && showCloseBtnIfMagicLink);
        return (magicLinkCases &&
            !!this.closeCallback &&
            this.localePath(this.$route.path) === this.localePath({ name: 'declaration' }));
    }
    get shouldDisplayBack() {
        return !!this.goBackCallback && !this.isPreviousStepLocked && !this.isClosingStatus;
    }
    get shouldDisplayLanguages() {
        return insurerHasFeature(InsurerFeatureFlag.LanguageSelection) && this.accessToken !== null;
    }
    get textColor() {
        return this.isMobile ? this.color.mobileHeaderColor : this.color.headerColor;
    }
    async logout() {
        await this.dispatchLogoutAction();
        await this.$router.push('/');
    }
};
__decorate([
    qualification.State('qualification')
], Header.prototype, "qualification", void 0);
__decorate([
    layout.State('closeCallback')
], Header.prototype, "closeCallback", void 0);
__decorate([
    layout.State('goBackCallback')
], Header.prototype, "goBackCallback", void 0);
__decorate([
    layout.State('color')
], Header.prototype, "color", void 0);
__decorate([
    layout.State('isMobile')
], Header.prototype, "isMobile", void 0);
__decorate([
    layout.State('title')
], Header.prototype, "title", void 0);
__decorate([
    layout.Getter('isDesktop')
], Header.prototype, "isDesktop", void 0);
__decorate([
    pages.Getter('isPreviousStepLocked')
], Header.prototype, "isPreviousStepLocked", void 0);
__decorate([
    authentication.Action('logout')
], Header.prototype, "dispatchLogoutAction", void 0);
__decorate([
    authentication.State('magicLink')
], Header.prototype, "magicLink", void 0);
__decorate([
    authentication.State('accessToken')
], Header.prototype, "accessToken", void 0);
__decorate([
    claim.Getter('currentStep')
], Header.prototype, "currentStep", void 0);
__decorate([
    claim.Getter('currentStatus')
], Header.prototype, "currentStatus", void 0);
Header = __decorate([
    Component({
        components: { LanguageSelector, UiButton, UiIcon },
    })
], Header);
export default Header;
