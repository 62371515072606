var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import BreakInDamageDescriptionSectionCard from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionSectionCard.vue';
import BreakInDamageDescriptionStep from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionStep.vue';
import { RobberyBreakableElement } from '@shared/types/api/claim';
import BreakInDamagePicturesSection from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamagePicturesSection.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import BreakInDamageCheckBox from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageCheckBox.vue';
import BreakInDamageDescriptionSubSection from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionSubSection.vue';
import { parseLength } from '@declaration/helpers/typeFormatters';
const breakInDamage = namespace('breakInDamage');
let Photos = class Photos extends Vue {
    constructor() {
        super(...arguments);
        this.parseLength = parseLength;
        this.showValidation = false;
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get breakInDamage() {
        return this.change.data;
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get isValid() {
        return !this.validation.fieldHasViolations('pictureCollection');
    }
    save() {
        this.$emit('goToNextStep');
    }
    revealValidation() {
        this.$store.dispatch('error/add', this.$tc('error.form.please_validate_message'));
        this.validateBreakInDamage(this.changeKey);
        this.showValidation = true;
    }
    get subtitleText() {
        if (this.breakInDamage.type) {
            if (this.breakInDamage.type === RobberyBreakableElement.Door) {
                return this.$tc('claim.declaration.break_in_damages.form.door.photos.subtitle');
            }
            if (this.breakInDamage.type === RobberyBreakableElement.Window) {
                return this.$tc('claim.declaration.break_in_damages.form.window.photos.subtitle');
            }
            if (this.breakInDamage.type === RobberyBreakableElement.Fence) {
                return this.$tc('claim.declaration.break_in_damages.form.fence.photos.subtitle');
            }
            if (this.breakInDamage.type === RobberyBreakableElement.FrenchWindow) {
                return this.$tc('claim.declaration.break_in_damages.form.french-window.photos.subtitle');
            }
            if (this.breakInDamage.type === RobberyBreakableElement.SlidingBayWindow) {
                return this.$tc('claim.declaration.break_in_damages.form.sliding-bay.photos.subtitle');
            }
            if (this.breakInDamage.type === RobberyBreakableElement.Shutter) {
                return this.$tc('claim.declaration.break_in_damages.form.shutter.photos.subtitle');
            }
            if (this.breakInDamage.type === RobberyBreakableElement.GarageDoor) {
                return this.$tc('claim.declaration.break_in_damages.form.garage-door.photos.subtitle');
            }
            if (this.breakInDamage.type === RobberyBreakableElement.Gate) {
                return this.$tc('claim.declaration.break_in_damages.form.gate.photos.subtitle');
            }
            if (this.breakInDamage.type === RobberyBreakableElement.Other) {
                return this.$tc('claim.declaration.break_in_damages.form.other.photos.subtitle');
            }
            if (this.breakInDamage.type === RobberyBreakableElement.LittleGate) {
                return this.$tc('claim.declaration.break_in_damages.form.little-gate.photos.subtitle');
            }
            if (this.breakInDamage.type === RobberyBreakableElement.Gate) {
                return this.$tc('claim.declaration.break_in_damages.form.gate.photos.subtitle');
            }
        }
        return null;
    }
};
__decorate([
    breakInDamage.Getter('change')
], Photos.prototype, "changeGetter", void 0);
__decorate([
    breakInDamage.Getter('validation')
], Photos.prototype, "validationGetter", void 0);
__decorate([
    breakInDamage.Action('validateAndRevealDamages')
], Photos.prototype, "validateBreakInDamage", void 0);
Photos = __decorate([
    Component({
        components: {
            BreakInDamagePicturesSection,
            BreakInDamageDescriptionStep,
            BreakInDamageDescriptionSectionCard,
            RadioPrimaryButton,
            BreakInDamageCheckBox,
            BreakInDamageDescriptionSubSection,
        },
        props: {
            changeKey: { type: [String, Number], default: null },
            isFinalStep: { type: Boolean, default: false },
            isSaving: { type: Boolean, default: false },
        },
    })
], Photos);
export default Photos;
