var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Model, Vue, Emit, Watch } from 'vue-property-decorator';
import { UiIcon } from '@claims-ia/design-system';
import { namespace } from 'vuex-class';
import DOMPurify from 'dompurify';
import { FILE_SIZE_LIMIT10_MBYTES } from '@shared/constants/fileConstants';
import { fetchFile } from '@shared/files/fetchFile';
import { isImage, isPdf } from '@shared/files/mimeTypes';
import { uploadFile } from '@shared/files/uploadFile';
import { resourceIri } from '@shared/utils/iri';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
const claim = namespace('claim');
let FileCollectionUpload = class FileCollectionUpload extends Vue {
    constructor() {
        super(...arguments);
        this.uploadedAppFiles = [];
        this.isUploading = false;
        this.isFetching = false;
    }
    get hasFiles() {
        return this.uploadedAppFiles.length > 0;
    }
    get files() {
        return this.uploadedAppFiles.map((file) => {
            if (typeof file === 'string') {
                return {
                    key: file,
                    isFetching: true,
                    uploaded: null,
                };
            }
            return {
                key: file.id,
                isFetching: false,
                uploaded: file,
            };
        });
    }
    get fileIris() {
        return this.uploadedAppFiles.map(resourceIri);
    }
    get uploadButtonLabel() {
        var _a;
        return DOMPurify.sanitize(this.hasFiles
            ? (_a = this.addFileLabel) !== null && _a !== void 0 ? _a : this.$tc('model.document.upload.add_page')
            : this.uploadLabel);
    }
    async onUpload(file) {
        const claimId = this.claim.id;
        if (!claimId)
            return;
        if (file.size >= FILE_SIZE_LIMIT10_MBYTES) {
            this.$store.dispatch('error/add', this.$tc('error.snackbar.upload.file_size_greater_than_10_mo'));
            return;
        }
        if (!isImage(file.type) && !isPdf(file.type)) {
            this.$store.dispatch('error/add', this.$tc('error.snackbar.upload.file_image_or_pdf'));
            return;
        }
        try {
            this.isUploading = true;
            const uploaded = await uploadFile(file, claimId, this.claimFileType, this.$axios);
            this.update([...this.fileIris, uploaded.id]);
        }
        catch (_a) {
            this.$store.dispatch('error/add', this.$tc('error.snackbar.info'));
        }
        finally {
            this.isUploading = false;
        }
    }
    // Delete should not actually remove files as file collection can be
    // attached to an entity or form for which edition can be canceled
    onDelete(uploadedAppFileIri) {
        this.update(this.fileIris.filter((iri) => iri !== uploadedAppFileIri));
    }
    update(uploadedAppFiles) {
        var _a;
        const uploadedAppFileCollection = { ...((_a = this.fileCollection) !== null && _a !== void 0 ? _a : emptyUploadedFileCollection()) };
        uploadedAppFileCollection.uploadedAppFiles = uploadedAppFiles;
        return uploadedAppFileCollection;
    }
    async watchFileCollection(fileCollection) {
        this.isFetching = false;
        this.isUploading = false;
        if (!fileCollection) {
            this.uploadedAppFiles = [];
            return;
        }
        this.uploadedAppFiles = fileCollection.uploadedAppFiles;
        // Some uploaded app file can be IRI (for lazy loading)
        const hasFilesToFetch = fileCollection.uploadedAppFiles.some((file) => typeof file === 'string');
        if (!hasFilesToFetch)
            return;
        this.isFetching = true;
        // Fetch lazily loaded files
        this.uploadedAppFiles = await Promise.all(fileCollection.uploadedAppFiles.map((uploadedAppFileValue) => typeof uploadedAppFileValue === 'string'
            ? fetchFile(uploadedAppFileValue, this.$axios)
            : uploadedAppFileValue));
        this.isFetching = false;
    }
};
__decorate([
    Model('update', { type: Object, default: null })
], FileCollectionUpload.prototype, "fileCollection", void 0);
__decorate([
    Prop({ type: String, required: true })
], FileCollectionUpload.prototype, "claimFileType", void 0);
__decorate([
    Prop({ type: String, required: true })
], FileCollectionUpload.prototype, "uploadLabel", void 0);
__decorate([
    Prop({ type: String, default: null })
], FileCollectionUpload.prototype, "addFileLabel", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], FileCollectionUpload.prototype, "canDelete", void 0);
__decorate([
    Prop({ type: String, default: null })
], FileCollectionUpload.prototype, "icon", void 0);
__decorate([
    claim.State('claim')
], FileCollectionUpload.prototype, "claim", void 0);
__decorate([
    Emit('update')
], FileCollectionUpload.prototype, "update", null);
__decorate([
    Watch('fileCollection', { immediate: true })
], FileCollectionUpload.prototype, "watchFileCollection", null);
FileCollectionUpload = __decorate([
    Component({
        components: { UiIcon },
    })
], FileCollectionUpload);
export default FileCollectionUpload;
