var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { QualificationRoomType, LeakageCauseOutsideHouseDetail, } from '@shared/types/api/claim';
import { HousingTypeSlug } from '@shared/types/api/contract';
import OutsideHouse from '@declaration/components/svg/OutsideHouse.vue';
import { QUALIFICATION_OUTSIDE_HOUSE_DETAIL } from '@shared/constants/equipmentConstants';
import { OUTSIDE_HOUSE_DETAILS, OUTSIDE_APPARTMENT_DETAILS, } from '@declaration/constants/equipmentConstants';
import { INSURER_CONFIG } from '@shared/insurer';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
const layout = namespace('layout');
const qualification = namespace('qualification');
const claim = namespace('claim');
const contract = namespace('contract');
let LeakageCauseOutsideHouseQuestion = class LeakageCauseOutsideHouseQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.selectedDetail = null;
        this.hoveredDetail = null;
    }
    created() {
        this.selectedDetail = this.savedDetail;
    }
    get itsAnApartment() {
        return this.housingType === HousingTypeSlug.Apartment;
    }
    get savedDetail() {
        if (this.waterDamageQualification.leakageCauseDetailSlug) {
            return this.waterDamageQualification.leakageCauseDetailSlug;
        }
        return this.waterDamageQualification.leakageCauseRoomSlug === QualificationRoomType.Garage
            ? LeakageCauseOutsideHouseDetail.OutsideHouseGarage
            : null;
    }
    get detailValuesAndTradKeys() {
        var _a, _b, _c, _d;
        if (this.housingType === HousingTypeSlug.Apartment) {
            const outsideAppartmentDetails = ((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.roomDamagesContents) === null || _b === void 0 ? void 0 : _b.outsideAppartmentDetails) ||
                OUTSIDE_APPARTMENT_DETAILS;
            return outsideAppartmentDetails.withComponent.map((slug) => {
                return {
                    value: slug,
                    tradKey: QUALIFICATION_OUTSIDE_HOUSE_DETAIL[slug].tradKey,
                };
            });
        }
        else {
            const outsideHouseDetails = ((_d = (_c = INSURER_CONFIG.declaration.pages) === null || _c === void 0 ? void 0 : _c.roomDamagesContents) === null || _d === void 0 ? void 0 : _d.outsideHouseDetails) ||
                OUTSIDE_HOUSE_DETAILS;
            return outsideHouseDetails.withComponent.map((slug) => {
                return {
                    value: slug,
                    tradKey: QUALIFICATION_OUTSIDE_HOUSE_DETAIL[slug].tradKey,
                };
            });
        }
    }
    get otherValuesAndTradKeys() {
        var _a, _b, _c, _d;
        if (this.housingType === HousingTypeSlug.Apartment) {
            const outsideAppartmentDetails = ((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.roomDamagesContents) === null || _b === void 0 ? void 0 : _b.outsideAppartmentDetails) ||
                OUTSIDE_APPARTMENT_DETAILS;
            return outsideAppartmentDetails.other.map((slug) => {
                return {
                    value: slug,
                    tradKey: QUALIFICATION_OUTSIDE_HOUSE_DETAIL[slug].tradKey,
                };
            });
        }
        else {
            const outsideHouseDetails = ((_d = (_c = INSURER_CONFIG.declaration.pages) === null || _c === void 0 ? void 0 : _c.roomDamagesContents) === null || _d === void 0 ? void 0 : _d.outsideHouseDetails) ||
                OUTSIDE_HOUSE_DETAILS;
            return outsideHouseDetails.other.map((slug) => {
                return {
                    value: slug,
                    tradKey: QUALIFICATION_OUTSIDE_HOUSE_DETAIL[slug].tradKey,
                };
            });
        }
    }
    updateClaim() {
        const qualificationPayload = this.selectedDetail === LeakageCauseOutsideHouseDetail.OutsideHouseGarage
            ? { leakageCauseRoomSlug: QualificationRoomType.Garage, leakageCauseDetailSlug: null }
            : { leakageCauseRoomSlug: null, leakageCauseDetailSlug: this.selectedDetail };
        this.updateAndSaveWaterDamageQualification({
            qualificationPayload,
            step: this.step,
        });
    }
    updateSelected(leakageCauseDetailSlug) {
        this.selectedDetail = leakageCauseDetailSlug;
    }
    updateHover(leakageCauseDetailSlug) {
        this.hoveredDetail = leakageCauseDetailSlug;
    }
    get answer() {
        if (this.savedDetail === null) {
            return null;
        }
        if (!QUALIFICATION_OUTSIDE_HOUSE_DETAIL[this.savedDetail]) {
            return this.$tc('claim.qualification.leakage_cause_common_area_detail.sumup.default');
        }
        return this.$tc(QUALIFICATION_OUTSIDE_HOUSE_DETAIL[this.savedDetail]
            .leakageCauseOutsideHouseDetailAnswerTradKey);
    }
    get hideHelpLink() {
        return insurerHasFeature(InsurerFeatureFlag.HideHelpLink);
    }
    get showHelpLink() {
        var _a, _b;
        if (this.hideHelpLink &&
            ((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.leakageCauseOutsideHouse) === null || _b === void 0 ? void 0 : _b.showHelpLink) === false) {
            return false;
        }
        return true;
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], LeakageCauseOutsideHouseQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], LeakageCauseOutsideHouseQuestion.prototype, "waterDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], LeakageCauseOutsideHouseQuestion.prototype, "updateAndSaveWaterDamageQualification", void 0);
__decorate([
    layout.Getter('isDesktop')
], LeakageCauseOutsideHouseQuestion.prototype, "isDesktop", void 0);
__decorate([
    contract.Getter('housingType')
], LeakageCauseOutsideHouseQuestion.prototype, "housingType", void 0);
LeakageCauseOutsideHouseQuestion = __decorate([
    Component({
        components: {
            UiButton,
            OutsideHouse,
            RadioPrimaryButton,
            QuestionPanel,
        },
        props: QuestionProps,
    })
], LeakageCauseOutsideHouseQuestion);
export default LeakageCauseOutsideHouseQuestion;
