var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import AmountInput from '@declaration/components/UI/AmountInput.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { GLASS_BREAKAGE_SECURITY_REPARATION_BY_CHOICES } from '@shared/constants/glassBreakageDamageConstants';
import { GLASS_BREAKAGE_SECURITY_REPARATION_TYPE_ANSWERS, GLASS_BREAKAGE_TRAD_KEYS, GLASS_BREAKAGE_SECURITY_REPARATION_TYPE_CHOICES, GLASS_BREAKAGE_SECURITY_REPARATION_BY_ANSWERS, } from '@declaration/constants/glassBreakageDamageConstants';
import { GlassBreakageSecurityReparationBy, GlassBreakageSecurityReparationType, } from '@shared/types/api/glassBreakageDamage';
import { ClaimFileType } from '@shared/types/file';
import { newValidator, validate } from '@shared/validator/validator';
import { ValidationContext } from '@shared/validator/validationContext';
const claim = namespace('claim');
const qualification = namespace('qualification');
let GlassBreakageSecurityQuestion = class GlassBreakageSecurityQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.invoiceFileCollection = null;
        this.validator = null;
    }
    created() {
        this.invoiceFileCollection = this.qualification.temporaryReparationsInvoice;
        this.validator = newValidator({
            temporaryReparationsInvoice: this.invoiceFileCollection,
            temporaryReparationsInvoiceAmount: this.selectedTemporaryReparationsInvoiceAmount,
        });
    }
    get validation() {
        validate(this.validator, {
            temporaryReparationsInvoice: this.selectedTemporaryReparationsInvoice,
            temporaryReparationsInvoiceAmount: this.selectedTemporaryReparationsInvoiceAmount,
        }, {
            temporaryReparationsInvoiceAmount: {
                presence: {
                    allowEmpty: false,
                    message: GLASS_BREAKAGE_TRAD_KEYS['security-reparation-invoice-question-upload-validation'],
                },
            },
            temporaryReparationsInvoice: {
                requiredFileCollection: this.selectedReparationByValue === GlassBreakageSecurityReparationBy.Professional
                    ? {
                        message: 'validator.requiredFileCollection',
                        trigram: ClaimTypeTrigram.Glass,
                    }
                    : null,
            },
        });
        return new ValidationContext(this.validator);
    }
    get reparationTypeQuestionTitle() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['security-reparation-type-question']);
    }
    get reparationByQuestionTitle() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['security-reparation-by-question']);
    }
    get reparationInvoiceQuestionTitle() {
        if (!this.selectedReparationByValue)
            return null;
        return this.isReparationInvoiceMandatory
            ? this.$tc(GLASS_BREAKAGE_TRAD_KEYS['security-reparation-mandatory-invoice-question'])
            : this.$tc(GLASS_BREAKAGE_TRAD_KEYS['security-reparation-optional-invoice-question']);
    }
    get reparationInvoiceQuestionDescription() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['security-reparation-invoice-question-description']);
    }
    get reparationAddInvoiceLabel() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['security-reparation-invoice-question-upload']);
    }
    get reparationTypeChoices() {
        return GLASS_BREAKAGE_SECURITY_REPARATION_TYPE_CHOICES;
    }
    get reparationByChoices() {
        return GLASS_BREAKAGE_SECURITY_REPARATION_BY_CHOICES;
    }
    get selectedReparationTypeValue() {
        return this.qualification.temporaryReparationsType;
    }
    get selectedReparationByValue() {
        return this.qualification.temporaryReparationsBy;
    }
    get selectedTemporaryReparationsInvoice() {
        return this.invoiceFileCollection || this.qualification.temporaryReparationsInvoice;
    }
    get selectedTemporaryReparationsInvoiceAmount() {
        return this.qualification.temporaryReparationsInvoiceAmount;
    }
    get shouldDisplayReparationBy() {
        return (this.selectedReparationTypeValue !== null &&
            this.selectedReparationTypeValue !== undefined &&
            this.selectedReparationTypeValue !== GlassBreakageSecurityReparationType.None);
    }
    get shouldDisplayReparationInvoice() {
        return (this.selectedReparationTypeValue !== null &&
            this.selectedReparationTypeValue !== undefined &&
            this.selectedReparationTypeValue !== GlassBreakageSecurityReparationType.None &&
            this.selectedReparationByValue !== null &&
            this.selectedReparationByValue !== undefined &&
            this.selectedReparationByValue !== GlassBreakageSecurityReparationBy.Assistance);
    }
    get shouldDisplayReparationInvoiceAmount() {
        switch (this.selectedReparationByValue) {
            case GlassBreakageSecurityReparationBy.Professional:
                return true;
            case GlassBreakageSecurityReparationBy.Myself:
                return (this.selectedTemporaryReparationsInvoice !== null &&
                    this.selectedTemporaryReparationsInvoice.uploadedAppFiles.length > 0);
            default:
                return false;
        }
    }
    get isReparationInvoiceMandatory() {
        return this.selectedReparationByValue === GlassBreakageSecurityReparationBy.Professional;
    }
    get canValidateInvoice() {
        if (this.selectedTemporaryReparationsInvoice.uploadedAppFiles.length === 0 &&
            this.isReparationInvoiceMandatory) {
            return false;
        }
        if (this.selectedTemporaryReparationsInvoice.uploadedAppFiles.length > 0 &&
            this.selectedTemporaryReparationsInvoiceAmount === null) {
            return false;
        }
        return true;
    }
    get invoiceFileType() {
        return ClaimFileType.CraftsmanInvoice;
    }
    get answer() {
        if (this.selectedReparationTypeValue === null)
            return null;
        if (this.selectedReparationTypeValue === GlassBreakageSecurityReparationType.None ||
            this.selectedReparationByValue === null) {
            return this.$tc(GLASS_BREAKAGE_SECURITY_REPARATION_TYPE_ANSWERS[this.selectedReparationTypeValue]);
        }
        return `${this.$tc(GLASS_BREAKAGE_SECURITY_REPARATION_TYPE_ANSWERS[this.selectedReparationTypeValue])}&nbsp;${this.$tc(GLASS_BREAKAGE_SECURITY_REPARATION_BY_ANSWERS[this.selectedReparationByValue])}`;
    }
    onReparationTypeUpdate(temporaryReparationsType) {
        if (temporaryReparationsType === GlassBreakageSecurityReparationType.None) {
            this.updateAndSaveGlassBreakageDamageQualification({
                step: this.step,
                qualificationPayload: {
                    temporaryReparationsType,
                    temporaryReparationsBy: null,
                },
            });
        }
        else {
            this.updateGlassBreakageDamageQualification({
                qualificationPayload: { temporaryReparationsType },
            });
        }
    }
    onReparationByUpdate(temporaryReparationsBy) {
        if (temporaryReparationsBy === GlassBreakageSecurityReparationBy.Assistance) {
            this.updateAndSaveGlassBreakageDamageQualification({
                step: this.step,
                qualificationPayload: {
                    temporaryReparationsBy,
                    temporaryReparationsType: this.selectedReparationTypeValue,
                    temporaryReparationsInvoiceAmount: null,
                },
            });
        }
        else {
            this.updateGlassBreakageDamageQualification({
                qualificationPayload: {
                    temporaryReparationsBy,
                },
            });
        }
    }
    onFileCollectionUpdate(fileCollection) {
        this.invoiceFileCollection = fileCollection;
    }
    onTemporaryReparationsInvoiceAmountUpdate(temporaryReparationsInvoiceAmount) {
        this.updateGlassBreakageDamageQualification({
            qualificationPayload: {
                temporaryReparationsInvoiceAmount,
            },
        });
    }
    onValidate() {
        if (this.canValidateInvoice) {
            this.updateAndSaveGlassBreakageDamageQualification({
                step: this.step,
                qualificationPayload: {
                    temporaryReparationsType: this.selectedReparationTypeValue,
                    temporaryReparationsBy: this.selectedReparationByValue,
                    temporaryReparationsInvoice: this.selectedTemporaryReparationsInvoice,
                    temporaryReparationsInvoiceAmount: this.selectedTemporaryReparationsInvoiceAmount,
                },
            });
        }
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], GlassBreakageSecurityQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], GlassBreakageSecurityQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], GlassBreakageSecurityQuestion.prototype, "updateGlassBreakageDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], GlassBreakageSecurityQuestion.prototype, "updateAndSaveGlassBreakageDamageQualification", void 0);
GlassBreakageSecurityQuestion = __decorate([
    Component({
        components: {
            RadioPrimaryButton,
            QuestionPanel,
            UiButton,
            FileCollectionUpload,
            AmountInput,
        },
        props: QuestionProps,
    })
], GlassBreakageSecurityQuestion);
export default GlassBreakageSecurityQuestion;
