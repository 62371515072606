var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@shared/layout/layout';
import { Step } from '@shared/claim/steps';
import { BreakInDamageRepairPerson, BreakInDamageRepairStatus, CompensationMode, } from '@shared/types/api/claim';
import { breakInDamageTitle } from '@shared/breakInDamage/breakInDamageFields';
import ImmediateCompensationDetailCard from '@declaration/components/Compensation/ImmediateCompensationDetailCard.vue';
import { PERSONAL_PROPERTY_RECEIPT_TYPES_SUMUP_TRAD_KEY } from '@declaration/constants/personalPropertyConstants';
import { fileCollectionFilesCount } from '@shared/files/uploadedFiles';
const layout = namespace('layout');
const claim = namespace('claim');
const counting = namespace('counting');
const breakInDamage = namespace('breakInDamage');
let CompensationRemainingWorksConfirmation = class CompensationRemainingWorksConfirmation extends Vue {
    constructor() {
        super(...arguments);
        this.remainingWorkImage = require('@declaration/assets/images/compensation/reparation-di.svg');
        this.workDoneImage = require('@declaration/assets/images/compensation/repaired.svg');
        this.personalPropertyImage = require('@declaration/assets/images/compensation/objets-voles.svg');
        this.isLoading = false;
        this.isSaving = false;
        this.selfRepairImage = require('@declaration/assets/images/compensation/selfRepair.svg');
        this.partnerArtisanImage = require('@declaration/assets/images/compensation/partnerArtisan.svg');
        this.PERSONAL_PROPERTY_RECEIPT_TYPES_SUMUP_TRAD_KEY = PERSONAL_PROPERTY_RECEIPT_TYPES_SUMUP_TRAD_KEY;
    }
    get breakInWithRemainingWorkOrAesthetic() {
        return this.counting.breakInDamages.filter((breakInDamage) => breakInDamage.pendingCompensationMode !== CompensationMode.CompensationModeNone);
    }
    get hasRemainingByCertifiedArtisan() {
        return (this.counting.breakInDamages.filter((breakInDamage) => breakInDamage.needsRepair &&
            breakInDamage.pendingCompensationMode ===
                CompensationMode.CompensationModeCertifiedArtisan).length > 0);
    }
    get breakInWithInitialWorkDone() {
        return this.counting.breakInDamages.filter((breakInDamage) => breakInDamage.initialCompensationMode !== CompensationMode.CompensationModeNone);
    }
    get remaininWorkInfoMessage() {
        if (this.hasRemainingByCertifiedArtisan) {
            return 'compensation.remaining_works_confirmation.card.break_in_with_remaining_work.certified_artisan_info';
        }
        return null;
    }
    breakInDamageTitle(breakInDamage) {
        return breakInDamageTitle(breakInDamage, this.$tc.bind(this));
    }
    breakInWithRemainingWorkReason(breakInDamage) {
        const numberOfFilesUploaded = fileCollectionFilesCount(breakInDamage.pendingRepairFileCollection) +
            fileCollectionFilesCount(breakInDamage.repairFileCollection) +
            fileCollectionFilesCount(breakInDamage.pictureCollection);
        const fileUploaded = `${fileCollectionFilesCount(breakInDamage.pendingRepairFileCollection) > 0
            ? `${this.$tc('model.break_in_damage.documents.quote')}`
            : ''}${!(fileCollectionFilesCount(breakInDamage.repairFileCollection) > 0)
            ? ''
            : fileCollectionFilesCount(breakInDamage.pendingRepairFileCollection) > 0
                ? `, ${this.$tc('model.break_in_damage.documents.invoice')}`
                : `${this.$tc('model.break_in_damage.documents.invoice')}`}${!(fileCollectionFilesCount(breakInDamage.pictureCollection) > 0)
            ? ''
            : fileCollectionFilesCount(breakInDamage.pendingRepairFileCollection) > 0 ||
                fileCollectionFilesCount(breakInDamage.repairFileCollection) > 0
                ? `, ${fileCollectionFilesCount(breakInDamage.pictureCollection)} ${this.$tc('model.break_in_damage.documents.picture', fileCollectionFilesCount(breakInDamage.pictureCollection))}`
                : `${fileCollectionFilesCount(breakInDamage.pictureCollection)} ${this.$tc('model.break_in_damage.documents.picture', fileCollectionFilesCount(breakInDamage.pictureCollection))}`}`;
        switch (breakInDamage.pendingCompensationMode) {
            case CompensationMode.CompensationModeSelfRepair:
                return (this.$tc('compensation.remaining_works_confirmation.card.pending_repair.self_repair') +
                    (numberOfFilesUploaded > 0
                        ? ` - ${this.$tc('model.personal_property.documents', numberOfFilesUploaded)}: ${fileUploaded}`
                        : ''));
            case CompensationMode.CompensationModeCertifiedArtisan:
                return (this.$tc('compensation.remaining_works_confirmation.card.pending_repair.certified_artisan') +
                    (numberOfFilesUploaded > 0
                        ? ` - ${this.$tc('model.personal_property.documents', numberOfFilesUploaded)}: ${fileUploaded}`
                        : ''));
            case CompensationMode.CompensationModePecuniary:
                return (this.$tc('compensation.remaining_works_confirmation.card.pending_repair.own_artisan') +
                    (numberOfFilesUploaded > 0
                        ? `${this.$tc('model.personal_property.documents', numberOfFilesUploaded)}: ${fileUploaded}`
                        : ''));
            default:
                return 'error.snackbar.info';
        }
    }
    breakInWithInitialWorkReason(breakInDamage) {
        if (breakInDamage.hasBeenRepaired &&
            breakInDamage.hasBeenRepaired !== BreakInDamageRepairStatus.NotRepaired) {
            const numberOfFilesUploaded = fileCollectionFilesCount(breakInDamage.pendingRepairFileCollection) +
                fileCollectionFilesCount(breakInDamage.repairFileCollection) +
                fileCollectionFilesCount(breakInDamage.pictureCollection);
            const fileUploaded = `${this.$tc('model.personal_property.documents', numberOfFilesUploaded)}: ${fileCollectionFilesCount(breakInDamage.pendingRepairFileCollection) > 0
                ? `${this.$tc('model.break_in_damage.documents.quote')}`
                : ''}${!(fileCollectionFilesCount(breakInDamage.repairFileCollection) > 0)
                ? ''
                : fileCollectionFilesCount(breakInDamage.pendingRepairFileCollection) > 0
                    ? `, ${this.$tc('model.break_in_damage.documents.invoice')}`
                    : `${this.$tc('model.break_in_damage.documents.invoice')}`}${!(fileCollectionFilesCount(breakInDamage.pictureCollection) > 0)
                ? ''
                : fileCollectionFilesCount(breakInDamage.pendingRepairFileCollection) > 0 ||
                    fileCollectionFilesCount(breakInDamage.repairFileCollection) > 0
                    ? `, ${fileCollectionFilesCount(breakInDamage.pictureCollection)} ${this.$tc('model.break_in_damage.documents.picture', fileCollectionFilesCount(breakInDamage.pictureCollection))}`
                    : `${fileCollectionFilesCount(breakInDamage.pictureCollection)} ${this.$tc('model.break_in_damage.documents.picture', fileCollectionFilesCount(breakInDamage.pictureCollection))}`}`;
            const reason = [];
            if (breakInDamage.hasBeenRepaired === BreakInDamageRepairStatus.Repaired) {
                reason.push('compensation.break-in-damage.confirmation.card.repaired');
            }
            else if (breakInDamage.hasBeenRepaired === BreakInDamageRepairStatus.RepairedButNeedsMoreWork) {
                reason.push('compensation.break-in-damage.confirmation.card.repaired-but-needs-more-work');
            }
            switch (breakInDamage.repairedBy) {
                case BreakInDamageRepairPerson.Myself:
                    reason.push('compensation.break-in-damage.confirmation.card.repaired-by.myself');
                    break;
                case BreakInDamageRepairPerson.Artisan:
                    reason.push('compensation.break-in-damage.confirmation.card.repaired-by.artisan');
                    break;
                case BreakInDamageRepairPerson.Assistance:
                    reason.push('compensation.break-in-damage.confirmation.card.repaired-by.assistance');
                    break;
            }
            reason.push(fileUploaded);
            return reason;
        }
        return 'error.snackbar.info';
    }
    personalPropertyTitle(personalProperty) {
        return personalProperty.thirdPartyProductInfo
            ? personalProperty.thirdPartyProductInfo.name
            : personalProperty.designation;
    }
    personalPropertyProofDetails(personalProperty) {
        var _a, _b;
        if ((_a = personalProperty.receipt) === null || _a === void 0 ? void 0 : _a.type) {
            return PERSONAL_PROPERTY_RECEIPT_TYPES_SUMUP_TRAD_KEY[(_b = personalProperty.receipt) === null || _b === void 0 ? void 0 : _b.type];
        }
    }
    async validate() {
        this.isLoading = true;
        await this.updateAndSaveClaim({
            claimPayload: {
                isCompensationOptionAccepted: true,
            },
            step: Step.CompensationRemainingWorksConfirmation,
        });
        this.isLoading = false;
    }
};
__decorate([
    layout.State('color')
], CompensationRemainingWorksConfirmation.prototype, "color", void 0);
__decorate([
    layout.State('isMobile')
], CompensationRemainingWorksConfirmation.prototype, "isMobile", void 0);
__decorate([
    layout.Getter('isDesktop')
], CompensationRemainingWorksConfirmation.prototype, "isDesktop", void 0);
__decorate([
    counting.Getter('personalPropertiesRefund')
], CompensationRemainingWorksConfirmation.prototype, "personalPropertiesRefund", void 0);
__decorate([
    counting.Getter('breakInDamagesRefund')
], CompensationRemainingWorksConfirmation.prototype, "breakInDamagesRefund", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], CompensationRemainingWorksConfirmation.prototype, "updateAndSaveClaim", void 0);
__decorate([
    counting.State('counting')
], CompensationRemainingWorksConfirmation.prototype, "counting", void 0);
__decorate([
    breakInDamage.State('damages')
], CompensationRemainingWorksConfirmation.prototype, "damages", void 0);
CompensationRemainingWorksConfirmation = __decorate([
    Component({
        components: {
            UiButton,
            ImmediateCompensationDetailCard,
        },
        meta: {
            close: quitClaimDeclarationCloseCallback,
            title: 'page.title.compensation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            goBack: null,
        },
    })
], CompensationRemainingWorksConfirmation);
export default CompensationRemainingWorksConfirmation;
