var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Model } from 'vue-property-decorator';
import { UiIcon } from '@claims-ia/design-system';
import { FormElement } from './UI/FormElement';
let NumberInput = class NumberInput extends FormElement {
    get inputValue() {
        var _a;
        return (_a = this.value) !== null && _a !== void 0 ? _a : 0;
    }
    set inputValue(value) {
        this.$emit('value-change', value);
    }
};
__decorate([
    Model('value-change', { type: Number, default: null })
], NumberInput.prototype, "value", void 0);
__decorate([
    Prop({ type: [Number, String], default: null })
], NumberInput.prototype, "max", void 0);
__decorate([
    Prop({ type: [Number, String], default: null })
], NumberInput.prototype, "min", void 0);
NumberInput = __decorate([
    Component({
        components: {
            UiIcon,
        },
    })
], NumberInput);
export default NumberInput;
