var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { goBackOneStep } from '@declaration/helpers/layoutMetaCallback';
const qualification = namespace('qualification');
let UnderConstructionQuestion = class UnderConstructionQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/miscellaneous/maintenance.svg');
    }
    get title() {
        return this.$tc('under-construction.title', 0, { step: this.step });
    }
    get description() {
        return this.$tc('under-construction.description', 0, { trigram: this.trigram });
    }
    get answer() {
        return null;
    }
    goBack() {
        goBackOneStep(this);
    }
};
__decorate([
    qualification.State('typeTrigram')
], UnderConstructionQuestion.prototype, "trigram", void 0);
UnderConstructionQuestion = __decorate([
    Component({
        components: { QuestionPanel, UiButton },
        props: QuestionProps,
    })
], UnderConstructionQuestion);
export default UnderConstructionQuestion;
