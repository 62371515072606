var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
import { ClaimFileType } from '@shared/types/file';
import { QuestionComponent, QuestionProps } from '@declaration/question';
const claim = namespace('claim');
const qualification = namespace('qualification');
let ProfessionalInterventionInvoiceQuestion = class ProfessionalInterventionInvoiceQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.invoice = null;
        this.companyName = null;
        this.interventionDate = null;
        this.maxDate = new Date();
        this.ClaimFileType = ClaimFileType;
    }
    created() {
        this.invoice = this.electricalDamageQualification.professionalInterventionInvoice;
    }
    onFileCollectionUpdate(fileCollection) {
        this.invoice = fileCollection;
    }
    updateClaimWithInfo() {
        this.updateAndSaveQualification({
            qualificationPayload: {
                professionalInterventionInvoice: this.invoice,
            },
            step: this.step,
        });
    }
    async updateClaimWithNoInfo() {
        await Promise.all([
            this.updateAndSaveQualification({
                qualificationPayload: {
                    professionalInterventionInvoice: emptyUploadedFileCollection(),
                },
                step: this.step,
            }),
        ]);
    }
    get answer() {
        if (this.electricalDamageQualification.professionalInterventionInvoice === null) {
            return this.$tc('claim.qualification.electrical.professional_intervention_invoice.sumup.no_invoice');
        }
        return this.$tc('claim.qualification.electrical.professional_intervention_invoice.sumup.with_invoice');
    }
};
__decorate([
    claim.State('claim')
], ProfessionalInterventionInvoiceQuestion.prototype, "claim", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], ProfessionalInterventionInvoiceQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], ProfessionalInterventionInvoiceQuestion.prototype, "electricalDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], ProfessionalInterventionInvoiceQuestion.prototype, "updateAndSaveQualification", void 0);
ProfessionalInterventionInvoiceQuestion = __decorate([
    Component({
        components: { QuestionPanel, UiButton, FileCollectionUpload },
        props: QuestionProps,
    })
], ProfessionalInterventionInvoiceQuestion);
export default ProfessionalInterventionInvoiceQuestion;
