import { render, staticRenderFns } from "./WindowOtherDamages.vue?vue&type=template&id=0125c57a&scoped=true"
import script from "./WindowOtherDamages.vue?vue&type=script&lang=ts"
export * from "./WindowOtherDamages.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0125c57a",
  null
  
)

export default component.exports