var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { UiIcon } from '@claims-ia/design-system';
import { namespace } from 'vuex-class';
import DamageFields from '@declaration/components/damage/DamageFields.vue';
import { ModelFieldsProjection } from '@shared/modelField/modelField';
import { thirdPartyFields, thirdPartyTitle } from '@shared/thirdParty/thirdPartyFields';
const thirdParty = namespace('thirdParty');
const layout = namespace('layout');
const qualification = namespace('qualification');
let ThirdPartyCard = class ThirdPartyCard extends Vue {
    constructor() {
        super(...arguments);
        this.isDeleting = false;
    }
    onDelete() {
        this.confirmDelete(this.thirdParty.iri, this.deleteThirdParty);
    }
    async deleteThirdParty() {
        this.isDeleting = true;
        await this.deleteThirdPartyByIndex(this.thirdPartyIndex);
        this.isDeleting = false;
    }
    get isComplete() {
        return !!this.thirdParty.iri;
    }
    get title() {
        return thirdPartyTitle(this.thirdParty);
    }
    get editBtnLabel() {
        return this.isComplete
            ? this.$tc('claim.declaration.section.third_party.contact.edit')
            : this.$tc('claim.declaration.third_party.add_details');
    }
    get modelFields() {
        return thirdPartyFields(this.thirdParty, {
            type: this.typeTrigram,
            projection: ModelFieldsProjection.Declaration,
            translate: this.$tc.bind(this),
            edition: false,
            vehicle: false,
        });
    }
};
__decorate([
    layout.State('color')
], ThirdPartyCard.prototype, "color", void 0);
__decorate([
    thirdParty.Action('deleteThirdPartyByIndex')
], ThirdPartyCard.prototype, "deleteThirdPartyByIndex", void 0);
__decorate([
    qualification.State('typeTrigram')
], ThirdPartyCard.prototype, "typeTrigram", void 0);
ThirdPartyCard = __decorate([
    Component({
        props: {
            thirdParty: {
                type: Object,
                required: true,
            },
            thirdPartyIndex: {
                type: Number,
                required: true,
            },
            confirmDelete: {
                type: Function,
                required: true,
            },
        },
        components: {
            DamageFields,
            UiIcon,
        },
    })
], ThirdPartyCard);
export default ThirdPartyCard;
