var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { UiIcon } from '@claims-ia/design-system';
import { namespace } from 'vuex-class';
import { layoutMiddlewares } from '@declaration/middleware/middlewares';
import Maintenance from '@declaration/components/declaration/pages/maintenance.vue';
import { CLAIM_TYPE_IMAGES } from '@shared/layout/layout';
import { APPOINTMENT_TYPE_TRAD_KEY } from '@declaration/constants/appointmentConstants';
import { createSerializationQueryParam, SERIALIZATION_KEY_LIST, } from '@shared/constants/serializationKeys';
import { ApiResourceName } from '@shared/types/api/api';
import { ClaimStatus, ClaimTypeTrigram } from '@shared/types/api/claim';
import { formatDate } from '@shared/utils/date';
import { DeclarationPage } from '@declaration/pages';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
const claim = namespace('claim');
const pages = namespace('pages');
var ClaimTrackingStatus;
(function (ClaimTrackingStatus) {
    ClaimTrackingStatus["Opened"] = "opened";
    ClaimTrackingStatus["Closed"] = "closed";
    ClaimTrackingStatus["DocumentPending"] = "invoice_pending";
    ClaimTrackingStatus["RealEstateQuoteFilePending"] = "real_estate_quote_file_pending";
})(ClaimTrackingStatus || (ClaimTrackingStatus = {}));
function compareClaimDate({ claim: { updatedAt: a } }, { claim: { updatedAt: b } }) {
    if (!a && !b) {
        return 0;
    }
    if (!a) {
        return -1;
    }
    if (!b) {
        return 1;
    }
    return new Date(b).getTime() - new Date(a).getTime();
}
const TRACKING_STATUS_TRAD_KEY = {
    [ClaimTrackingStatus.Opened]: 'track.claim.tracking.opened',
    [ClaimTrackingStatus.Closed]: 'track.claim.tracking.closed',
    [ClaimTrackingStatus.DocumentPending]: 'track.claim.tracking.pending_document',
    [ClaimTrackingStatus.RealEstateQuoteFilePending]: 'track.claim.tracking.real_estate_quote_file_pending',
};
const CLAIM_STATUS_TO_TRACKING_STATUS_MAPPING = {
    [ClaimStatus.Opened]: ClaimTrackingStatus.Opened,
    [ClaimStatus.DamagesValidated]: ClaimTrackingStatus.Opened,
    [ClaimStatus.DamagesPreValidated]: ClaimTrackingStatus.Opened,
    [ClaimStatus.ImmediatePayoutPending]: ClaimTrackingStatus.Opened,
    [ClaimStatus.DelayedPayoutPending]: ClaimTrackingStatus.Opened,
    [ClaimStatus.Completed]: ClaimTrackingStatus.Closed,
    [ClaimStatus.CompensationDocumentPending]: ClaimTrackingStatus.DocumentPending,
    [ClaimStatus.DeclarationDocumentPending]: ClaimTrackingStatus.DocumentPending,
    [ClaimStatus.ExpertAppointmentConfirmed]: ClaimTrackingStatus.Closed,
    [ClaimStatus.ArtisanAppointmentConfirmed]: ClaimTrackingStatus.Closed,
    [ClaimStatus.ArtisanCounterOfferRejected]: ClaimTrackingStatus.Closed,
    [ClaimStatus.NotCovered]: ClaimTrackingStatus.Closed,
    [ClaimStatus.NotCoveredDateIntervalOver]: ClaimTrackingStatus.Closed,
    [ClaimStatus.NoAdministrativeFollowUp]: ClaimTrackingStatus.Closed,
    [ClaimStatus.TransferToPae]: ClaimTrackingStatus.Closed,
    [ClaimStatus.NoSuitableExpertiseSchedule]: ClaimTrackingStatus.Closed,
    [ClaimStatus.Expert]: ClaimTrackingStatus.Closed,
    [ClaimStatus.ExpertPending]: ClaimTrackingStatus.Closed,
    [ClaimStatus.REN]: ClaimTrackingStatus.Closed,
    [ClaimStatus.RENPending]: ClaimTrackingStatus.Closed,
    [ClaimStatus.ClientPending]: ClaimTrackingStatus.Closed,
    [ClaimStatus.GoPay]: ClaimTrackingStatus.Closed,
    [ClaimStatus.Closed]: ClaimTrackingStatus.Closed,
    [ClaimStatus.ClosureConfirmed]: ClaimTrackingStatus.Closed,
    [ClaimStatus.Created]: ClaimTrackingStatus.Opened,
};
const DELETION_ENABLED_CLAIM_STATUS = [ClaimTrackingStatus.Opened];
let ClaimsList = class ClaimsList extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.claims = [];
        this.claimInDeletionId = null;
        this.isDeletingClaim = false;
        this.claimInTrackingId = null;
        this.isTrackingClaim = false;
        this.appointmentTradKey = APPOINTMENT_TYPE_TRAD_KEY;
        this.formatDate = formatDate;
        this.DeclarationPage = DeclarationPage;
    }
    appointmentsArray(claim) {
        const { artisanAppointment, expertAppointment } = claim;
        return [artisanAppointment, expertAppointment].filter(Boolean);
    }
    async created() {
        try {
            const response = await this.$axios.get(`${apiResourcePath(ApiResourceName.Claims)}?exists[contract]=true&order[createdAt]=desc&exists[type]=true&${createSerializationQueryParam(SERIALIZATION_KEY_LIST)}`);
            this.claims = response.data['hydra:member']
                .map((claim) => ({
                claim,
                tracking: CLAIM_STATUS_TO_TRACKING_STATUS_MAPPING[claim.status] || ClaimTrackingStatus.Closed,
            }))
                .sort(compareClaimDate);
        }
        catch (err) {
            this.$store.dispatch('error/add', this.$tc('error.snackbar.info'));
        }
        this.isLoading = false;
    }
    image(claim) {
        return claim.type
            ? CLAIM_TYPE_IMAGES[claim.type.trigram]
            : CLAIM_TYPE_IMAGES[ClaimTypeTrigram.WaterDamage];
    }
    type(claim) {
        return claim.type ? this.$tc(`claim.type.${claim.type.trigram}`) : '';
    }
    date(claim) {
        return formatDate(claim.createdAt);
    }
    city(claim) {
        return `${claim.contract.specialConditions.zipcode} ${claim.contract.specialConditions.city}`;
    }
    address(claim) {
        return claim.contract.specialConditions.address;
    }
    statusTradKey(tracking) {
        return TRACKING_STATUS_TRAD_KEY[tracking];
    }
    info(claim) {
        if (claim.type) {
            return `${this.type(claim)} - ${this.date(claim)}`;
        }
        return this.date(claim);
    }
    isDeletable(tracking) {
        return DELETION_ENABLED_CLAIM_STATUS.includes(tracking);
    }
    async trackClaim(_tracking, claim) {
        this.isTrackingClaim = true;
        this.claimInTrackingId = claim.id;
        this.resetStore();
        try {
            await this.fetchClaim({ id: claim.id, shouldUpdatePage: true });
            this.$goToLocalePath('/declaration');
        }
        catch (_a) {
            this.$store.dispatch('error/add', this.$tc('error.snackbar.info'));
        }
        this.isTrackingClaim = false;
        this.claimInTrackingId = null;
    }
    openDeletionConfirm(claimId) {
        this.$buefy.dialog.confirm({
            animation: 'fade',
            title: this.$tc('claim.delete_modal.title'),
            message: this.$tc('claim.delete_modal.message'),
            cancelText: this.$tc('claim.delete_modal.cancel'),
            confirmText: this.$tc('claim.delete_modal.confirm'),
            onCancel: () => {
                // No-op
            },
            onConfirm: () => this.confirmDeletion(claimId),
        });
    }
    async confirmDeletion(claimId) {
        this.isDeletingClaim = true;
        try {
            await this.$axios.delete(claimId);
            this.claims = this.claims.filter((claim) => claim.claim.id !== claimId);
        }
        catch (_a) {
            this.$store.dispatch('error/add', this.$tc('error.snackbar.info'));
        }
        this.isDeletingClaim = false;
    }
};
__decorate([
    claim.Action('resetStore')
], ClaimsList.prototype, "resetStore", void 0);
__decorate([
    claim.Action('fetchClaim')
], ClaimsList.prototype, "fetchClaim", void 0);
__decorate([
    pages.Getter('currentPage')
], ClaimsList.prototype, "currentPage", void 0);
ClaimsList = __decorate([
    Component({
        middleware: layoutMiddlewares('authenticated', 'forbidMagicLinkAuth'),
        components: { Maintenance, UiIcon },
    })
], ClaimsList);
export default ClaimsList;
