var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UiIcon } from '@claims-ia/design-system';
import { ChoiceUsage } from '../../../shared/choice';
let ClaimsButton = class ClaimsButton extends Vue {
    onClick(e) {
        if (this.isDisabled)
            return this.$emit('on-disabled-button-click', e);
        this.$emit('on-button-click', e);
    }
    get hasIcon() {
        return this.icon !== null;
    }
    get isIconDisplayed() {
        return this.hasIcon && this.displayIcon;
    }
    get usageModeClass() {
        return `claims-button__container--is-${this.usage}`;
    }
    get claimsTypeClass() {
        return this.claimsButtonType === 'primaryButton'
            ? 'primary-button__container'
            : 'validation-button__container';
    }
    get computedClasses() {
        const buttonClasses = [];
        if (this.isInQuestionPanel) {
            buttonClasses.push('claims-button__container--question-panel');
        }
        if (this.isOutlined) {
            buttonClasses.push('claims-button__container--outlined');
        }
        if (this.isDisabled) {
            buttonClasses.push('claims-button__container--disabled');
        }
        if (this.isLoading) {
            buttonClasses.push('claims-button__container--loading');
        }
        if (this.isOutlined && this.isInQuestionPanel) {
            buttonClasses.push('claims-button__container--outlined--question-panel');
        }
        if (this.isDisabled && this.isInQuestionPanel) {
            buttonClasses.push('claims-button__container--disabled--question-panel');
        }
        if (this.isLoading && this.isInQuestionPanel) {
            buttonClasses.push('claims-button__container--loading--question-panel');
        }
        if (this.isRadio) {
            buttonClasses.push('claims-button__container--radio');
        }
        if (this.isCheckbox) {
            buttonClasses.push('claims-button__container--checkbox');
        }
        if (this.isSelected) {
            buttonClasses.push('claims-button__container--selected');
        }
        if (this.isHovered) {
            buttonClasses.push('claims-button__container--hovered');
        }
        if (this.isNotSelectedInButtonGroup) {
            buttonClasses.push('claims-button__container--not-selected-in-group');
        }
        if (this.hasIcon) {
            buttonClasses.push('claims-button__container--has-icon');
        }
        if (this.isIconDisplayed) {
            buttonClasses.push('claims-button__container--is-icon-displayed');
        }
        buttonClasses.push(this.claimsTypeClass);
        buttonClasses.push(this.usageModeClass);
        return buttonClasses;
    }
    get checkboxIconClass() {
        switch (this.usage) {
            case ChoiceUsage.Alert:
                return 'warning-triangle';
            default:
                return 'check';
        }
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], ClaimsButton.prototype, "isLoading", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ClaimsButton.prototype, "isDisabled", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ClaimsButton.prototype, "isOutlined", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ClaimsButton.prototype, "isInQuestionPanel", void 0);
__decorate([
    Prop({ type: String, required: true })
], ClaimsButton.prototype, "text", void 0);
__decorate([
    Prop({ type: String, default: 'button' })
], ClaimsButton.prototype, "type", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ClaimsButton.prototype, "isSelected", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ClaimsButton.prototype, "isHovered", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ClaimsButton.prototype, "isNotSelectedInButtonGroup", void 0);
__decorate([
    Prop({ type: String, default: null })
], ClaimsButton.prototype, "icon", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], ClaimsButton.prototype, "displayIcon", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ClaimsButton.prototype, "isRadio", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ClaimsButton.prototype, "isCheckbox", void 0);
__decorate([
    Prop({ type: String, default: 'generic' })
], ClaimsButton.prototype, "usage", void 0);
__decorate([
    Prop({ type: String, default: 'validationButton' })
], ClaimsButton.prototype, "claimsButtonType", void 0);
ClaimsButton = __decorate([
    Component({ components: { UiIcon } })
], ClaimsButton);
export default ClaimsButton;
