var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import axios from 'axios';
import Component from 'vue-class-component';
import { UiButton } from '@claims-ia/design-system';
import { namespace } from 'vuex-class';
import { layoutMiddlewares } from '@declaration/middleware/middlewares';
import Maintenance from '@declaration/components/declaration/pages/maintenance.vue';
import { DeclarationPage } from '@declaration/pages';
const pages = namespace('pages');
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.email = '';
        this.password = '';
        this.houseImage = require('@declaration/assets/images/housing-type/house.svg');
        this.DeclarationPage = DeclarationPage;
    }
    mounted() {
        this.$forceUpdate();
    }
    get validEmail() {
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/i.test(this.email);
    }
    get validPassword() {
        return this.password.length > 2;
    }
    get canLogin() {
        return this.validEmail && this.validPassword;
    }
    async postLogin() {
        if (!this.canLogin) {
            this.toast('Veuillez renseigner votre email et mot de passe.', 'is-info');
            return;
        }
        this.loading = true;
        try {
            await this.$store.dispatch('authentication/login', {
                email: this.email.toLowerCase(),
                password: this.password,
            });
            await this.$store.dispatch('user/getUserInfo');
            this.$goToLocalePath('index');
        }
        catch (error) {
            if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                this.toast(this.$tc('authentication.bad_credentials'), 'is-danger');
            }
            else {
                this.toast('Une erreur est survenue', 'is-danger');
            }
            this.loading = false;
        }
    }
    toast(message, type) {
        this.$buefy.toast.open({
            message,
            type,
            position: 'is-bottom',
        });
    }
};
__decorate([
    pages.Getter('currentPage')
], Login.prototype, "currentPage", void 0);
Login = __decorate([
    Component({
        middleware: layoutMiddlewares('notAuthenticated'),
        components: {
            UiButton,
            Maintenance,
        },
        meta: {
            goBack: null,
        },
    })
], Login);
export default Login;
