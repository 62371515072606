var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import { RobberyType, ClaimTypeTrigram } from '@shared/types/api/claim';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { Step } from '@shared/claim/steps';
const claim = namespace('claim');
const pages = namespace('pages');
const breakInDamage = namespace('breakInDamage');
const personalProperty = namespace('personalProperty');
const qualification = namespace('qualification');
let DamagesSynthesisQuestion = class DamagesSynthesisQuestion extends QuestionComponent {
    get hasBreakInDamages() {
        return this.breakInDamages.length > 0;
    }
    get hasPersonalProperties() {
        return this.personalProperties.length > 0;
    }
    get hasNoDamage() {
        return !this.hasBreakInDamages && !this.hasPersonalProperties;
    }
    get type() {
        if (this.hasNoDamage) {
            return 'no_damage';
        }
        if (!this.hasBreakInDamages) {
            // Vol sans effraction
            return 'personal_properties';
        }
        if (!this.hasPersonalProperties) {
            // Tentative de vol avec effraction
            return 'break_in_damages';
        }
        // Vol avec effraction
        return 'both_damages';
    }
    get journey() {
        switch (this.claimTypeTrigram) {
            case 'VOL':
                return 'robbery';
            default:
                return 'default';
        }
    }
    get title() {
        return this.$tc(`claim.qualification.${this.journey}.synthesis.${this.type}.title`);
    }
    get subTitle() {
        return this.$tc(`claim.qualification.${this.journey}.synthesis.${this.type}.description`);
    }
    get image() {
        return this.hasNoDamage
            ? require('@declaration/assets/images/documents/hors-garantie.svg')
            : null;
    }
    fixDeclaration() {
        if (this.qualification.type === RobberyType.AnyRobbery) {
            this.selectActiveStep(Step.BreakInDamages);
        }
        else if (this.claimTypeTrigram === ClaimTypeTrigram.Electric) {
            this.selectActiveStep(Step.ElectricalDamageType);
        }
        else {
            this.selectActiveStep(Step.PersonalProperties);
        }
    }
    finalizeDeclaration() {
        this.updateAndSaveClaimStep(this.step);
    }
    get answer() {
        return null;
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], DamagesSynthesisQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    claim.Action('updateAndSaveClaimStep')
], DamagesSynthesisQuestion.prototype, "updateAndSaveClaimStep", void 0);
__decorate([
    qualification.State('typeTrigram')
], DamagesSynthesisQuestion.prototype, "claimTypeTrigram", void 0);
__decorate([
    qualification.State('qualification')
], DamagesSynthesisQuestion.prototype, "qualification", void 0);
__decorate([
    pages.Action('selectActiveStep')
], DamagesSynthesisQuestion.prototype, "selectActiveStep", void 0);
__decorate([
    breakInDamage.State('damages')
], DamagesSynthesisQuestion.prototype, "breakInDamages", void 0);
__decorate([
    personalProperty.State('damages')
], DamagesSynthesisQuestion.prototype, "personalProperties", void 0);
DamagesSynthesisQuestion = __decorate([
    Component({
        components: { QuestionPanel, UiButton },
        props: QuestionProps,
    })
], DamagesSynthesisQuestion);
export default DamagesSynthesisQuestion;
