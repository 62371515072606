var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import ElectricalDamagesList from './ElectricalDamagesList.vue';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { ElectricalDamageType } from '@shared/types/api/electricalDamage';
import { ELECTRICAL_DAMAGE_TYPES_TRAD_KEYS } from '@shared/constants/electricalDamageConstants';
import ElectricalDamageModal from '@declaration/components/electricalDamage/ElectricalDamageModal.vue';
import { fileCollectionFilesCount } from '@shared/files/uploadedFiles';
import formatAmount from '@shared/utils/formatAmount';
import { Step } from '@shared/claim/steps';
const electricalDamage = namespace('electricalDamage');
let GlassDamagesQuestion = class GlassDamagesQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.selectedChangeKey = null;
        this.isImmovablePropertyModalOpen = false;
        this.isDeleting = false;
    }
    get damagesCount() {
        return this.electricalDamages.length;
    }
    get title() {
        return this.$tc('claim.damage.electrical_damage.question.title');
    }
    get validationButtonText() {
        return this.$tc('claim.damage.electrical_damage.question.validate', this.damagesCount);
    }
    get canValidate() {
        return this.damagesCount > 0;
    }
    get answerTitle() {
        return this.$tc('claim.damage.electrical_damage.question.answer.title', this.damagesCount);
    }
    getDamageTitle(damage) {
        let label = this.$tc(ELECTRICAL_DAMAGE_TYPES_TRAD_KEYS[damage.type]);
        if (damage.type === ElectricalDamageType.Other && damage.designation) {
            label += ` (${damage.designation})`;
        }
        else {
            const similarDamages = this.electricalDamages.filter(({ type }) => type === damage.type);
            if (similarDamages.length > 1) {
                const index = similarDamages.findIndex(({ id }) => id === damage.id);
                label += ` n°${index + 1}`;
            }
        }
        return label;
    }
    addNewDamage() {
        this.selectedChangeKey = null;
        this.isImmovablePropertyModalOpen = true;
    }
    deleteDamageHandler(electricalDamage) {
        this.electricalDamage = electricalDamage;
        this.confirmDelete(this.electricalDamage.id, this.deleteElectricalDamage);
    }
    async deleteElectricalDamage() {
        this.isDeleting = true;
        if (this.electricalDamage.id) {
            this.deleteDamage(this.electricalDamage.id);
            await this.saveChanges({ step: Step.ElectricalDamagesDetails });
        }
        else {
            this.deleteDamage(null);
        }
        this.isDeleting = false;
    }
    confirmDelete(iri, deleteCallback) {
        if (iri) {
            this.$buefy.dialog.confirm({
                animation: 'fade',
                message: this.$tc('claim.damage.break_in_damages.form.delete.modal.text'),
                cancelText: this.$tc('claim.damage.break_in_damages.form.delete.modal.cancel'),
                confirmText: this.$tc('claim.damage.break_in_damages.form.delete.modal.confirm'),
                onConfirm: deleteCallback,
            });
        }
        else {
            deleteCallback();
        }
    }
    closeModal() {
        this.resetChange(this.selectedChangeKey);
        this.isImmovablePropertyModalOpen = false;
    }
    getDamageDocuments(damage) {
        // Justificatif(s) d'achat
        let receiptFiles = fileCollectionFilesCount(damage.purchaseInvoice);
        // Attestation(s) d'irréparabilité
        let irreparabilityCertificateFiles = fileCollectionFilesCount(damage.irreparabilityCertificate);
        // Devis de réparation
        let repairQuoteFiles = fileCollectionFilesCount(damage.repairQuote);
        const values = [];
        if (receiptFiles > 0) {
            // Si l'assuré ne connait pas le prix ou la date d'achat, on ignore les fichiers
            if (damage.purchaseInfo === false) {
                receiptFiles = 0;
            }
            else {
                values.push(this.$tc('claim.damage.justificative.receipt', receiptFiles));
            }
        }
        if (irreparabilityCertificateFiles > 0) {
            // Si le bien est réparable, on ignore les fichiers
            if (damage.repairable === true) {
                irreparabilityCertificateFiles = 0;
            }
            else {
                values.push(this.$tc('claim.damage.justificative.irreparability_certificate', irreparabilityCertificateFiles));
            }
        }
        if (repairQuoteFiles > 0) {
            // Si le bien est irréparable, on ignore les fichiers
            if (damage.repairable === false) {
                repairQuoteFiles = 0;
            }
            else {
                values.push(this.$tc('claim.damage.justificative.reparation_invoice', repairQuoteFiles));
            }
        }
        const label = this.$tc('claim.damage.justificative.title', receiptFiles + irreparabilityCertificateFiles + repairQuoteFiles);
        const value = values.length ? values.join(', ') : this.$tc('claim.damage.justificative.none');
        return `${label} : ${value}`;
    }
    getRepairStatus(damage) {
        if (typeof damage.repairable === 'boolean') {
            return `${this.$tc('claim.damage.repairable.title')} : ${this.$tc(damage.repairable ? 'yes' : 'no')}`;
        }
        return null;
    }
    getRepairAmount(damage) {
        if (damage.repairable && damage.repairAmount) {
            return `${this.$tc('claim.damage.repairable.amount')} : ${formatAmount(damage.repairAmount / 100)}`;
        }
        return null;
    }
    selectImmovablePropertyAndOpenModal(damage) {
        this.selectedChangeKey = damage.id;
        this.isImmovablePropertyModalOpen = true;
    }
    confirmImmovablePropertyDelete(iri, deleteCallback) {
        if (iri) {
            this.$buefy.dialog.confirm({
                animation: 'fade',
                message: this.$tc('claim.damage.break_in_damages.form.delete.modal.text'),
                cancelText: this.$tc('claim.damage.break_in_damages.form.delete.modal.cancel'),
                confirmText: this.$tc('claim.damage.break_in_damages.form.delete.modal.confirm'),
                onConfirm: deleteCallback,
            });
        }
        else {
            deleteCallback();
        }
    }
    askToComplete() {
        this.$buefy.toast.open({
            message: this.$tc('claim.damage.electrical_damage.question.validate.error.ask_to_complete'),
            type: 'is-danger',
            position: 'is-bottom',
        });
    }
    async handleValidation() {
        try {
            this.isSaving = true;
            await this.validateElectricalDamagesStep();
        }
        catch (_a) {
        }
        finally {
            this.isSaving = false;
        }
    }
};
__decorate([
    electricalDamage.State('damages')
], GlassDamagesQuestion.prototype, "electricalDamages", void 0);
__decorate([
    electricalDamage.Action('validateElectricalDamagesStep')
], GlassDamagesQuestion.prototype, "validateElectricalDamagesStep", void 0);
__decorate([
    electricalDamage.Action('resetChanges')
], GlassDamagesQuestion.prototype, "resetChange", void 0);
__decorate([
    electricalDamage.Action('saveChanges')
], GlassDamagesQuestion.prototype, "saveChanges", void 0);
__decorate([
    electricalDamage.Action('deleteDamage')
], GlassDamagesQuestion.prototype, "deleteDamage", void 0);
GlassDamagesQuestion = __decorate([
    Component({
        components: { QuestionPanel, UiButton, ElectricalDamagesList, ElectricalDamageModal },
        props: QuestionProps,
    })
], GlassDamagesQuestion);
export default GlassDamagesQuestion;
