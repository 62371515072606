var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import QuitApplicationButton from '@declaration/components/UI/QuitApplicationButton.vue';
const layout = namespace('layout');
const claim = namespace('claim');
let TitleImageDescriptionLayout = class TitleImageDescriptionLayout extends Vue {
};
__decorate([
    layout.State('color')
], TitleImageDescriptionLayout.prototype, "color", void 0);
__decorate([
    claim.State('claim')
], TitleImageDescriptionLayout.prototype, "claim", void 0);
TitleImageDescriptionLayout = __decorate([
    Component({
        components: {
            UiButton,
            QuitApplicationButton,
        },
        props: {
            title: {
                type: String,
                required: true,
            },
            isTitleSmall: {
                type: Boolean,
                default: false,
            },
            imageSrc: {
                type: String,
                required: true,
            },
            imagePadding: {
                type: Boolean,
                default: false,
            },
            continuation: {
                type: String,
                default: null,
            },
            primaryButtonText: {
                type: String,
                default: null,
            },
            primaryButtonAction: {
                type: Function,
                default: null,
            },
            primaryButtonDataTest: {
                type: String,
                default: 'primary-button',
            },
            primaryButtonLoading: {
                type: Boolean,
                default: false,
            },
            primaryButtonDisabled: {
                type: Boolean,
                default: false,
            },
            secondaryButtonText: {
                type: String,
                default: null,
            },
            secondaryButtonAction: {
                type: Function,
                default: null,
            },
            secondaryButtonDataTest: {
                type: String,
                default: 'secondary-button',
            },
            secondaryButtonClass: {
                type: String,
                default: null,
            },
            displayTitle: {
                type: Boolean,
                default: true,
            },
            displayCaseNumber: {
                type: Boolean,
                default: true,
            },
            displayButton: {
                type: Boolean,
                default: true,
            },
        },
    })
], TitleImageDescriptionLayout);
export default TitleImageDescriptionLayout;
