var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { getProtectionMeasure } from '@shared/contract/protectionMeasures';
const claim = namespace('claim');
const contract = namespace('contract');
const qualification = namespace('qualification');
let RobberyProtectionMeasuresQuestion = class RobberyProtectionMeasuresQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.isSaving = false;
        this.areProtectionMeansLoaded = false;
        this.clickedProtectionMeans = new Set();
    }
    get questionTitle() {
        return this.$tc('claim.qualification.robbery.protection_means.title');
    }
    get protectionMeasuresChoices() {
        return (this.allProtectionMeasures ? this.allProtectionMeasures.measures : []).map((mean) => ({
            value: mean.id,
            label: mean.designation,
        }));
    }
    get protectionMeasures() {
        var _a;
        return (_a = this.qualification.protectionMeasures) !== null && _a !== void 0 ? _a : [];
    }
    set protectionMeasures(protectionMeasures) {
        this.updateRobberyDamageQualification({ qualificationPayload: { protectionMeasures } });
    }
    get protectionMeasuresString() {
        var _a, _b, _c, _d;
        if (!this.qualification.protectionMeasures || !this.allProtectionMeasures) {
            return '';
        }
        if (this.qualification.protectionMeasures.length === 1) {
            return ((_b = (_a = getProtectionMeasure(this.qualification.protectionMeasures[0], this.allProtectionMeasures)) === null || _a === void 0 ? void 0 : _a.shortDescription.toLowerCase()) !== null && _b !== void 0 ? _b : '');
        }
        const lastProtectionMeasureString = this.$tc((_d = (_c = getProtectionMeasure(this.qualification.protectionMeasures[this.qualification.protectionMeasures.length - 1], this.allProtectionMeasures)) === null || _c === void 0 ? void 0 : _c.shortDescription.toLowerCase()) !== null && _d !== void 0 ? _d : '').toLowerCase();
        const firstProtectionMeasuresString = this.qualification.protectionMeasures
            .slice(0, this.qualification.protectionMeasures.length - 1)
            .map((protectionMean) => { var _a, _b; return (_b = (_a = getProtectionMeasure(protectionMean, this.allProtectionMeasures)) === null || _a === void 0 ? void 0 : _a.shortDescription) !== null && _b !== void 0 ? _b : ''; })
            .join(', ')
            .toLowerCase();
        return `${firstProtectionMeasuresString} ${this.$tc('and')} ${lastProtectionMeasureString}`;
    }
    get canValidate() {
        return true;
    }
    get answer() {
        if (this.qualification.protectionMeasures === null) {
            return null;
        }
        if (this.qualification.protectionMeasures.length === 0) {
            return this.$tc('claim.qualification.robbery.protection_measures.answer', 1, {
                protectionMeasures: '...',
            });
        }
        if (this.qualification.protectionMeasures.length === 1) {
            return this.$tc('claim.qualification.robbery.protection_measures.answer', 1, {
                protectionMeasures: this.protectionMeasuresString,
            });
        }
        return this.$tc('claim.qualification.robbery.protection_measures.answer', 1, {
            protectionMeasures: this.protectionMeasuresString,
        });
    }
    async validate() {
        await this.updateAndSaveRobberyDamageQualification({
            qualificationPayload: { protectionMeasures: this.qualification.protectionMeasures },
            step: this.step,
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], RobberyProtectionMeasuresQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    contract.State('protectionMeasures')
], RobberyProtectionMeasuresQuestion.prototype, "allProtectionMeasures", void 0);
__decorate([
    qualification.State('qualification')
], RobberyProtectionMeasuresQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], RobberyProtectionMeasuresQuestion.prototype, "updateRobberyDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], RobberyProtectionMeasuresQuestion.prototype, "updateAndSaveRobberyDamageQualification", void 0);
RobberyProtectionMeasuresQuestion = __decorate([
    Component({
        components: { RadioPrimaryButton, QuestionPanel, UiButton },
        props: QuestionProps,
    })
], RobberyProtectionMeasuresQuestion);
export default RobberyProtectionMeasuresQuestion;
