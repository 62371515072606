var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import axios from 'axios';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import Maintenance from '@declaration/components/declaration/pages/maintenance.vue';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { DeclarationPage } from '@declaration/pages';
const authentication = namespace('authentication');
const pages = namespace('pages');
/**
 * Login and redirection logic is done in the magicLinkLogin middleware
 * This page is only shown to display the reason of failure if the link|user|claim is not ok
 */
let MagicLinkLoginAndRedirect = class MagicLinkLoginAndRedirect extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.contractNumber = '';
        this.password = '';
        this.houseImage = require('@declaration/assets/images/housing-type/house.svg');
        this.attempts = 0;
        this.DeclarationPage = DeclarationPage;
    }
    get shouldDisplayContent() {
        return !!this.error;
    }
    get error() {
        var _a;
        const error = (_a = this.magicLink) === null || _a === void 0 ? void 0 : _a.error;
        return error ? this.$tc(error) : null;
    }
    get isContractNumberRequired() {
        return insurerHasFeature(InsurerFeatureFlag.MagicLinkLoginCheckContractNumber);
    }
    get isTooManyAttempts() {
        return this.attempts >= 3;
    }
    get validNumber() {
        return this.contractNumber.trim().length > 3;
    }
    get canSubmit() {
        return this.validNumber && !this.isTooManyAttempts;
    }
    async checkContractNumber() {
        if (!this.validNumber) {
            this.attempts++;
            if (!this.isTooManyAttempts) {
                this.toast(this.$tc('authentication.bad_contract_number'), 'is-warning');
            }
            return;
        }
        this.loading = true;
        try {
            await this.$store.dispatch('authentication/loginWithMagicLink', {
                magicToken: this.$route.query.m,
                contractNumber: this.contractNumber.trim(),
                claimId: this.$route.query.c,
            });
            await this.$store.dispatch('user/getUserInfo');
            await this.$store.dispatch('claim/fetchClaim', { id: this.$route.query.c });
            return this.$goToLocalePath('/declaration');
        }
        catch (error) {
            this.attempts++;
            if (!this.isTooManyAttempts) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    this.toast(this.$tc('authentication.bad_contract_number'), 'is-warning');
                }
                else {
                    this.toast('Une erreur est survenue', 'is-danger');
                }
            }
            await this.$store.dispatch('authentication/loginWithMagicLink', {
                magicToken: '',
                claimId: '',
                error: 'error.magic_link.no_claim',
            });
            this.loading = false;
        }
    }
    toast(message, type) {
        this.$buefy.toast.open({
            message,
            type,
            position: 'is-top',
            duration: 5000,
        });
    }
};
__decorate([
    authentication.State('magicLink')
], MagicLinkLoginAndRedirect.prototype, "magicLink", void 0);
__decorate([
    pages.Getter('currentPage')
], MagicLinkLoginAndRedirect.prototype, "currentPage", void 0);
MagicLinkLoginAndRedirect = __decorate([
    Component({
        middleware: 'magicLinkLogin',
        components: {
            UiButton,
            Maintenance,
        },
        meta: {
            goBack: null,
        },
    })
], MagicLinkLoginAndRedirect);
export default MagicLinkLoginAndRedirect;
