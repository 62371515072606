var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ParagraphsWithIcon from '@declaration/components/ParagraphsWithIcon.vue';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import ReceiptUpload from '@declaration/components/UI/ReceiptUpload.vue';
import { WHITE_LAYOUT_COLOR } from '@declaration/layout';
import formatOptionalCentsAmount from '@shared/utils/formatOptionalCentsAmount';
import { forcedAmountWithDefault } from '@shared/utils/forcedAmount';
const claim = namespace('claim');
const counting = namespace('counting');
const layout = namespace('layout');
let CompensationOtherArtisanSendInvoice = class CompensationOtherArtisanSendInvoice extends Vue {
    constructor() {
        super(...arguments);
        this.receiptIllustration = require('@declaration/assets/images/receipts/receipt.svg');
        this.receiptConfirmedIllustration = require('@declaration/assets/images/receipts/receipt-confirmed.svg');
        this.invoiceUploading = false;
        this.invoiceToUpload = null;
    }
    get displayedText() {
        if (!this.damagesRefund.appliedAmounts.immediate) {
            return [
                {
                    icon: 'file',
                    html: this.$tc('compensation.other_artisan.upload_invoice.description.2', 0, {
                        delayed: formatOptionalCentsAmount(forcedAmountWithDefault(this.damagesRefund.appliedAmounts.total, 0)),
                    }),
                },
            ];
        }
        return [
            {
                icon: 'check',
                html: this.$tc('compensation.other_artisan.upload_invoice.description.1', 0, {
                    immediate: formatOptionalCentsAmount(forcedAmountWithDefault(this.damagesRefund.appliedAmounts.immediate, 0)),
                }),
            },
            {
                icon: 'file',
                html: this.$tc('compensation.other_artisan.upload_invoice.description.2', 0, {
                    delayed: formatOptionalCentsAmount(forcedAmountWithDefault(this.damagesRefund.appliedAmounts.total, 0)),
                }),
            },
        ];
    }
    get hasInvoice() {
        return !!(this.claim.invoiceFile && this.claim.invoiceFile.id);
    }
    get pageTitle() {
        return this.isMobile ? '' : this.$tc('compensation.other_artisan.upload_invoice.title');
    }
    get buttonText() {
        return this.hasInvoice || this.invoiceToUpload === null
            ? this.$tc('claim.button.back_home')
            : this.$tc('compensation.other_artisan.upload_invoice.button');
    }
    async onButtonClick() {
        if (!this.hasInvoice && this.invoiceToUpload) {
            this.invoiceUploading = true;
            await this.postAndSaveInvoice(this.invoiceToUpload).catch();
            this.invoiceUploading = false;
            this.invoiceToUpload = null;
            return;
        }
        this.$goBackHome();
    }
    onFileUpload(file) {
        this.invoiceToUpload = file;
    }
    async onFileDelete() {
        this.invoiceUploading = true;
        await this.deleteInvoice().catch();
        this.invoiceToUpload = null;
        this.invoiceUploading = false;
    }
};
__decorate([
    claim.State('claim')
], CompensationOtherArtisanSendInvoice.prototype, "claim", void 0);
__decorate([
    claim.Action('postAndSaveInvoice')
], CompensationOtherArtisanSendInvoice.prototype, "postAndSaveInvoice", void 0);
__decorate([
    claim.Action('deleteInvoice')
], CompensationOtherArtisanSendInvoice.prototype, "deleteInvoice", void 0);
__decorate([
    counting.State('counting')
], CompensationOtherArtisanSendInvoice.prototype, "counting", void 0);
__decorate([
    counting.Getter('damagesRefund')
], CompensationOtherArtisanSendInvoice.prototype, "damagesRefund", void 0);
__decorate([
    layout.State('isMobile')
], CompensationOtherArtisanSendInvoice.prototype, "isMobile", void 0);
CompensationOtherArtisanSendInvoice = __decorate([
    Component({
        components: {
            ParagraphsWithIcon,
            TitleImageDescriptionLayout,
            ReceiptUpload,
        },
        meta: {
            goBack: null,
            title: 'compensation.other_artisan.upload_invoice.title',
            color: WHITE_LAYOUT_COLOR,
        },
    })
], CompensationOtherArtisanSendInvoice);
export default CompensationOtherArtisanSendInvoice;
