var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import BreakInDamageDescriptionSectionCard from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionSectionCard.vue';
import BreakInDamageDescriptionStep from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionStep.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import BreakInDamageCheckBox from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageCheckBox.vue';
import BreakInDamageDescriptionSubSection from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageDescriptionSubSection.vue';
import { parseLength } from '@declaration/helpers/typeFormatters';
import BreakInDamageChoices from '@declaration/components/breakInDamage/DamageDescription/UI/BreakInDamageChoices.vue';
import { ONE_LEAF_WINDOW_HEIGHT_CHOICES, ONE_LEAF_WINDOW_WIDTH_CHOICES, TWO_LEAVES_WINDOW_HEIGHT_CHOICES, TWO_LEAVES_WINDOW_WIDTH_CHOICES, } from '@shared/constants/breakInDamages/WindowConstants';
import { LeavesType } from '@shared/types/api/breakInDamage';
import { GLASS_TYPE_TRAD_KEYS_AND_IMAGES, GLAZING_TYPE_TRAD_KEYS_AND_IMAGES, } from '@shared/constants/breakInDamageConstants';
const layout = namespace('layout');
const breakInDamage = namespace('breakInDamage');
let WindowSizeAndGlazing = class WindowSizeAndGlazing extends Vue {
    constructor() {
        super(...arguments);
        this.parseLength = parseLength;
        this.isSaving = false;
        this.showValidation = false;
        this.glazingTypes = Object.entries(GLAZING_TYPE_TRAD_KEYS_AND_IMAGES).map(([glazingType, description]) => ({
            value: glazingType,
            tradKey: description.tradKey,
            image: description.image,
        }));
        this.glassTypes = Object.entries(GLASS_TYPE_TRAD_KEYS_AND_IMAGES).map(([glassType, description]) => ({
            value: glassType,
            tradKey: description.tradKey,
            image: description.image,
        }));
    }
    get change() {
        return this.changeGetter(this.changeKey);
    }
    get breakInDamage() {
        return this.change.data;
    }
    get validation() {
        return this.validationGetter(this.changeKey);
    }
    get isValid() {
        const validation = !this.validation.fieldHasViolations('windowHeight') &&
            !this.validation.fieldHasViolations('windowWidth') &&
            !this.validation.fieldHasViolations('windowGlazingType') &&
            !this.validation.fieldHasViolations('windowLaminatedGlazingType');
        return validation;
    }
    get widthValues() {
        let widthValues;
        this.breakInDamage.windowType === LeavesType.OneLeaf
            ? (widthValues = ONE_LEAF_WINDOW_WIDTH_CHOICES)
            : (widthValues = TWO_LEAVES_WINDOW_WIDTH_CHOICES);
        return widthValues;
    }
    get heightValues() {
        let heightValues;
        this.breakInDamage.windowType === LeavesType.OneLeaf
            ? (heightValues = ONE_LEAF_WINDOW_HEIGHT_CHOICES)
            : (heightValues = TWO_LEAVES_WINDOW_HEIGHT_CHOICES);
        return heightValues;
    }
    update(data) {
        this.updateBreakInDamage({ changeKey: this.changeKey, data });
    }
    save() {
        this.isSaving = true;
        this.$emit('goToNextStep');
        this.isSaving = false;
    }
    revealValidation() {
        this.$store.dispatch('error/add', this.$tc('error.form.please_validate_message'));
        this.validateBreakInDamage(this.changeKey);
        this.showValidation = true;
    }
};
__decorate([
    layout.State('color')
], WindowSizeAndGlazing.prototype, "color", void 0);
__decorate([
    breakInDamage.Getter('change')
], WindowSizeAndGlazing.prototype, "changeGetter", void 0);
__decorate([
    breakInDamage.Action('updateDamage')
], WindowSizeAndGlazing.prototype, "updateBreakInDamage", void 0);
__decorate([
    breakInDamage.Getter('validation')
], WindowSizeAndGlazing.prototype, "validationGetter", void 0);
__decorate([
    breakInDamage.Action('validateAndRevealDamages')
], WindowSizeAndGlazing.prototype, "validateBreakInDamage", void 0);
WindowSizeAndGlazing = __decorate([
    Component({
        components: {
            BreakInDamageDescriptionStep,
            BreakInDamageDescriptionSectionCard,
            RadioPrimaryButton,
            BreakInDamageCheckBox,
            BreakInDamageDescriptionSubSection,
            BreakInDamageChoices,
        },
        props: {
            changeKey: { type: [String, Number], default: null },
            isFinalStep: { type: Boolean, default: false },
        },
    })
], WindowSizeAndGlazing);
export default WindowSizeAndGlazing;
