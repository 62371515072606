var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import AmountInput from '@declaration/components/UI/AmountInput.vue';
import RadioPrimaryButton from '@declaration/components/UI/RadioPrimaryButton.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { GLASS_BREAKAGE_ROOM_DAMAGE_REPARATION_BY_CHOICES } from '@shared/constants/glassBreakageDamageConstants';
import { GLASS_BREAKAGE_ROOM_DAMAGE_REPARATIONS_BY_ANSWERS, GLASS_BREAKAGE_ROOM_DAMAGE_REPARATIONS_ANSWERS, GLASS_BREAKAGE_TRAD_KEYS, } from '@declaration/constants/glassBreakageDamageConstants';
import { GlassBreakageRoomDamageReparationBy } from '@shared/types/api/glassBreakageDamage';
import { ClaimFileType } from '@shared/types/file';
import { newValidator, validate } from '@shared/validator/validator';
import { ValidationContext } from '@shared/validator/validationContext';
import { YES_OR_NO_CHOICES } from '@declaration/helpers/choices';
const claim = namespace('claim');
const qualification = namespace('qualification');
let GlassDamagesReparationsQuestion = class GlassDamagesReparationsQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.invoiceFileCollection = null;
        this.validator = null;
    }
    created() {
        this.invoiceFileCollection = this.qualification.roomDamageReparationsInvoice;
        this.validator = newValidator({
            glassDamageReparationsInvoice: this.invoiceFileCollection,
            glassDamageReparationsInvoiceAmount: this.selectedRoomDamageReparationsInvoiceAmount,
        });
    }
    get validation() {
        validate(this.validator, {
            roomDamageReparationsInvoice: this.selectedRoomDamageReparationsInvoice,
            roomDamageReparationsInvoiceAmount: this.selectedRoomDamageReparationsInvoiceAmount,
        }, {
            roomDamageReparationsInvoiceAmount: {
                presence: {
                    allowEmpty: false,
                    message: GLASS_BREAKAGE_TRAD_KEYS['room-damage-reparation-invoice-question-upload-validation'],
                },
            },
            roomDamageReparationsInvoice: {
                requiredFileCollection: this.selectedReparationByValue === GlassBreakageRoomDamageReparationBy.Professional
                    ? {
                        message: 'validator.requiredFileCollection',
                        trigram: ClaimTypeTrigram.Glass,
                    }
                    : null,
            },
        });
        return new ValidationContext(this.validator);
    }
    get reparationTypeQuestionTitle() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['room-damage-reparation-type-question']);
    }
    get reparationByQuestionTitle() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['room-damage-reparation-by-question']);
    }
    get reparationInvoiceQuestionTitle() {
        if (!this.selectedReparationByValue)
            return null;
        return this.isReparationInvoiceMandatory
            ? this.$tc(GLASS_BREAKAGE_TRAD_KEYS['room-damage-reparation-mandatory-invoice-question'])
            : this.$tc(GLASS_BREAKAGE_TRAD_KEYS['room-damage-reparation-optional-invoice-question']);
    }
    get reparationInvoiceQuestionDescription() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['room-damage-reparation-invoice-question-description']);
    }
    get reparationAddInvoiceLabel() {
        return this.$tc(GLASS_BREAKAGE_TRAD_KEYS['room-damage-reparation-invoice-question-upload']);
    }
    get reparationChoices() {
        return YES_OR_NO_CHOICES;
    }
    get reparationByChoices() {
        return GLASS_BREAKAGE_ROOM_DAMAGE_REPARATION_BY_CHOICES;
    }
    get selectedReparationValue() {
        return this.qualification.roomDamageReparations;
    }
    get selectedReparationByValue() {
        return this.qualification.roomDamageReparationsBy;
    }
    get selectedRoomDamageReparationsInvoice() {
        return this.invoiceFileCollection || this.qualification.roomDamageReparationsInvoice;
    }
    get selectedRoomDamageReparationsInvoiceAmount() {
        return this.qualification.roomDamageReparationsInvoiceAmount;
    }
    get shouldDisplayReparationBy() {
        return this.selectedReparationValue === true;
    }
    get shouldDisplayReparationInvoice() {
        return (this.selectedReparationValue === true &&
            this.selectedReparationByValue !== null &&
            this.selectedReparationByValue !== GlassBreakageRoomDamageReparationBy.Assistance);
    }
    get shouldDisplayReparationInvoiceAmount() {
        switch (this.selectedReparationByValue) {
            case GlassBreakageRoomDamageReparationBy.Professional:
                return true;
            case GlassBreakageRoomDamageReparationBy.Myself:
                return (this.selectedRoomDamageReparationsInvoice !== null &&
                    this.selectedRoomDamageReparationsInvoice.uploadedAppFiles.length > 0);
            default:
                return false;
        }
    }
    get isReparationInvoiceMandatory() {
        return this.selectedReparationByValue === GlassBreakageRoomDamageReparationBy.Professional;
    }
    get canValidateInvoice() {
        if (this.selectedRoomDamageReparationsInvoice.uploadedAppFiles.length === 0 &&
            this.isReparationInvoiceMandatory) {
            return false;
        }
        if (this.selectedRoomDamageReparationsInvoice.uploadedAppFiles.length > 0 &&
            this.selectedRoomDamageReparationsInvoiceAmount === null) {
            return false;
        }
        return true;
    }
    get invoiceFileType() {
        return ClaimFileType.CraftsmanInvoice;
    }
    get answer() {
        if (this.selectedReparationValue === null)
            return null;
        if (this.selectedReparationValue === false) {
            return this.$tc(GLASS_BREAKAGE_ROOM_DAMAGE_REPARATIONS_ANSWERS.no);
        }
        if (this.selectedReparationByValue === null)
            return null;
        return `${this.$tc(GLASS_BREAKAGE_ROOM_DAMAGE_REPARATIONS_ANSWERS.yes)}&nbsp;${this.$tc(GLASS_BREAKAGE_ROOM_DAMAGE_REPARATIONS_BY_ANSWERS[this.selectedReparationByValue])}`;
    }
    onReparationUpdate(roomDamageReparations) {
        if (roomDamageReparations === false) {
            this.updateAndSaveGlassBreakageDamageQualification({
                step: this.step,
                qualificationPayload: {
                    roomDamageReparations,
                    roomDamageReparationsBy: null,
                },
            });
        }
        else {
            this.updateGlassBreakageDamageQualification({
                qualificationPayload: { roomDamageReparations },
            });
        }
    }
    onReparationByUpdate(roomDamageReparationsBy) {
        if (roomDamageReparationsBy === GlassBreakageRoomDamageReparationBy.Assistance) {
            this.updateAndSaveGlassBreakageDamageQualification({
                step: this.step,
                qualificationPayload: {
                    roomDamageReparations: true,
                    roomDamageReparationsBy,
                },
            });
        }
        else {
            this.updateGlassBreakageDamageQualification({
                qualificationPayload: { roomDamageReparationsBy },
            });
        }
    }
    onFileCollectionUpdate(fileCollection) {
        this.invoiceFileCollection = fileCollection;
    }
    onRoomDamageReparationsInvoiceAmountUpdate(roomDamageReparationsInvoiceAmount) {
        this.updateGlassBreakageDamageQualification({
            qualificationPayload: { roomDamageReparationsInvoiceAmount },
        });
    }
    onValidate() {
        if (this.canValidateInvoice) {
            this.updateAndSaveGlassBreakageDamageQualification({
                step: this.step,
                qualificationPayload: {
                    roomDamageReparations: this.selectedReparationValue,
                    roomDamageReparationsBy: this.selectedReparationByValue,
                    roomDamageReparationsInvoice: this.selectedRoomDamageReparationsInvoice,
                    roomDamageReparationsInvoiceAmount: this.selectedRoomDamageReparationsInvoiceAmount,
                },
            });
        }
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], GlassDamagesReparationsQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], GlassDamagesReparationsQuestion.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], GlassDamagesReparationsQuestion.prototype, "updateGlassBreakageDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], GlassDamagesReparationsQuestion.prototype, "updateAndSaveGlassBreakageDamageQualification", void 0);
GlassDamagesReparationsQuestion = __decorate([
    Component({
        components: {
            UiButton,
            RadioPrimaryButton,
            QuestionPanel,
            FileCollectionUpload,
            AmountInput,
        },
        props: QuestionProps,
    })
], GlassDamagesReparationsQuestion);
export default GlassDamagesReparationsQuestion;
