var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { UiButton, UiIcon } from '@claims-ia/design-system';
import { MandatoryDocumentStatus, } from '@shared/types/documents';
import Modal from '@declaration/components/UI/Modal.vue';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import PictureCollectionUpload from '@declaration/components/upload/PictureCollectionUpload.vue';
import DocumentSynthesisField from '@declaration/components/documents/DocumentSynthesisField.vue';
import { getMandatoryDocumentTitle } from '@shared/documents/document';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import { PersonalPropertyReceiptType, } from '@shared/types/api/personalProperty';
import { ClaimFileType } from '@shared/types/file';
const personalProperty = namespace('personalProperty');
const emptyFileCollection = () => emptyUploadedFileCollection();
let DocumentSynthesisModal = class DocumentSynthesisModal extends Vue {
    constructor() {
        super(...arguments);
        this.fileCollection = emptyFileCollection();
        this.fields = [];
        this.receiptType = null;
        this.isHelpOpened = false;
    }
    get fileType() {
        return this.document.fileType;
    }
    get status() {
        return this.document.status;
    }
    get title() {
        return getMandatoryDocumentTitle(this.document, this.categoriesByIri, this.$i18n.tc.bind(this.$i18n));
    }
    get statusTitle() {
        return this.$tc(`claim.document_synthesis.modal.title.${this.status}`);
    }
    get statusSubtitle() {
        const key = `claim.document_synthesis.modal.subtitle.${this.status}`;
        return this.$te(key) ? this.$tc(key) : null;
    }
    get saveLabel() {
        if (this.document.status === MandatoryDocumentStatus.Invalid) {
            return this.$tc('claim.document_synthesis.modal.upload.replace');
        }
        return this.$tc('claim.document_synthesis.modal.upload.save');
    }
    get isPictureCollection() {
        return [
            ClaimFileType.PersonalPropertyPicture,
            ClaimFileType.ExternalDamagePicture,
            ClaimFileType.BreakInDamagePicture,
            ClaimFileType.GlassDamagePicture,
            ClaimFileType.GlassDamagePicture,
            ClaimFileType.EquipmentPicture,
        ].includes(this.document.fileType);
    }
    get isPersonalPropertyReceipt() {
        return this.document.parent['@type'] === ApiEntityType.PersonalPropertyReceipt;
    }
    get hasMandatoryParameters() {
        var _a;
        return !!((_a = this.document.mandatoryParameters) === null || _a === void 0 ? void 0 : _a.length);
    }
    get isValid() {
        var _a;
        if (!((_a = this.fileCollection.uploadedAppFiles) === null || _a === void 0 ? void 0 : _a.length))
            return false;
        return true;
    }
    get hasHelp() {
        return this.document.status === MandatoryDocumentStatus.Invalid;
    }
    updateFile(uploadedAppFileCollection) {
        this.fileCollection = uploadedAppFileCollection;
    }
    save() {
        this.$emit('save', {
            uploadedAppFileCollection: this.fileCollection,
            personalPropertyReceiptType: this.receiptType,
            parameters: this.fields,
        });
        this.close();
    }
    open() {
        this.$emit('open', true);
    }
    close() {
        this.$emit('open', false);
    }
    handleOpened() {
        var _a, _b;
        this.fileCollection =
            this.document.status === MandatoryDocumentStatus.Invalid
                ? emptyFileCollection()
                : (_a = this.document.uploadedAppFileCollection) !== null && _a !== void 0 ? _a : emptyFileCollection();
        this.fields = this.document.parameters.map((param) => ({ ...param }));
        if (this.isPersonalPropertyReceipt) {
            const personalPropertyReceipt = this.document.parent;
            if (personalPropertyReceipt.type !== PersonalPropertyReceiptType.None &&
                ((_b = this.fileCollection.uploadedAppFiles) === null || _b === void 0 ? void 0 : _b.length)) {
                this.receiptType = personalPropertyReceipt.type;
            }
        }
    }
    handleClosed() {
        this.fileCollection = emptyFileCollection();
        this.fields = [];
        this.receiptType = null;
    }
};
__decorate([
    personalProperty.State('categoriesByIri')
], DocumentSynthesisModal.prototype, "categoriesByIri", void 0);
DocumentSynthesisModal = __decorate([
    Component({
        components: {
            Modal,
            UiButton,
            UiIcon,
            FileCollectionUpload,
            PictureCollectionUpload,
            DocumentSynthesisField,
        },
        props: {
            opened: {
                type: Boolean,
                default: false,
            },
            document: {
                type: Object,
                required: true,
            },
        },
        model: {
            prop: 'opened',
            event: 'open',
        },
    })
], DocumentSynthesisModal);
export default DocumentSynthesisModal;
