var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { UiButton } from '@claims-ia/design-system';
import { QuestionComponent, QuestionProps } from '@declaration/question';
import AmountInput from '@declaration/components/UI/AmountInput.vue';
import QuestionPanel from '@declaration/components/UI/QuestionPanel.vue';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import Card from '@declaration/components/UI/Card.vue';
import { ClaimFileType } from '@shared/types/file';
import formatOptionalCentsAmount from '@shared/utils/formatOptionalCentsAmount';
const claim = namespace('claim');
const qualification = namespace('qualification');
let WaterLeakageCauseLocalizedPlumberIntervenedQuestion = class WaterLeakageCauseLocalizedPlumberIntervenedQuestion extends QuestionComponent {
    constructor() {
        super(...arguments);
        this.fileType = ClaimFileType.ProfessionalIntervention;
    }
    get questionTitle() {
        return this.$t('step.qualification/degat-des-eaux/fuite-localisee/plombier-intervenu/facture.question-title');
    }
    get title() {
        return this.$t('step.qualification/degat-des-eaux/fuite-localisee/plombier-intervenu/facture.title');
    }
    get description() {
        return this.$t('step.qualification/degat-des-eaux/fuite-localisee/plombier-intervenu/facture.description');
    }
    get uploadLabel() {
        return this.$t('step.qualification/degat-des-eaux/fuite-localisee/plombier-intervenu/facture.upload-button-label');
    }
    get addFileLabel() {
        return this.$t('step.qualification/degat-des-eaux/fuite-localisee/plombier-intervenu/facture.add-file-label');
    }
    get invoice() {
        return this.waterDamageQualification.plumberInvoice;
    }
    set invoice(plumberInvoice) {
        this.updateWaterDamageQualification({ qualificationPayload: { plumberInvoice } });
    }
    get invoiceAmount() {
        return this.waterDamageQualification.plumberInvoiceAmount;
    }
    set invoiceAmount(plumberInvoiceAmount) {
        this.updateWaterDamageQualification({ qualificationPayload: { plumberInvoiceAmount } });
    }
    get answer() {
        if (this.waterDamageQualification.plumberInvoice === null ||
            this.waterDamageQualification.plumberInvoiceAmount === null) {
            return null;
        }
        return this.$tc(`step.qualification/degat-des-eaux/fuite-localisee/plombier-intervenu/facture.answer`, undefined, {
            amount: formatOptionalCentsAmount(this.waterDamageQualification.plumberInvoiceAmount),
        });
    }
    get canValidate() {
        var _a, _b;
        return !!((_b = (_a = this.invoice) === null || _a === void 0 ? void 0 : _a.uploadedAppFiles) === null || _b === void 0 ? void 0 : _b.length) && !!this.invoiceAmount;
    }
    onFileCollectionUpdate(fileCollection) {
        this.invoice = fileCollection;
    }
    async validate() {
        await this.updateAndSaveWaterDamageQualification({
            step: this.step,
            qualificationPayload: {
                plumberInvoice: this.invoice,
                plumberInvoiceAmount: this.invoiceAmount,
            },
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], WaterLeakageCauseLocalizedPlumberIntervenedQuestion.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], WaterLeakageCauseLocalizedPlumberIntervenedQuestion.prototype, "waterDamageQualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], WaterLeakageCauseLocalizedPlumberIntervenedQuestion.prototype, "updateWaterDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], WaterLeakageCauseLocalizedPlumberIntervenedQuestion.prototype, "updateAndSaveWaterDamageQualification", void 0);
WaterLeakageCauseLocalizedPlumberIntervenedQuestion = __decorate([
    Component({
        components: { QuestionPanel, UiButton, FileCollectionUpload, AmountInput, Card },
        props: QuestionProps,
    })
], WaterLeakageCauseLocalizedPlumberIntervenedQuestion);
export default WaterLeakageCauseLocalizedPlumberIntervenedQuestion;
