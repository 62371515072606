var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { UiIcon } from '@claims-ia/design-system';
import { namespace } from 'vuex-class';
import { disableBodyScrolling, enableBodyScrolling } from '@shared/utils/scroll';
import { COLORS } from '@shared/layout/layout';
const layout = namespace('layout');
let Modal = class Modal extends Vue {
    constructor() {
        super(...arguments);
        this.scrollToTopSubscription = null;
        this.COLORS = COLORS;
    }
    onNoPriceKnown() {
        this.$emit('no-price-known');
    }
    created() {
        this.$watch('isDisplayed', (newValue) => {
            this.toggleBodyScrolling();
            if (newValue)
                this.$emit('opened');
            else
                this.$emit('closed');
        }, { immediate: true });
        this.$watch('scrollToTop', (scrollToTop$) => {
            if (scrollToTop$ === null) {
                if (this.scrollToTopSubscription === null) {
                    return;
                }
                this.scrollToTopSubscription.unsubscribe();
                this.scrollToTopSubscription = null;
                return;
            }
            this.scrollToTopSubscription = scrollToTop$.subscribe(() => this.executeScrollToTop());
        }, { immediate: true });
    }
    destroyed() {
        enableBodyScrolling();
    }
    close() {
        this.$emit('close');
        this.$nextTick(this.executeScrollToTop);
    }
    goBack() {
        this.$emit('goBack');
        this.$nextTick(this.executeScrollToTop);
    }
    toggleBodyScrolling() {
        this.isDisplayed ? disableBodyScrolling() : enableBodyScrolling();
    }
    executeScrollToTop() {
        const container = this.$refs.content;
        if (!container) {
            return;
        }
        container.scrollTop = 0;
    }
};
__decorate([
    layout.State('color')
], Modal.prototype, "layoutColor", void 0);
__decorate([
    layout.State('isMobile')
], Modal.prototype, "isMobile", void 0);
Modal = __decorate([
    Component({
        components: { UiIcon },
        props: {
            isDisplayed: { type: Boolean, required: true },
            title: {
                type: String,
                default: null,
            },
            headerImage: {
                type: String,
                default: null,
            },
            contentBackground: {
                type: String,
                default: null,
            },
            contentTextColor: {
                type: String,
                default: null,
            },
            whiteContentBackground: {
                type: Boolean,
                default: false,
            },
            scrollToTop: {
                type: Object,
                default: null,
            },
            multiStep: {
                type: Boolean,
                default: false,
            },
            goBackButton: {
                type: Boolean,
                default: false,
            },
        },
    })
], Modal);
export default Modal;
